import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box } from '@mui/material';
import ImageUpload from './Component/ImageUpload';

const { Option } = Select;

interface Image_folder {
    typeFolderName : string;
    SpanFolderName: string;
    SubSpanFolderName: string;
}

export default function BridgePreProcessImageUploadPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];

    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setCompanyId(res.data.data.companyId)
                    // localStorage.set("project_id", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    
    const setting :any= localStorage.getItem("settings")
    const [isSelect, setIsSelect] = useState<boolean>(false)
    const [typeArr, setType]=useState<string[]>([])
    const [BridgeType, setBridgeType] = useState("")
    const [folderName, setFolderName]= useState<Image_folder>({typeFolderName:"",SpanFolderName:"",SubSpanFolderName:""})
    const [confirmOption, setConfirmOption] = useState<boolean>(false);
    const [spanNumber,setSpanNumber] =useState([])
    const [pierNumber, setPierNumber] = useState([])
    const [selectType, SetSelectType] = useState('')
    const [selectOption, setSelectOption] = useState()
    const [optionCount, setOptionCount] = useState([])

    useEffect(()=>{
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            let settings = JSON.parse(res.data.data.settings)
            let countList :any= [];
                if (res.data.check === true) {
                    setType(settings.bridge_type)
                    countList.push(settings.girder_count)
                    countList.push(settings.pier_count)
                    countList.push(settings.slab_count)
                    setOptionCount(countList)
                    setSpanNumber(settings.span_number_list)
                    setPierNumber(settings.pier_number_list)
                }
        })
    },[])

    const onChange2 = (e: any) => {
        // setImgFiles(firstImgFiles)
        setFolderName({...folderName,typeFolderName:e,SpanFolderName:"",SubSpanFolderName:"" })
        setIsSelect(false)
        // onClearSelect()
        let optionArr: any = [];
        if (e === 'Girder' || e==='Slab') {//거더 하면 또는 슬라브 하면
            setBridgeType(e)
            setConfirmOption(true)
            spanNumber.map((span: any) => {
                let name: string = 'S' + String(span.num).padStart(3, '0')
                optionArr.push(<Option value={name} > Span {String(span.num)}</Option>)
            })
        } else if (e === 'Pier') {//교각
            setConfirmOption(false)
            setBridgeType(e)
            pierNumber.map((pier: any) => {
                let name: string = 'P' + String(pier.num).padStart(2, '0')
                optionArr.push(<Option value={name} > Pier {String(pier.num)}</Option>)
            })
            
        } else if (e === 'GirderSide') { //거더 측면
            setConfirmOption(false)
            setBridgeType(e)
            spanNumber.map((span: any) => {
                let name: string = 'S' + String(span.num).padStart(3, '0')
                optionArr.push(<Option value={name} > Span {String(span.num)}</Option>)
            })
        }
        SetSelectType('_'+e)
        setSelectOption(optionArr)
    }

    const onChange3 = (e: any, select_id: string) => {
        if (select_id === 'sub2') {
            setFolderName({...folderName, SpanFolderName:e})
            if(BridgeType === 'GirderSide' || BridgeType === 'Pier') {
                setIsSelect(true)
            }
            
        } else {
            setFolderName({...folderName, SubSpanFolderName:e})
            setIsSelect(true)
        }
        
    }

    const option_render=()=>{
        const arr:any[]=[];
        typeArr?.map((type:any)=>{
            let name=''
            if(type==='Girder'){
                name='거더 하면'
            }else if(type==='GirderSide'){
                name='거더 측면'
            }else if (type === 'Slab') {
                name='슬라브 하면'
            } else if (type === 'Pier') {
                name ='교각'
            } else if(type==='Abutment') {
                name = '교대'
            }
            arr.push(<Option value={type}> {name}</Option>)
        })
        return arr;
    }

    const girder_render=()=>{
        const spanArr: any[] = [];
        if (BridgeType === 'Girder') {
            for (let i = 1; i < Number(optionCount[0])+1; i++){
                spanArr.push(<Option value={'G'+String(i).padStart(2,'0')}> Girder {i}</Option>)
            }
        } else if (BridgeType === 'Slab') {
            for (let i = 1; i < Number(optionCount[2] + 1); i++){
                spanArr.push(<Option value={'Slab' + String(i).padStart(2,'0')}>Slab {i}</Option>)
            }
        }
        return spanArr;
    }

    const selectModel = () => {

        return <div style={{ display:'flex'}}>
                <Select placeholder="선택해주세요" value={folderName.typeFolderName === "" ? null : folderName.typeFolderName} style={{ width : '8rem'}} onChange={onChange2}>
                    {option_render()}    
                </Select>
                <Select placeholder="선택해주세요" value={folderName.SpanFolderName === "" ? null : folderName.SpanFolderName} style={{ width : '8rem', marginLeft: '1rem'}} onChange={(e:any)=>onChange3(e,"sub2")} >
                    {selectOption}
                </Select>
                {confirmOption === true?
                    <div><Select placeholder="선택해주세요" value={folderName.SubSpanFolderName === "" ? null : folderName.SubSpanFolderName} style={{ width : '8rem', marginLeft: '1rem'}} onChange={(e:any)=>onChange3(e,"sub3")}>{girder_render()}</Select></div>
                : <></>}
            </div>;
    }
    

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 이미지 업로드</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>이미지 업로드</div>
            <div style={{ marginTop: '1rem', marginLeft: '2rem'}}>
                <div style={{ marginBottom: '1rem'}}>
                    <Box sx={confirmOption === true?{
                        display: 'block',
                        width: '26rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }:{
                        display: 'block',
                        width: '17rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }}>
                        {selectModel()}
                    </Box>
                    
                </div>
                <div>
                    {
                        isSelect ? <ImageUpload /> : 
                        <Box sx={{
                            width: '11.5rem',
                            height: '1.5rem',
                            borderRadius: '0.5rem',
                            background: '#FFF',
                            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                            padding : '0.75rem'
                        }}>
                            <div>구역을 먼저 선택해주세요.</div>
                        </Box>
                    }
                </div>
            </div>
            
        </Layout>
    );
};