import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { Box, styled, Tabs, Tab, Typography } from '@mui/material';
import PreProcessTab from '../Dam/Component/PreProcessTab'
import PanoramaTab from '../Dam/Component/PanoramaTab';
import DetectorMeasureTab from './Component/DetectorMeasureTab';
import DrawerTab from '../Dam/Component/DrawerTab';
import EstimatorTab from '../Dam/Component/EstimatorTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DamPreProcessSettingPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let projectId : string | null = localStorage.getItem("projectId")
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const StyledTab = styled(Tab)({
      "&.Mui-selected": {
        color: "#0F6FB7",
        fontWeight : 700,
        background: 'rgba(15, 111, 183, 0.10)',
        borderRadius: '0.25rem',
        marginBottom : '0.25rem',
      }
    });

    return (
        <Layout>
            <div >
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 설정</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>설정</div>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="프로젝트 설정" {...a11yProps(0)} />
                    <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="파노라마" {...a11yProps(1)} />
                    <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="검출 & 측정" {...a11yProps(2)} />
                    <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="외관조사망도" {...a11yProps(3)} />
                    <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="상태평가보고서" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                  <Box sx={{ height: '35rem', marginTop: '3rem', overflowY:'scroll'}}>
                  <PreProcessTab/>
                  </Box> 
                  
                    
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ height: '35rem', overflowY:'scroll'}}>
                    <PanoramaTab/>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box sx={{ height: '35rem', overflowY:'scroll'}}>
                    <div style={{ display: 'flex', padding: '2rem'}}>
                      <div style={{ margin:'0 auto'}}>
                      <DetectorMeasureTab/>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box sx={{ height: '35rem', overflowY:'scroll'}}>
                    <DrawerTab/>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Box sx={{ height: '35rem', overflowY:'scroll'}}>
                    <EstimatorTab/>
                  </Box>
                </TabPanel>
                </Box>
            </div>
        </Layout>
    );
};

