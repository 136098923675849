import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Button } from '@mui/material';

const { Option } = Select;

  interface ImgContents {
    no : number
    name : any
    sort : any
}

interface ResultImage {
    no : any
    result_image : string
}

interface Data {
  num: string,
  name: string,
}

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }

export default function DamHorizontalPanoramaPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setCompanyId(res.data.data.companyId)
                    // localStorage.set("project_id", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    const ImgContents : ImgContents[] = [];   
    const ResultImage : ResultImage[] = [];  

    const [num, setNum] = useState<number>(0);
    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)
    const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);
    const [data, setData] = useState<any[]>(ResultImage);
    const [resultCrack, setResultCrack] = useState<boolean>(false)

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        setData(ImgListCrack.slice((page-1)*10, page*10));
      }, [page]);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [damtype, setDamtype] = useState<string>('');
    const [typeName, setTypeName] = useState<string>();

    useEffect(() => {
      const response = axios({
          method: 'get',
          url: API_URL + `/project/view/${projectId}`,
          headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
      }).then((res) => {
          // console.log(res.data.check) 
          const settings: any = JSON.parse(res.data.data.settings)
          // console.log("dlfmaaaaaaaaaaaaaaaa"+res.data.data.title)
              if (res.data.check === true) {
                  for (let i = 0; i < 1; i++) {
                      setDamtype(settings.dam_type)
              }
          }
          }).catch((err) => {
              console.log(err);
          });
  }, []);

  useEffect(() => {

    axios({
        method: 'get',
        url: API_URL+'/File/Files',
        headers: { "accept": `application/json`, "access-token": `${token}` },
        params: {
            path: `/project/${projectId}/stage4/${typeName}`
        }
    }).then((res)=>{
      
        if (res.data.check === true) {
            // console.log("성공")
            
            for(let i = 0; i<res.data.data.files.length; i++){
                // const SpanNum = (res.data.data.files[i].slice(0, -10)).substring(16)
                ResultImage.push({
                    result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${typeName}/${res.data.data.files[i]}&width=1440`,
                    no : i
                })
                // console.log(res.data.data.files[i])
                ImgContents.push({
                    no : i,
                    name : res.data.data.files[i],
                    sort : i+1
                })
            }

            if(ResultImage.length > 0){
                setResultCrack(true);
                console.log(ResultImage)
            }else{
                alert("결과가 나올 때까지 기다려주세요.")
            }

        } else {
            // alert('수직파노라마를 실행해주세요.')
        }

            ImgContents.sort((obj1, obj2) => {
                if (obj1.sort > obj2.sort) {
                    return 1;
                }
                if (obj1.sort < obj2.sort) {
                    return -1;
                }
                return 0;
                })

        let copyArrImgList = [...ImgList];

        for(let i = 0; i < ImgList.length; i++){
            copyArrImgList[i] = { ...copyArrImgList[i], no : i};
        }
        setImgList(copyArrImgList)
        // console.log(ImgList, "ImgListttttt")

        ResultImage.sort((obj1, obj2) => {
            if (obj1.no > obj2.no) {
                return 1;
            }
            if (obj1.no < obj2.no) {
                return -1;
            }
            return 0;
            })

        setImgListCrack(ResultImage);
        setData(ResultImage.slice((page-1)*10, page*10));

    }).catch((err) => {
        console.log(err);
    });
}, [typeName])

    const onClickPonirama = (e:any) => {
      // console.log(arr);
      // console.log(Verstitch);    
      axios({

          method: "post",
          url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
          data: {
              project_id: projectId,
              task_name: "ver_stitch_old",
              interactive: false,
              tasks: [
                  {
                      input_folder: `stage4/${damtype}`,
                      output_folder: "stage5",
                      yml_output_folder: "stage5_YML",
                      cam_no: 2,
                      conf_name: "config.cfg"
                  }
              ],
          }
      }).then((res)=>{
          if (res.data.check === true) {
              console.log("파노라마 성공")
              alert("수직파노라마 작업")

          } else {
              console.log("파노라마 실패")
          }
      }).catch((err) => {
          console.log(err);
      });
  }

  const onSave = () => {
    alert("수직 파노라마를 생성합니다.")
    setTimeout(function() {
      alert("수직 파노라마가 생성되었습니다.")
      navigate(`/dam/project/panorama/vertical/${projectId}`)
    }, 3000);}
        
  
      const rendering = (i:any) => {
          const result = [];
          result.push(
            <div>
                <div style={{ marginTop : '1.5rem'}}>
                    <img style={typeName == 'Spillway' ? { width:'50rem', height:'33rem' ,borderRadius: '0.625rem'} : { width:'50rem', height:'20rem' ,borderRadius: '0.625rem'}} src={resultCrack === true ? ImgListCrack[num]?.result_image : ""} alt="" />
                </div>
                <div style={{ display:'flex', float : 'right' }}>
                <div style={{marginTop : '1.5rem'}}>
                        <Button variant="outlined" sx={{ fontWeight : 600, fontFamily:'inter-pretendard'}} onClick={onSave} >수직 파노라마 실행</Button>
                    </div>
                </div>
            </div>

        );
          return result;
      };

      const onClickPanor = (i:any) => {
        setNum(page > 1 ? (page-1)*10+i : i);
    };


    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },Option

      }));

      const [isSelect, setIsSelect] = useState<boolean>(false)
      const setting :any = localStorage.getItem("settings")
      const typeArr: any = JSON.parse(setting).dam_type

      const option_render=()=>{
        const arr:any[]=[];
        
        typeArr.map((type:any)=>{
            let name=''
            if(type==='Overflow'){
                name='월류부'
            }else if(type==='DamFloor'){
                name='댐마루'
            }else if (type === 'UpStream') {
                name='상류면'
            } else if (type === 'DownStream') {
                name ='비월류부'
            } else if (type === 'Spillway') {
                name ='여수로'
            }
            arr.push(<Option value={type} > {name}</Option>)
        })
        return arr;
    }
    
      const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요"  style={{ width : '8rem'}} onChange={(e) => {setIsSelect(true); setTypeName(e)}}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }
  

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>파노라마 &gt; 수평 파노라마</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>수평 파노라마</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div>
                <div  style={{ marginTop: '1rem'}}>
                    <Box sx={{
                        display: 'block',
                        width: '8rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }}>
                        {selectModel()}
                    </Box>
                    </div>
                    {
                        isSelect ?
                            <div style={{ display:'flex', marginTop: '1rem'}}>
                                <div >
                                    {
                                        ImgListCrack.length !== 0 ? 
                                        <div style={{display:'flex'}}>
                                            <div>
                                            <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem'}}>
                                                <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                                                </div>
                                                <div>
                                                    <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem' }}>
                                                        <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                                            <TableHead>
                                                            
                                                            <TableRow >
                                                                <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                                                <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            
                                                            <TableBody>
                                                            {data?.map((row:any, idx:any) => (
                                                                <StyledTableRow 
                                                                    key={row.result_image}
                                                                    style={{ height: '2.5rem', cursor:'pointer'}}
                                                                    onClick={() => onClickPanor(idx)}
                                                                >
                                                                {/* <StyledTableCell component="th" scope="row" align="center">
                                                                    {row.num}
                                                                </StyledTableCell> */}
                                                                <StyledTableCell align="center" style={{width:'2em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{page > 1 ? (page-1)*10+idx+1 : idx+1}</StyledTableCell>
                                                                <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{data[idx]?.result_image.split(`stage4/${typeName}/`)[1].split('&width')[0]}</StyledTableCell>
                                                                </StyledTableRow> 
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                        <TableFooter>
                                                    <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                                    <Pagination count={ImgListCrack.length > 10 && ImgListCrack.length % 10 === 0 ? Math.floor(ImgListCrack.length/10) : Math.floor(ImgListCrack.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                                        sx={{
                                                        '& svg': {
                                                            pointerEvents: 'none'
                                                        }
                                                        }}
                                                    />
                                                    </TableRow>
                                                    </TableFooter>
                                                    </TableContainer>
                                                    
                                                    </div>
                                                </Box> 
                                                </div>
                                                <div>
                                                    <Box sx={{ width: '56.25rem', paddingBottom : '3rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                                        <div style={{padding : '3rem'}}>
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                                                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>이미지 뷰어 </div>
                                                                <div style={{ fontWeight : 600, fontSize:'1rem', fontFamily:'inter-pretendard', marginRight:'0.25rem'}}>{ImgListCrack[num]?.result_image.split(`stage4/${typeName}/`)[1].split('&width')[0]}</div>
                                                            </div>
                                                            <div>
                                                                {rendering(num)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                        : <Box sx={{
                                            width: '8.5rem',
                                            height: '1.5rem',
                                            borderRadius: '0.5rem',
                                            background: '#FFF',
                                            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                            padding : '0.75rem'
                                        }}>
                                            <div>이미지가 없습니다.</div>
                                        </Box>
                                    }
                                    
                                </div>
                                
                            </div>
                        : <Box sx={{
                            width: '11.5rem',
                            height: '1.5rem',
                            borderRadius: '0.5rem',
                            background: '#FFF',
                            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                            padding : '0.75rem',
                            marginTop : '1rem'
                        }}>
                            <div>구역을 먼저 선택해주세요.</div>
                        </Box>
                    }
                </div>
            </div>
        </Layout>
    );
};

