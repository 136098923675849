import { useState, useEffect } from 'react';
import { apiHelper,util,formHelper } from "../../allutils";
import { Sidebar, Menu, MenuItem, SubMenu  } from "react-pro-sidebar";
import { Routes, Route, Link } from "react-router-dom";
import './SidebarMolecure.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../Store/Global';

export default function SidebarMolecure() {
    let type: string | null = localStorage.getItem('projectType');
    const pre_link = document.location.href.replace("http://", "");
    const fix_link = pre_link.split("/")[0]
    const link = pre_link.replace(fix_link, "");

    let token : string | null = localStorage.getItem("token") 
    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));
    
    useEffect(() => {
      console.log(link)
        let getIdCompany = async () => {
          if (token !== null) {
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                console.log(res)
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  // localStorage.set("project_id", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

    return (
        <>
            <Sidebar
                toggled={true}
                // backgroundColor='rgba(15, 111, 183, 1)'
                style={{ 
                            position : 'fixed',
                            top : '0',
                            zIndex: '999',
                            height: '100%',
                            width: '16.25rem',
                            fontSize: '1rem'
                        }}
            >
              <div style={{ 
                            height: '100%',
                            width: '16.25rem',
                            background: `${type === "tunnel" ? 'url("/images/sidebar/Tunnel_sidebar.png")' : type === 'dam' ? 'url("/images/sidebar/Dam_sidebar.png")' : type === 'bridge' ? 'url("/images/sidebar/Bridge_sidebar.png")' : type === 'airport' ? 'url("/images/sidebar/Airport_sidebar.png")' : 'url("/images/sidebar/Scaf_sidebar.png")'}`,
                        }} >
                <Menu  
                    menuItemStyles={{ 
                        button: { 
                          'paddingLeft' : '10px',
                             '&:hover': {
                                backgroundColor: 'rgba(9, 94, 157, 1)',
                                color: '#ffffff'
                            },

                        },
                    }}>
                    <MenuItem 
                        // disabled={true}
                        className="menu1" 
                        component={<Link to="/main" className="link" />}
                    >
                    <h2>Deep Inspector</h2>
                    </MenuItem>
                    <MenuItem 
                        className={ link === '/project/list' ? "MenuItemClick" : "MenuItem" }
                        icon={<DashboardIcon />}
                        component={<Link to="/project/list" className="link" />}
                    > 
                        프로젝트 목록 
                    </MenuItem>
                    <MenuItem 
                        className={ link.includes('/user/detail') ? "MenuItemClick" : "MenuItem" }
                        icon={<AssignmentIndIcon />}
                        component={<Link to={`/user/detail/${userid}`} className="link" />}
                        // onClick={()=> alert("서비스 준비중 입니다.")}
                    > 
                        내 정보 
                    </MenuItem>
                    <SubMenu 
                        label="결제 관리" 
                        className="MenuItem" 
                        icon={<CreditCardIcon />}>
                        <MenuItem className="SubMenuItem" onClick={()=> alert("서비스 준비중 입니다.")}>&nbsp; &nbsp; &nbsp;이전 결제 내역 </MenuItem>
                        <MenuItem className="SubMenuItem" onClick={()=> alert("서비스 준비중 입니다.")}>&nbsp; &nbsp; &nbsp;사용 내역 상세 </MenuItem>
                    </SubMenu>
                    <MenuItem 
                            className="MenuItem" 
                            icon={<AssessmentIcon />}
                            onClick={()=> alert("서비스 준비중 입니다.")}
                    > 
                        통계 관리 
                    </MenuItem>
                    <MenuItem 
                        className="MenuItem" 
                        icon={<QuestionAnswerRoundedIcon />}
                        onClick={()=> alert("서비스 준비중 입니다.")}
                    > 
                        고객 문의 
                    </MenuItem>
                    <div className="Logout" style={{ cursor:"pointer"}} onClick={()=>{util.removeCookie("access_token"); localStorage.removeItem('token'); alert("로그아웃이 완료되었습니다."); util.Move('/')}}> 
                        Logout {<LogoutRoundedIcon />}
                    </div>
                </Menu>
                </div>
            </Sidebar>
        </>
    );
};



  