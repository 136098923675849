import React, { useState, useEffect } from 'react';
import styles from '../../../styles/Preprocess.module.css'
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { Row } from 'antd';
import axios from "axios";

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ImageUpload from './Component/ImageUpload';

function createData(
    num: string,
    name: string,
    startPoint: string,
    endPoint:string,
    status : string
  ) {
    return { num, name, startPoint, endPoint, status};
  }
  
  const rows = [
    createData('1', 'C01', '00:00', '00:00', '-'),
    createData('2', 'C02', '00:00', '00:00', '-'),
    createData('3', 'C03', '00:00', '00:00', '-'),
    createData('4', 'C04', '00:00', '00:00', '-'),
    createData('5', 'C05', '00:00', '00:00', '-'),
    createData('6', 'C06', '00:00', '00:00', '-'),
    createData('7', 'C07', '00:00', '00:00', '-'),
    createData('8', 'C08', '00:00', '00:00', '-'),
    createData('9', 'C09', '00:00', '00:00', '-'),
    createData('10', 'C10', '00:00', '00:00', '-'),
    createData('11', 'C11', '00:00', '00:00', '-'),
  ];

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  
  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

export default function AirportPreProcessImageUploadPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('dashboard/')[1];
    let projectId : string | null = localStorage.getItem("projectId")

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

        const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
        ) => {
        setPage(newPage);
        };

        // const handleChangeRowsPerPage = (
        //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        // ) => {
        //   setRowsPerPage(parseInt(event.target.value, 10));
        //   setPage(0);
        // };

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));
      let result: any;
      const imageFiltering = () => {
        // console.log(selectType)
        // if (project_Type === 'Airport') {
        //     alert("90도 Rotation시작했습니다.")
        //     axios({
        //         method: "post",
        //         url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
        //         headers: {
        //           "accept": `application/json`,
        //           "access-token": `${token}`,
        //           "Content-Type": `application/json`
        //         },
        //         data: {
        //             project_id: project_id,
        //             task_name: "imageRotation_airport",
        //             interactive: false,
        //             tasks: [{
        //                 input_folder: "stage0",
        //                 output_folder: "stage0_r",
        //                 rotation_angle: 90
        //             }]
        //         }
        //     }).then((res) => {
        //         if (res.data.check === true) {
        //             job_id = res.data.data.job_id
        //             result = setInterval(confirm, 10000)
        //         } else {
        //             console.log("실패", res)
        //         }
        //     })
        // // } else if (project_Type === 'Bridge' && typeArr.includes('Pier') === true) {
        // } 
        // } else {
        //     console.log(selectType)
        //     alert("이미지 필터링 작업을 시작합니다.")
        //     setTimeout(()=>alert("이미지 필터링 작업이 완료되었습니다"), 5000)
        //     // window.location.replace("./Filter")
        // }
        
    }


    return (
        <Layout>
            <div>
                <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 이미지 업로드</div>
                <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>이미지 업로드</div>
                <div style={{ marginTop: '2rem', marginLeft: '2rem'}}>
                    <div>
                        <ImageUpload />
                    </div> 
                </div>
            </div>
        </Layout>
    );
};

