import { Button, Form, Input, Radio, Select, Progress, } from "antd";
import styles from '../../../../../styles/CrackDrawer_Estimator.module.css';
import { API_URL } from '../../../../../Store/Global';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'

export default function DrawerTab() {
    const navigate = useNavigate();
    let token: string | null = localStorage.getItem("token");
    let job_id = 0;
    const projectId = localStorage.getItem("projectId");
    const [title, setTitle] = useState("");
    const [type, setType] = useState<any>([]);
    const [DamType, setDamType] = useState<any>('');
   
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

    useEffect(()=> {
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((result) => {
          if (result.data.check === true) {
            const settings = JSON.parse(result.data.data.settings)
            console.log(settings)
              setTitle(settings.dam_kor)
              setType(settings.dam_type)
            }
            console.log(title)
        })
    },[])
    
    // form 설정 가져오기

    const handleChangeType = (e: any) => {
        setType(e.target.value)
    }
    const handleChangeName = (e: any) => {
      setTitle(e.target.value)
  }

    const submitClick1 = () => {
        console.log("프로젝트 id", projectId)
        console.log("프로젝트 title", title)

        
        // setTimeout(function() {
        //   alert("외관조사망도가 생성되었습니다.")
        // }, 8000);
      if(DamType) {
        axios({
          method: "post",
          url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
            data: {
            project_id: projectId,
            task_name: "dxfDraw",
            interactive: false,
            tasks: [{
                input_folder: `stage7/${type[DamType-1]}`,
                output_folder: `stage8/${type[DamType-1]}`,
            }]
            },
            
        }).then((res) => {
          if (res.data.check === true) {
            alert("외관조사망도 생성 작업이 시작되었습니다.")
            job_id = res.data.data.job_id
            result = setInterval(confirm, 30000)
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        alert("댐 타입을 선택해주세요.")
      }
        

    // axios({
    //   method: "post",
    //   url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
    //   headers: {
    //     "accept": `application/json`,
    //     "access-token": `${token}`,
    //     "Content-Type": `application/json`
    //   },
    //     data: {
    //     projectId: projectId,
    //     task_name: "crack_drawer",
    //     interactive: true,
    //     tasks: [{
    //         input_folder: "stage7",
    //         output_folder_csv: "stage8_csv",
    //         output_folder_CAD: "stage8_CAD",
    //         cam_no: state.cam_no,
    //         span_no: state.span_no,
    //         span_length : state.span_len,
    //         total_crack_path: "stage8_csv/TotalCrack.csv",
    //         global_sheet_path: "stage8_csv/GlobalLocation.csv",
    //         total_csv_path:"stage8_csv/total.csv",
    //         max_width: state.maxwid,
    //         csv_path: "stage8_csv/minLength_minWidth.csv",
    //         filter_folder: "stage8_csv",
    //         min_width: state.filter_wid,
    //         min_length: state.filter_len,
    //         layer: state.layer,
    //         case:state.color,
    //         conf_dbinfo_name : "DBinfo.conf",
    //         conf_dbinfo_folder: "stage8_CAD",
    //         conf_dbinfo_values: {
    //           tunnelwidth: String(state.tunnelwidth),
    //           camerano: String(state.cam_no),
    //           jointdistance:String(state.span_len),
    //           tunnelname:title,
    //           tunneltilewidth: String(state.tunneltilewidth),
    //           contype: String(state.contype),
    //           camerashotwidth: 0,
    //           tunnellength: String(state.tunnellength),
    //           jointno: String(state.span_no + 1),
    //           overlappingratio:String(state.overlap)
    //         },
    //         conf_setup_name : "setup.conf",
    //         conf_setup_folder: "stage8_CAD",
    //         conf_setup_values:{
    //             table_color: state.table_color,
    //             draw_color:state.draw_color
    //         },
    //     }]
    //     },
        
    // }).then((res) => {
    //   if (res.data.check === true) {
    //     job_id = res.data.data.job_id
    //     result = setInterval(confirm, 100000)
    //     alert("외관조사망도 진행중입니다.")
        
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // })

    };

    let result :any;

    const confirm = () => {
        console.log(job_id)
        axios({
          method: "post",
          url: API_URL + '/scheduler/job/query',
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
              data: {
                  "job_id": job_id ,
                  "company_id": companyid
              }
                }).then((res) => {
                console.log(res)
                if (res.data.check == true) {
                    console.log("성공",res.data.data.status)
                    if (res.data.data.status === "done") {
                        alert("외관조사망도가 생성되었습니다.")
                        clearInterval(result)
                        navigate(`/project/download/${projectId}`)

                    } else if (res.data.data.status === "wait") {
                       console.log("wait")
                    } else if(res.data.data.status === "error"){
                        alert("해당 파일이 없습니다.")
                        clearInterval(result)
                    }
                 } else {
                    console.log("실패")
                 }
            })
        
    }


    return (
        
    <div>
      <Form labelCol={{ span: 8, }}  wrapperCol={{ span: 8, }} layout="horizontal" >
              <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>댐 타입</p>} className={styles.csFormItem} >
              <Select placeholder="선택해주세요." onChange={(e) => { setDamType(e)}}>
                {
                  type?.map((row:any, i:any) => (
                    <Select.Option value={i+1}>{row}</Select.Option>
                  ))
                }
                  </Select>
                  </Form.Item>
                  <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>댐 명</p>} className={styles.csFormItem}>
                    <Input value={title} className={styles.csFormInput}></Input>
                  </Form.Item>
                  <Form.Item className={styles.submitBtn}>
                    <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '200px'}} >외관조사망도 생성 </Button>
                  </Form.Item>
        </Form></div>
        //  style={{float: "right"}}
  )
}
