import styles from '../../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Checkbox ,Select, Input, Table, Spin} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../../Store/Global';

interface ImgName {
    no : number
    name : string
  }
  
  //결함 검출 실행 tasks명
  interface DetectorList {
    input_folder : string
    output_folder : string
    choice_weight : string
  }

  interface Detect {
    input_folder : string;
    output_folder : string;
}

  
  interface Measure {
      input_folder : string;
      output_folder : string;
  }

export default function Setting() {
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState('');
    const [korName, setKorName] = useState('');
    const [engName, setEngName] = useState('');
    const [type, setType] = useState<any>([]);
    const [startLatitude, setStartLatitude] = useState(36.12693); // 시작 위도
    const [endLatitude, setEndLatitude] = useState(36.12537); // 끝 위도
    const [startLongitude, setStartLongitude] = useState(128.9484); // 시작 경도
    const [endLongitude, setEndLongitude] = useState(128.9461); // 끝 경도
    const [startHeight, setStartHeight] = useState(220); // 높이 시작 지점
    const [endHeight, setEndHeight] = useState(280); // 높이 끝 지점
    const [widthAreaCount, setWidthAreaCount] = useState(9); // 가로 영역 개수
    const [heightAreaCount, setHeightAreaCount] = useState(4); // 세로 영역 개수

    const [isStart, setIsStart] = useState<boolean>(false)
    const [isDefectDetect, setIsDefectDetect] = useState<boolean>(false)
    const [isDefectMeasure, setIsDefectMeasure] = useState<boolean>(false)
    const [isDrawer, setIsDrawer] = useState<boolean>(false)
    const [isEstimator, setIsEstimator] = useState<boolean>(false)
    const [text, setText] = useState<string>("loading")

    const list: any[] = [projectName, korName, engName, type, startLatitude, endLatitude, startLongitude, endLongitude, startHeight, endHeight, widthAreaCount, heightAreaCount ]

        let token : string | null = localStorage.getItem("token") 
        let projectId: string | null = localStorage.getItem("projectId");

        const [isBind, setIsBind] = useState<boolean>(false);

        useEffect(() => {
            const response = axios({
                method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
            }).then((res) => {
                    // console.log(res.data.check) 
                    console.log(res.data.data.title)
                    const settings: any = JSON.parse(res.data.data.settings)
                    // console.log("dlfmaaaaaaaaaaaaaaaa"+res.data.data.title)
                    if (res.data.check === true) {
                        for (let i = 0; i < 1; i++) {
                            console.log(settings)
                            setProjectName(res.data.data.title)
                            setKorName(settings.dam_kor)
                            setEngName(settings.dam_eng)
                            setType(settings.dam_type)
                            setStartLatitude(settings.dam_latitude_start)
                            setEndLatitude(settings.dam_latitude_end)
                            setStartLongitude(settings.dam_longitude_start)
                            setEndLongitude(settings.dam_longitude_end)
                            setStartHeight(settings.dam_height_start)
                            setEndHeight(settings.dam_height_end)
                            setWidthAreaCount(settings.dam_width_area)
                            setHeightAreaCount(settings.dam_height_area)
                        }

                    } else {
                        console.log("실패");
                    }
                
                    setIsBind(true)

                }).catch((err) => {
                    console.log(err);
                });
        }, []);

        const ImgName : ImgName[] = [];  
    const ImgName2 : ImgName[] = [];  
    const DetectorList : DetectorList[] = [];
    const Detect : Detect [] = [];
    const Measure : Measure [] = [];

    //useEffect에서 stage4의 파노라마 이미지를 불러와서 Table에 사용
    const [ImgList, setImgList] = useState<any | undefined>([]);    
    const [ImgList2, setImgList2] = useState<any | undefined>([]);   
    //ImgList에 불러온 목록이 카메라 개수 * 스팬 개수와 동일하면 true로 반환 후, true일 때 Table을 그림
    const [result, setResult] = useState<boolean>(false)
    const [result2, setResult2] = useState<boolean>(false)
    //균열 검출을 실행하기 위한 tasks를 Weight가 변경될 때 함께 복사하여 변경하고 새롭게 세팅하기 위함
    const [NewDetectorList, setNewDetectorList] = useState<any[]>(DetectorList);
    //결함 검출을 실행하기 위한 tasks를 세팅
    const [detectList, setDetectList] = useState<any[]>(Detect);
    //결함 측정을 실행하기 위한 tasks를 세팅
    // const [NewMeasureList, setNewMeasureList] = useState<any[]>(Measure);
    const [measureList, setMeasureList] = useState<any[]>(Measure);
    //새롭게 할당할 Weight 파일명
    const [DetectorModel, setDetectorModel] = useState<string>("crack_detector_tf1");
    //현재 체크되어 있는 체크박스(Weight => 현재 옵션은 버튼으로 설정되어 있음(checkbox X))
    const [CheckBox, setCheckBox] = useState(1);

    // const [setting, setSetting]=useState<any>([])

    // const [typeArr, setTypeArr]=useState<any>([])
    const [DamType, setDamType]=useState<any>('Spillway');

    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

      useEffect(()=>{
        const response = axios({
            method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            console.log(res.data)
            const settings: any = JSON.parse(res.data.data.settings)
                // setSetting(settings)
                setType(settings.dam_type)
                console.log(settings.dam_type, settings.dam_type.length)
                
                Detect.push({
                    input_folder : `stage4/${DamType}`,
                    output_folder : `stage6/${DamType}`,
                }) 

                Measure.push({
                    input_folder: `stage6/${DamType}`,
                    output_folder: `stage7/${DamType}`
                }) 
        }).catch((err) => {
            console.log(err);
        });   


        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage4/${DamType}`
            }
        }).then((res) => {    
            
        if(res.data.check) {
            setResult(true);
        }
        // console.log(res.data.data.files.length)
        for (let j = 0; j < res.data.data.files.length; j++) {
            ImgName.push({
                no : j+1,
                name : res.data.data.files[j]
            })            
    }
    
    ImgName.sort((obj1, obj2) => {
        if (obj1.no > obj2.no) {
            return 1;
        }
        if (obj1.no < obj2.no) {
            return -1;
        }
        return 0;
        })
    setImgList(ImgName)

    }).catch((err) => {
        console.log(err)
    })

        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage6/${DamType}`
            }
        }).then((res) => {    
        console.log(res.data.check)
        if(res.data.check) {
            setResult2(true);
        }
        // console.log(res.data.data.files.length)
        for (let j = 0; j < res.data.data.files.length; j++) {
            ImgName2.push({
                no : j+1,
                name : res.data.data.files[j]
            })            
    }

    ImgName2.sort((obj1, obj2) => {
        if (obj1.no > obj2.no) {
            return 1;
        }
        if (obj1.no < obj2.no) {
            return -1;
        }
        return 0;
        })
    setImgList2(ImgName2)

    }).catch((err) => {
        console.log(err)
    })
        setDetectList(Detect);
        setMeasureList(Measure);
        console.log(result,result2)
    },[isDefectDetect])



        // 결함 검출
        const routineDefectDetector = () => {
            axios({
                method: "post",
                url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
                headers: {
                  "accept": `application/json`,
                  "access-token": `${token}`,
                  "Content-Type": `application/json`
                },
                      data: {
                    project_id: projectId,
                    task_name: "defectDetect",
                    interactive: false,
                    tasks: detectList
                }
                }).then((res)=>{
                    if (res.data.check === true) {
                        console.log("성공")  
                        alert(`${DamType} 결함 검출 작업을 시작합니다.`)  
                        job_id = res.data.data.job_id
                        /////30초마다 alert로 알려줌////////////
                        resultDefectDetector = setInterval(confirmDefectDetector, 30000)
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
        }
        let job_id = 0;
        let resultDefectDetector :any;
        let resultDefectDetector2 :any;
        // 결함 검출 confirm
        const confirmDefectDetector = () => {
            axios({
                method: "post",
                url: API_URL + '/scheduler/job/query',
                headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                    data: {
                        "job_id": job_id ,
                        "company_id": companyid
                    }
                      }).then((res) => {
                    // console.log(res)
                    if (res.data.check === true) {
                        console.log("성공")
                        if (res.data.data.status === "done") {
                            alert("결함 검출 작업이 끝났습니다. 결함 측정을 시작합니다.")
                            clearInterval(resultDefectDetector)
                            setIsDefectDetect(false)
                            setIsDefectMeasure(true)
                            routineDefectMeasure()
                        } else if (res.data.data.status === "progress") {
                            console.log("이미지 추출중입니다.")
                        } else if(res.data.data.status === "error"){
                            alert("해당 파일이 없습니다.")
                            clearInterval(resultDefectDetector)
                            setIsStart(false)
                        }
                    } else {
                        console.log("실패")
                    }
                })
        }
        // 결함 측정
        const routineDefectMeasure = () => {
            axios({
                method: "post",
                url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
                headers: {
                "accept": `application/json`,
                "access-token": `${token}`,
                "Content-Type": `application/json`
                },
                data: {
                project_id: projectId,
                task_name: "cv2_defectMeasure",
                interactive: false,
                tasks: measureList
            }
            }).then((res)=>{
                if (res.data.check === true) {
                    console.log("성공")    
                    alert(`${DamType} 결함 측정 작업을 시작합니다.`)  
                    job_id = res.data.data.job_id
                    /////30초마다 alert로 알려줌////////////
                    resultDefectDetector2 = setInterval(confirmDefectMeasure, 30000)
                    // alert("lean_mean 작업")            
                } else {
                    console.log("실패")
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        // 결함 측정 confirm
        const confirmDefectMeasure = () => {
            axios({
                method: "post",
                url: API_URL + '/scheduler/job/query',
                headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                    data: {
                        "job_id": job_id ,
                        "company_id": companyid
                    }
                      }).then((res) => {
                    // console.log(res)
                    if (res.data.check === true) {
                        console.log("성공")
                        if (res.data.data.status === "done") {
                            alert("결함 측정 작업이 끝났습니다. 외관조사망도 작업을 시작합니다.")
                            clearInterval(resultDefectDetector2)
                            setIsDefectMeasure(false)
                            setIsDrawer(true)
                            routineDrawer();
                        } else if (res.data.data.status === "progress") {
                            console.log("이미지 추출중입니다.")
                        } else if(res.data.data.status === "error"){
                            alert("해당 파일이 없습니다.")
                            clearInterval(resultDefectDetector2)
                            setIsStart(false)
                        }
                    } else {
                        console.log("실패")
                    }
                })
        }

        let drawer :any;
        // 외관조사망도 생성
        const routineDrawer = () => {
            if(DamType) {
                axios({
                  method: "post",
                  url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
                  headers: {
                    "accept": `application/json`,
                    "access-token": `${token}`,
                    "Content-Type": `application/json`
                  },
                    data: {
                    project_id: projectId,
                    task_name: "dxfDraw",
                    interactive: false,
                    tasks: [{
                        input_folder: `stage7/Spillway`,
                        output_folder: `stage8/Spillway`,
                    }]
                    },
                    
                }).then((res) => {
                  if (res.data.check === true) {
                    alert("외관조사망도 생성 작업이 시작되었습니다.")
                    job_id = res.data.data.job_id
                    drawer = setInterval(confirmDrawer, 30000)
                  }
                }).catch((err) => {
                  console.log(err)
                })
              } else {
                alert("댐 타입을 선택해주세요.")
              }
        }
        
        // 외관조사망도 confirm
        const confirmDrawer = () => {
            axios({
                method: "post",
                url: API_URL + '/scheduler/job/query',
                headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                    data: {
                        "job_id": job_id ,
                        "company_id": companyid
                    }
                      }).then((res) => {
                      console.log(res)
                      if (res.data.check == true) {
                          console.log("성공",res.data.data.status)
                          if (res.data.data.status === "done") {
                              alert("외관조사망도 생성 작업이 끝났습니다. 상태평가보고서 작업을 시작합니다.")
                              clearInterval(drawer)
                              setIsDrawer(false)
                              setIsEstimator(true)
                              setTimeout(function() {
                                alert("외관조사망도 생성이 완료되었습니다. 안전점검이 완료되었습니다.")
                                setIsStart(false)
                                navigate(`/dam/project/defect/${projectId}`)
                            }, 1000); // 외관조사망도 생성
      
                          } else if (res.data.data.status === "wait") {
                             console.log("wait")
                          } else if(res.data.data.status === "error"){
                              alert("해당 파일이 없습니다.")
                              clearInterval(drawer)
                              setIsStart(false)
                          }
                       } else {
                          console.log("실패")
                       }
                  })
        }
        // 상태평가보고서는 고정

        const submitClick1 = () => {
            
            alert("댐 시설물에 대한 AI 안전점검이 시작되었습니다.")
            setIsStart(true)
            setTimeout(function() {
                alert("파노라마 생성이 완료되었습니다. 결함 검출을 시작합니다.")
                setIsDefectDetect(true)
                routineDefectDetector();
            }, 2000); // 검출 시작
        
        }

            const onclick = (e: any) => {
                let arr: any = [...type];
                console.log(e.target.value, e)
                if (e.target.checked === true) {
                    if (arr.includes(e.target.value) === false) {
                        arr.push(e.target.value)
                    } 
                } else {
                    arr = arr.filter((a:string) => a !== e.target.value)
                }
                setType(arr)
                console.log(arr)

        }
        
    useEffect (() => {
        if(isDefectDetect) {
            setText("결함 검출 작업 중 ...")
        }
        if(isDefectMeasure) {
            setText("결함 측정 작업 중 ...")
        }
        if(isDrawer) {
            setText("외관조사망도 생성 작업 중 ...")
        }
        if(isEstimator) {
            setText("상태평가보고서 생성 작업 중 ...")
        }
        
    }, [isDefectDetect, isDefectMeasure,isDrawer,isEstimator])


    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
                            <Form.Item className={styles.FormItem}  label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>프로젝트 명</p>}>
                                <Input value={projectName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 이름(한)</p>}>
                                <Input value={korName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 이름(영)</p>}>
                                <Input value={engName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 구역</p>}>
                            <Checkbox value="UpStream" checked={type.includes("UpStream") ? true : false} onClick={onclick}>상류</Checkbox>
                            <Checkbox value="DamFloor" checked={type.includes("DamFloor") ? true : false} onClick={onclick}>댐마루</Checkbox>
                            <Checkbox value="Overflow" checked={type.includes("Overflow") ? true : false} onClick={onclick}>하류(월류부)</Checkbox>
                            <Checkbox value="DownStream" checked={type.includes("DownStream") ? true : false} onClick={onclick}>하류(비월류부)</Checkbox>
                            <Checkbox value="Spillway" checked={type.includes("Spillway") ? true : false} onClick={onclick}>여수로</Checkbox>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}} >댐 시작 위도</p>}>
                                <Input disabled value={startLatitude} suffix="°"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 끝 위도</p>}>
                                <Input disabled value={endLatitude} suffix="°"  />                         
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 시작 경도</p>}>
                                <Input disabled value={startLongitude} suffix="°"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 끝 경도</p>}>
                               <Input disabled value={endLongitude} suffix="°"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 시작 지점</p>}>
                               <Input disabled value={startHeight} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 끝 지점</p>}>
                               <Input disabled value={endHeight} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 가로 영역 갯수</p>}>
                               <Input disabled value={widthAreaCount} suffix="개"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 영역 갯수</p>}>
                               <Input disabled value={heightAreaCount} suffix="개"  />  
                            </Form.Item>
                            <Form.Item className={styles.submitBtn}>
                            {
                                    !isStart ? 
                                    <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '200px'}} >AI 안전점검 시작</Button>
                                    : 
                                    <>
                                    <Spin spinning={true} size={"large"}/>
                                    <p>{text}</p>
                                    </>

                                    
                                }
                        </Form.Item>
            </Form>
            </>


    )
}
