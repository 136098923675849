import styles from '../../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Radio,Select, Input, Table, Checkbox, Spin} from 'antd';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../../Store/Global';
import { bridge_project_name, bridge_type, bridge_name_kor, bridge_name_eng, bridge_length, bridge_sub_type, bridge_span_count, bridge_span_num, bridge_span_length, bridge_span_class_length } from "../../../../../Store/State/projectAtom";
interface TableSet {
    key: any;
    no: any;
    firstCam:any;
    lastCam:any;
}

const { Option } = Select;

export default function Setting() {
    const navigate = useNavigate();
    const [ListArr, setListArr] = useState<any>([]);
    const [projectName, setProjectName] = useState<any>(); 
    const [bridgeType, setBridgeType] = useState<any>();
    const [bridgeNameKor, setBridgeNameKor] = useState<any>(); 
    const [bridgeNameEng, setBridgeNameEng] = useState<any>(); 
    const [bridgeLength, setBridgeLength] = useState<any>(100); 
    const [spanCount, setSpanCount] = useState<any>(); 
    const [spanNum, setSpanNum] = useState<any>(); 
    const [bridgeSubType, setBridgeSubType] = useState<any[]>(["Pier"]);
    const [spanLength, setSpanLength] = useState<any>(48); 
    const [spanClassLength, setSpanClassLength] = useState<any>(8); 
    const [girderCount, setGirderCount] = useState<any>(8); 
    const [girderCameraCount, setGirderCameraCount] = useState<any>(); 
    const [girderWidth, setGirderWidth] = useState<any>(); 
    const [girdersideHeight, setGirdersideHeight] = useState<any>(); 
    const [pierCount, setPierCount] = useState<any>(); 
    const [pierFilmCount, setPierFilmCount] = useState<any>(); 
    const [pierHeight, setPierHeight] = useState<any>(); 
    const [pierRadius, setPierRadius] = useState<any>(); 
    const [slabCount, setSlabCount] = useState<any>(); 
    const [slabWidth, setSlabWidth] = useState<any>(); 
    // loading
    const [isStart, setIsStart] = useState<boolean>(false)
    const [isCrackDetect, setIsCrackDetect] = useState<boolean>(false)
    const [isCrackMeasure, setIsCrackMeasure] = useState<boolean>(false)
    const [isDefectDetect, setIsDefectDetect] = useState<boolean>(false)
    const [isDefectMeasure, setIsDefectMeasure] = useState<boolean>(false)
    const [isDrawer, setIsDrawer] = useState<boolean>(false)
    const [isEstimator, setIsEstimator] = useState<boolean>(false)
    const [text, setText] = useState<string>("loading")

        const plus = (event:any) => {
            alert(`설정값을 변경하였습니다`);
        }
        let token : string | null = localStorage.getItem("token") 
        let projectId: string | null = localStorage.getItem("projectId");

        useEffect(() => {
            const response = axios({
                method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
            }).then((res) => {
                    // console.log(res.data.check) 
                    console.log(res.data.data)
                    const settings: any = JSON.parse(res.data.data.settings)
                    console.log(settings)
                    if (res.data.check === true) {
                        for (let i = 0; i < 1; i++) {
                            console.log(`스팬갯수${settings.span_count}` )
                            setProjectName(res.data.data.title)
                            setBridgeNameKor(settings.bridge_kor)
                            setBridgeNameEng(settings.bridge_eng)
                            setSpanCount(settings.span_count)
                            setBridgeType(settings.bridge_type)
                            // setCamraNo(settings.cameraCount)
                            // setSpanNo(settings.spanCount)
                            // setProjectName(res.data.data.title)
                            // setKorName(settings.tunnel_kor)
                            // setEnName(settings.tunnel_eng)
                            // setSpeedd(settings.scanSpeed)
                            // setFpss(settings.scanSpeed.fps)
                            // setSpanLenn(settings.spanLength)
                            // setDirection(settings.direction)

                        }
                    } else {
                        console.log("실패");
                    }

                }).catch((err) => {
                    console.log(err);
                });
        }, []);

        const submitClick1 = () => {
            alert("교량 시설물에 대한 AI 안전점검이 시작되었습니다.")
            setIsStart(true)
            setTimeout(function() {
                alert("교량 안전점검 진단이 모두 완료되었습니다.")
                setIsStart(false)
                // window.location.reload();
                navigate(`../bridge/project/defect/detector/${projectId}`)
            }, 20000);
            // // 균열 검출
            // setTimeout(function() {
            //     alert("파노라마가 완료 되었습니다. 균열 검출을 시작합니다.")
            //     setIsCrackDetect(true)
            //     // 결함 검출
            //     setTimeout(function() {
            //         alert("균열 측정이 완료 되었습니다. 결함 검출을 시작합니다.")
            //         setIsCrackMeasure(false)
            //         setIsDefectDetect(true)
            //         // 결함 측정
            //         setTimeout(function() {
            //             alert("결함 검출이 완료 되었습니다. 결함 측정을 시작합니다.")
            //             setIsDefectDetect(false)
            //             setIsDefectMeasure(true)
            //             // 외관조사망도
            //             setTimeout(function() {
            //                 alert("결함 측정이 완료 되었습니다. 외관조사망도 생성을 시작합니다.")
            //                 setIsDefectMeasure(false)
            //                 setIsDrawer(true)
            //                 // 상태평가보고서
            //                 setTimeout(function() {
            //                     alert("외관조사망도 생성이 완료 되었습니다. 상태평가보고서 생성을 시작합니다.")
            //                     setIsDrawer(false)
            //                     setIsEstimator(true)
            //                      // 완료
            //                     setTimeout(function() {
            //                         alert("상태평가보고서 생성이 완료되었습니다. 안전점검 진단이 모두 완료되었습니다.")
            //                         setIsEstimator(false)
            //                         setIsStart(false)
            //                         // window.location.reload();
            //                         navigate(`../bridge/project/crack/detector/${projectId}`)
            //                     }, 20000); // 상평 시작
            //                 }, 15000); // 외조망 시작
            //             }, 20000); // 결함 측정 시작
            //         }, 10000); // 결함 검출 시작
            //     }, 20000); // 균열 측정 시작
            // }, 2000); // 균열 검출 시작
        }
        
        
useEffect (() => {
    if(isCrackDetect) {
        setText("균열 검출 작업 중 ...")
    } 
    if(isCrackMeasure) {
        setText("균열 측정 작업 중 ...")
    }
    if(isDefectDetect) {
        setText("결함 검출 작업 중 ...")
    }
    if(isDefectMeasure) {
        setText("결함 측정 작업 중 ...")
    }
    if(isDrawer) {
        setText("외관조사망도 생성 작업 중 ...")
    }
    if(isEstimator) {
        setText("상태평가보고서 생성 작업 중 ...")
    }
}, [isCrackDetect,isCrackMeasure,isDefectDetect, isDefectMeasure,isDrawer,isEstimator])

    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
            <Form.Item className={styles.FormItem}  label="프로젝트 명">
                            <Input value={projectName} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량의 종류">
                                <Select value={"concrete"} placeholder="선택해주세요" className={styles.selectDiv}>
                                        <Option value="Steel">스틸박스거더교</Option>
                                        <Option value="Beem" >PSC빔교</Option>
                                        <Option value="concrete">콘크리트박스거더교</Option>
                                </Select>    
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 이름(한)">
                            <Input value={bridgeNameKor} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 이름(영)">
                            <Input value={bridgeNameEng} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량의 길이">
                            <Input value={bridgeLength} type={"number"} suffix="m"/> 
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 개수">
                            <Input value={spanCount} type={"number"} suffix="개"/>   
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 번호">    
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 부재"> 
                                <Checkbox value="Girder" checked={bridgeType?.includes("Girder") ? true : false} disabled>거더 하면</Checkbox>
                                <Checkbox value="GirderSide"checked={bridgeType?.includes("GirderSide") ? true : false} disabled>거더 측면</Checkbox>    
                                <Checkbox value="Slab" checked={bridgeType?.includes("Slab") ? true : false} disabled>슬라브 하면</Checkbox>
                                <Checkbox value="SlabSide" checked={bridgeType?.includes("SlabSide") ? true : false} disabled>슬라브 측면</Checkbox>
                                <Checkbox value="Pier" checked={bridgeType?.includes("Pier") ? true : false} disabled>교각</Checkbox>
                                <Checkbox value="Abutment" disabled>교대</Checkbox>   
                            </Form.Item>
                            {/* <Form.Item className={styles.FormItem} label="교량 부재"> 
                                <Checkbox value="Girder" onClick={onclick}>거더 하면</Checkbox>
                                <Checkbox value="GirderSide" onClick={onclick}>거더 측면</Checkbox>    
                                <Checkbox value="Slab" onClick={onclick}>슬라브 하면</Checkbox>
                                <Checkbox value="SlabSide" onClick={onclick}>슬라브 측면</Checkbox>
                                <Checkbox value="Pier" onClick={onclick}>교각</Checkbox>
                                <Checkbox value="Abutment" onClick={onclick} disabled>교대</Checkbox>   
                            </Form.Item>
                            {ListArr}
                            {pierConfirm === true ?
                                <Form.Item className={styles.FormItem} label="교각 번호">    
                                    {pier_number_render}     
                                </Form.Item> : <div></div>
                            } */}
                            <Form.Item className={styles.FormItem} label="경간 길이">
                            <Input value={spanLength} type={"number"} suffix="m"/>   
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 분류 길이">
                            <Input value={spanClassLength} type={"number"} suffix="m"/>   
                            </Form.Item>
                            <Form.Item className={styles.submitBtn}>
                                {
                                    !isStart ? 
                                    <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '200px'}} >AI 안전점검 시작</Button>
                                    : 
                                    <>
                                    <Spin spinning={true} size={"large"}/>
                                    <p>{text}</p>
                                    </>

                                    
                                }
                            
                        </Form.Item>
            </Form>
            </>


    )
}
