import styles from '../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { Button, Form, Radio,Select, Input, Checkbox} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../Store/Global';
import { bridge_project_name, bridge_type, bridge_name_kor, bridge_name_eng, bridge_length, bridge_sub_type, bridge_span_count, bridge_span_num, bridge_span_length, bridge_span_class_length,
    girder_count, girder_camera_count, girder_width, girderside_height, pier_count, pier_film_count, pier_height, pier_radius, slab_count, slab_width,
    pier_number_list, span_number_list } from "../../../../Store/State/projectAtom";

const { Option } = Select;

export default function CreateSettingTab() {
    let token : string | null = localStorage.getItem("token") 


    const [ListArr, setListArr] = useState<any>([]);
    const [projectName, setProjectName] = useRecoilState<any>(bridge_project_name); 
    const [bridgeType, setBridgeType] = useRecoilState<any>(bridge_type);
    const [bridgeNameKor, setBridgeNameKor] = useRecoilState<any>(bridge_name_kor); 
    const [bridgeNameEng, setBridgeNameEng] = useRecoilState<any>(bridge_name_eng); 
    const [bridgeLength, setBridgeLength] = useRecoilState<any>(bridge_length); 
    const [spanCount, setSpanCount] = useRecoilState<any>(bridge_span_count); 
    const [spanNum, setSpanNum] = useRecoilState<any>(bridge_span_num); 
    const [bridgeSubType, setBridgeSubType] = useRecoilState<any[]>(bridge_sub_type);
    const [spanLength, setSpanLength] = useRecoilState<any>(bridge_span_length); 
    const [spanClassLength, setSpanClassLength] = useRecoilState<any>(bridge_span_class_length); 
    const [girderCount, setGirderCount] = useRecoilState<any>(girder_count); 
    const [girderCameraCount, setGirderCameraCount] = useRecoilState<any>(girder_camera_count); 
    const [girderWidth, setGirderWidth] = useRecoilState<any>(girder_width); 
    const [girdersideHeight, setGirdersideHeight] = useRecoilState<any>(girderside_height); 
    const [pierCount, setPierCount] = useRecoilState<any>(pier_count); 
    const [pierFilmCount, setPierFilmCount] = useRecoilState<any>(pier_film_count); 
    const [pierHeight, setPierHeight] = useRecoilState<any>(pier_height); 
    const [pierRadius, setPierRadius] = useRecoilState<any>(pier_radius); 
    const [slabCount, setSlabCount] = useRecoilState<any>(slab_count); 
    const [slabWidth, setSlabWidth] = useRecoilState<any>(slab_width); 

    const handleProjectName = (event:any) => {
        setProjectName(event.target.value);
    }    

    const handleBridgeType = (event:any) => {
        setBridgeType(event.target.value);
    }

    const handleBridgeNameKor = (event:any) => {
        setBridgeNameKor(event.target.value);
    }
        
    const handleBridgeNameEng = (event:any) => {
        setBridgeNameEng(event.target.value);
    }  

    const handleBridgeLength = (event:any) => {
        setBridgeLength(event.target.value);
    }  

    const handleSpanCount = (event:any) => {
        setSpanCount(event.target.value);
    }  
    
    const handleSpanNum = (event:any) => {
        setSpanNum(event.target.value);
    } 

    const handleBridgeSubType = (event:any) => {
        setBridgeSubType(event.target.value);
    }  

    const handleSpanLength = (event:any) => {
        setSpanLength(event.target.value);
    } 

    const handleSpanClassLength = (event:any) => {
        setSpanClassLength(event.target.value);
    } 

    const handleGirderCount = (event:any) => {
        setGirderCount(event.target.value);
    }  

    const handleGirderCameraCount = (event:any) => {
        setGirderCameraCount(event.target.value);
    }  

    const handleGirderWidth = (event:any) => {
        setGirderWidth(event.target.value);
    }  

    const handleGirdersideHeight = (event:any) => {
        setGirdersideHeight(event.target.value);
    }  

    const handlePierCount = (event:any) => {
        setPierCount(event.target.value);
    }  

    const handlePierFilmCount = (event:any) => {
        setPierFilmCount(event.target.value);
    }  

    const handlePierHeight = (event:any) => {
        setPierHeight(event.target.value);
    }  

    const handlePierRadius = (event:any) => {
        setPierRadius(event.target.value);
    }  

    const handleSlabCount = (event:any) => {
        setSlabCount(event.target.value);
    }  
    
    const handleSlabWidth = (event:any) => {
        setSlabWidth(event.target.value);
    }  

    let span_list: any = []
    let pier_list : any = []

    const [span_number_render, setSpan_number_render] = useState<any>([])
    const [pier_number_render,setPier_number_render]=useState<any>([])
    const [span_num_list, setSpan_number_list] = useRecoilState<any>(span_number_list)
    const [pier_num_list, setPier_number_list] = useRecoilState<any>(pier_number_list)
    const [pierConfirm, setPierConfirm] = useState<boolean>(false)
    const [span_count, setSpan_count] = useState<number>(0);
    
    const pier_countInput = (e: any) => {
        setPierCount(Number(e.target.value));
        
        let pier_number=[]
        for (let i = 0; i < Number(e.target.value); i++){
            
            pier_number.push(
                <Input type={"number"} id={String((i))} defaultValue={0} onChange={pier_number_add} suffix="번"  />
            )
            console.log(pier_number)
        }
        setPier_number_render(pier_number)
    }
    const span_countInput = (e: any) => {
        setSpan_count(Number(e.target.value));
        let span_number = []
        for (let i = 0; i < Number(e.target.value); i++){
            span_number.push(
                <Input type={"number"} id={String((i))} defaultValue={0} onChange={span_number_add} suffix="번"  />
            )
        }
        setSpan_number_render(span_number)
            
    }
    const span_number_add = (e: any) => {
        const index = span_list.findIndex((item: any) => Number(item.key) === Number(e.target.id) + 1)
        if (index === -1) {
            span_list.push({key: Number(e.target.id) + 1, num: e.target.value })
        } else {
            span_list[index] = { ...span_list[index], num:e.target.value}
        }
        setSpan_number_list(span_list)
    }
    const pier_number_add = (e: any) => {
        const index = pier_list.findIndex((item: any) => Number(item.key) === Number(e.target.id) + 1)
        if (index === -1) {
            pier_list.push({key: Number(e.target.id) + 1, num: e.target.value })
        } else {
            pier_list[index] = { ...pier_list[index], num:e.target.value}
        }
        setPier_number_list(pier_list)
    }


    const onclick = (e: any) => {
        let arr: any = [...bridgeType];
        let list: any = [...ListArr];
        // console.log(e.target.value, e)
        if (e.target.checked === true) {
            if (arr.includes(e.target.value) === false) {
                arr.push(e.target.value)
            } 
        } else {
            arr = arr.filter((a:string) => a !== e.target.value)
        }
        setBridgeType(arr)

        if (e.target.checked === true) {
            if (e.target.value === 'Girder') {
                list.push(
                    <div key={e.target.value}>
                        <Form.Item label="거더 개수" className={styles.FormItem} key="Girder_count">
                            <Input type={"number"} defaultValue={girderCount} onChange={handleGirderCount} suffix="개" />
                        </Form.Item>
                        <Form.Item label="거더 촬영 횟수" className={styles.FormItem} key="Girder_camera_count">
                            <Input type={"number"} defaultValue={girderCameraCount} onChange={handleGirderCameraCount} suffix="개" />
                        </Form.Item>
                        <Form.Item label="거더 하면- 폭" className={styles.FormItem} key="Girder_width">
                            <Input type={"number"} defaultValue={girderWidth} onChange={handleGirderWidth} suffix="m" />
                        </Form.Item>
                    </div>
                )
            } else if (e.target.value === 'GirderSide') {
                list.push(<div key={e.target.value}> 
                        <Form.Item label="거더 측면 - 높이" className={styles.FormItem} key="Girder_height">
                            <Input type={"number"} defaultValue={girdersideHeight} onChange={handleGirdersideHeight} suffix="m"  />
                        </Form.Item>  
                    </div>)
            } else if (e.target.value === 'Pier') {
                setPierConfirm(true)
                list.push(
                    <div key="Pier">
                        <Form.Item label="교각 개수" className={styles.FormItem} key="Pier_count">
                            <Input type={"number"} defaultValue={pierCount} onChange={handlePierCount} suffix="개"  />
                        </Form.Item>
                        <Form.Item label="교각 촬영 횟수" className={styles.FormItem} key="Pier_film_count">
                            <Input type={"number"} defaultValue={pierFilmCount} onChange={handlePierFilmCount} suffix="개"  />
                        </Form.Item>
                        <Form.Item label="교각 높이" className={styles.FormItem} key="pier_height">
                            <Input type={"number"} defaultValue={pierHeight} onChange={handlePierHeight} suffix="개"  />
                        </Form.Item>
                         <Form.Item label="교각 반지름" className={styles.FormItem} key="pier_radius">
                            <Input type={"number"} defaultValue={pierRadius} onChange={handlePierRadius} suffix="m"  />
                        </Form.Item>
                    </div>
                )
            } else if (e.target.value === 'Slab') {
                list.push(
                    <div key='Slab'>
                        <Form.Item label="슬라브 개수" className={styles.FormItem} key="Slab_count">
                            <Input type={"number"} defaultValue={slabCount} onChange={handleSlabCount} suffix="개"/>
                        </Form.Item>
                        <Form.Item label="슬라브 폭" className={styles.FormItem} key="Slab_width">
                            <Input type={"number"} defaultValue={slabWidth} onChange={handleSlabWidth} suffix="m"/>
                        </Form.Item>
                    </div>
                )
            }
            // setListArr(list)
        } else {
            console.log(list)
            const val: string = e.target.value
            list=list.filter((a: any) => a.key!==val)
        }
        setListArr(list)
        
    }

    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 9 }} layout="horizontal">
                            <Form.Item className={styles.FormItem}  label="프로젝트 명">
                            <Input value={projectName} onChange={handleProjectName} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량의 종류">
                                <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={handleBridgeType}>
                                        <Option value="Steel">스틸박스거더교</Option>
                                        <Option value="Beem" >PSC빔교</Option>
                                        <Option value="concrete">콘크리트박스거더교</Option>
                                </Select>    
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 이름(한)">
                            <Input value={bridgeNameKor} onChange={handleBridgeNameKor} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 이름(영)">
                            <Input value={bridgeNameEng} onChange={handleBridgeNameEng} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량의 길이">
                            <Input value={bridgeLength} onChange={handleBridgeLength} type={"number"} suffix="m"/> 
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 개수">
                            <Input value={spanCount} onChange={handleSpanCount} type={"number"} suffix="개"/>   
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 번호">    
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="교량 부재"> 
                                <Checkbox value="Girder" onClick={onclick}>거더 하면</Checkbox>
                                <Checkbox value="GirderSide" onClick={onclick}>거더 측면</Checkbox>    
                                <Checkbox value="Slab" onClick={onclick}>슬라브 하면</Checkbox>
                                <Checkbox value="SlabSide" onClick={onclick}>슬라브 측면</Checkbox>
                                <Checkbox value="Pier" onClick={onclick}>교각</Checkbox>
                                <Checkbox value="Abutment" onClick={onclick} disabled>교대</Checkbox>   
                            </Form.Item>
                            {ListArr}
                            {pierConfirm === true ?
                                <Form.Item className={styles.FormItem} label="교각 번호">    
                                    {pier_number_render}     
                                </Form.Item> : <div></div>
                            }
                            <Form.Item className={styles.FormItem} label="경간 길이">
                            <Input value={spanLength} onChange={handleSpanLength} type={"number"} suffix="m"/>   
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="경간의 분류 길이">
                            <Input value={spanClassLength} onChange={handleSpanClassLength} type={"number"} suffix="m"/>   
                            </Form.Item>



                            
            </Form>
            </>


    )
}
