import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { User } from '../../../alltypes';
import { apiHelper } from '../../../allutils';
import { ReturnValues } from '../../../allmodels';
import { API_URL } from '../../../Store/Global';
import axios from "axios";
import moment from 'moment';

import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
  import Typography from '@mui/material/Typography';
  import Box from '@mui/material/Box';

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '200px',
            height: '200px',
            marginLeft : '3rem',
            
            
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            style={{
                color: '#0F6FB7',
                fontSize: '50px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '100%',
                letterSpacing: '-1px'
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

export default function DashboardPanorama() {
    const projectId = localStorage.getItem('projectId')
    const projectType = localStorage.getItem('projectType');
    // const companyId = localStorage.getItem("companyId")
    const navigate = useNavigate();

    //token
    let token: string | null = localStorage.getItem("token") 
    useEffect(()=>{
        if (typeof window !== 'undefined' || "null") {
            console.log('You are on the browser');
                    token = localStorage.getItem("token");
        } else {
            console.log('You are on the server');
            // 👉️ can't use localStorage1
        }
        
    })

    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));
    const [isBind, setIsBind] = useState(false);
    const [data, setData] = useState<User>();

    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  console.log(res)
                  // localStorage.set("project_id", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`);
                    // setId(res.data.data.id)
                    // setCompanyId(res.data.data.companyId === null ? "1" : res.data.data.companyId)
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

    const ProjectList: any =[];
    const [PJlist, setPJlist] = useState<any | undefined>([]);
    let task_name: any;    

    if (projectType === 'tunnel') {
      task_name=["hor_stitch", "ruler_view","create_csv","cut_image","ver_stitch_old"]
  } else if (projectType === 'airport') {
      task_name=['imageresize_airport','horpanorama_airport','imageRotation_airport','ver_stitch_old ']
  } else if (projectType === 'dam') {
      task_name=["hor_stitch", "ruler_view","create_csv","cut_image","ver_stitch_old"]
  } else if (projectType === 'bridge') {
      
  }
    useEffect(() => {
        const response = axios({
            method: "post",
            url: API_URL + `/scheduler/job/list`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`, "Content-Type" : `application/json`},
            data: {
                "project_id": projectId,
                "company_id": companyId
              },
        }).then((res) => {
            if (res.data.check === true) {
                
                console.log(res)
                for (let i = 0; i < res.data.data.jobs.length; i++) {
                    const name = res.data.data.jobs[i].task_name;       
                    if (task_name.includes(name)===true) {
                        ProjectList.push({
                            key: res.data.data.jobs[i].job_id,
                            no: 'C'+String(i+1).padStart(2,'0') ,
                            task_name: <a>{res.data.data.jobs[i].task_name}</a>,
                            status: res.data.data.jobs[i].status,
                            created: moment(res.data.data.jobs[i].created).format('YYYY-MM-DD HH:MM:SS'),
                        })
                    }
                }
                console.log(ProjectList)
                if(ProjectList.length > 5) {
                  setPJlist(ProjectList.slice(-5))
                  console.log("save ", ProjectList.slice(-5))
                }
                else {
                  setPJlist(ProjectList)
                  console.log("save ", ProjectList)
                }

            } else {
                console.log("실패");
            }
        }).catch((err) => {
            console.log(err);
        });
}, []);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

    return (
        <div style={{ display:'flex'}}>
                <div>
                    <TableContainer   sx={{ width: '1200px', marginLeft: '2rem', marginTop: '2rem' }}>
                    <Table sx={{ minWidth: 500 , backgroundColor: '#fffff' }} aria-label="customized table">
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard', fontSize:'0.9rem', fontWeight:600, background:'#F5F5F5', color :'#3A4557' }}>작업</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[0]? PJlist[0]?.task_name : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[1]? PJlist[1]?.task_name : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[2]? PJlist[2]?.task_name : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[3]? PJlist[3]?.task_name : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[4]? PJlist[4]?.task_name : '-'}</StyledTableCell>
                            </StyledTableRow> 
                            <StyledTableRow>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard', fontSize:'0.9rem', fontWeight:600, background:'#F5F5F5', color :'#3A4557' }}>상태</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[0]? PJlist[0]?.status : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[1]? PJlist[1]?.status : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[2]? PJlist[2]?.status : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[3]? PJlist[3]?.status : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[4]? PJlist[4]?.status : '-'}</StyledTableCell>
                            </StyledTableRow> 
                            <StyledTableRow>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard', fontSize:'0.9rem', fontWeight:600, background:'#F5F5F5', color :'#3A4557' }}>시작 시간</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[0]? PJlist[0]?.created : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[1]? PJlist[1]?.created : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[2]? PJlist[2]?.created : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[3]? PJlist[3]?.created : '-'}</StyledTableCell>
                                <StyledTableCell align="center" style={{width: 200, fontFamily : 'inter-pretendard' }}>{PJlist[4]? PJlist[4]?.created : '-'}</StyledTableCell>
                            </StyledTableRow> 
                        </TableBody>
                    </Table>
                    </TableContainer>
                </div>
                <div>
                    <CircularProgressWithLabel style={{ width : '200px', height : '200px', marginLeft : '3rem'}} value={PJlist[PJlist.length-1]?.status === "done" ? 100 : PJlist[PJlist.length-1]?.status === "wait" ? 20 : 0} />
                </div>
            </div>
    )
}