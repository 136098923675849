import styles from '../../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Radio,Select, Input, Table, Spin} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../../Store/Global';

interface TableSet {
    key: any;
    no: any;
    firstCam:any;
    lastCam:any;
}

export default function Setting() {
    const navigate = useNavigate();
    const [pName, setpName] = useState('');
    const [cNameKr, setcNameKr] = useState('');
    const [cNameEn, setcNameEn] = useState('');
    const [speed, setSpeed] = useState('10');
    const [fps, setFps] = useState('60');
    const [numSet, setnumSet] = useState('');
    const [numCam, setnumCam] = useState('');
    const [spanCount, setspanCount] = useState('');
    const [spanLen, setspanLen] = useState('');
    const [isStart, setIsStart] = useState<boolean>(false)

    const list: any[] = [pName, cNameKr, cNameEn, speed, fps, numSet, numCam, spanCount, spanLen ]

        const plus = (event:any) => {
    
            console.log(list);
            alert(`설정값을 변경하였습니다`);
            window.location.replace("/Facility/Panorama/Dashboard")
        }
        let token : string | null = localStorage.getItem("token") 
        let projectId: string | null = localStorage.getItem("projectId");


        // const formItemLayoutWithOutLabel = {
        //     wrapperCol: {
        //     xs: { span: 24, offset: 0 },
        //     sm: { span: 24, offset: 0 },
        //     },
        // };

        const [projectName, setProjectName] =useState(0); 
        const [korName, setKorName] =useState(0);
        const [cameraNo, setCamraNo] =useState(0); 
        const [spanNo, setSpanNo] =useState(0); 
        const [enName, setEnName] =useState(0);
        const [speedd, setSpeedd] =useState(0); 
        const [fpss, setFpss] =useState(0); 
        const [spanLenn, setSpanLenn] =useState(0); 
        const [direction, setDirection] = useState('');
        const[dirvlaue, setDirvlaue] = useState(direction)

        let TableSet : TableSet[] = [];

        const [filmSetResult, setfilmSetResult] = useState<boolean>(false);
        const [TableSetStauts, setTableSetStauts] = useState(TableSet);
        // let TableSet:any[] = [];



        const { Column } = Table;
        const [leng, SetLeng] = useState('');

        const spanNoInput = (e:any) => {setSpanNo(e.target.value);}


        useEffect(() => {
            const response = axios({
                method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
            }).then((res) => {
                    // console.log(res.data.check) 
                    const settings: any = JSON.parse(res.data.data.settings)
                    // console.log("dlfmaaaaaaaaaaaaaaaa"+res.data.data.title)
                    if (res.data.check === true) {
                        for (let i = 0; i < 1; i++) {
                            console.log('세트갯'+JSON.parse(settings.filmSetCount)[i].FirstCamera)
                            console.log(`카메라갯수${settings.tunnel_kor}` )
                            console.log(`스팬갯수${settings.direction}` )
                            setCamraNo(settings.cameraCount)
                            setSpanNo(settings.spanCount)
                            setProjectName(res.data.data.title)
                            setKorName(settings.tunnel_kor)
                            setEnName(settings.tunnel_eng)
                            setSpeedd(settings.scanSpeed)
                            setFpss(settings.scanSpeed.fps)
                            setSpanLenn(settings.spanLength)
                            setDirection(settings.direction)
                            if(settings.direction === 'P01'){
                                setDirvlaue('상행')
                            } else {
                                setDirvlaue('하행')
                                }
                            }

                        for(let i = 0; i < JSON.parse(settings.filmSetCount).length; i++){
                            TableSet.push({
                                key:i,
                                no: i+1,
                                firstCam: JSON.parse(settings.filmSetCount)[i].FirstCamera,
                                lastCam: JSON.parse(settings.filmSetCount)[i].LastCamera
                            })
                                // console.log(JSON.parse(settings.filmSetCount).length)

                            // SetLeng(JSON.parse(settings.filmSetCount).length);

                        }
                    } else {
                        console.log("실패");
                    }
                
                    setTableSetStauts(TableSet);
                    if(TableSetStauts.length > 0){
                        setfilmSetResult(true)
                }

                }).catch((err) => {
                    console.log(err);
                });
        }, []);

        const submitClick1 = () => {
            alert("터널 시설물에 대한 AI 안전점검이 시작되었습니다.")
            setIsStart(true)
            setTimeout(function() {
              alert("AI 안전점검이 완료되었습니다.")
              setIsStart(false)
              navigate(`/project/crack/detector/${projectId}`)
            }, 20000);
        }
        
        


    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
                            <Form.Item className={styles.FormItem}  label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>프로젝트 명</p>}>
                                <Input value={projectName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>터널 이름(한)</p>}>
                                <Input value={korName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>터널 이름(영)</p>}>
                                <Input value={enName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>터널 스캐너 이동 속도</p>}>
                                <Input value={speed} suffix="km/h"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>영상 FPS</p>}>
                                <Input value={fps} suffix="FPS"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} name="radio-button" label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>진행 방향</p>}>
                                <Input value={dirvlaue}  />
                            </Form.Item>
                            {/* <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>촬영 set 수" style={{marginBottom:'0px'}}>
                            {
                                filmSetResult === true ? 
                                <Table dataSource={TableSetStauts} pagination={false} size="small</p>}>
                                  
                                    <Column title="set 번호" dataIndex="no" key="no"/>
                                    <Column title="첫번째 카메라 번호" dataIndex="firstCam" key="firstCam"/>
                                    <Column title="마지막 카메라 번호" dataIndex="lastCam" key="lastCam"/>
                                </Table>
                            :
                            <div>데이터를 불러오지 못했습니다</div>                        
                            }

                            </Form.Item> */}
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 수</p>}>
                                <Input value={cameraNo} suffix="개"  />                         
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 수</p>}>
                                <Input value={spanNo} suffix="개"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 길이</p>}>
                               <Input value={spanLenn} suffix="개"  />  
                            </Form.Item>
                            <Form.Item className={styles.submitBtn}>
                                {
                                    !isStart ? 
                                    <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '200px'}} >AI 안전점검 시작</Button>
                                    : 
                                    <>
                                    <Spin spinning={true} size={"large"}/>
                                    <p>진단 중...</p>
                                    </>

                                    
                                }
                            
                        </Form.Item>
            </Form>
            </>


    )
}
