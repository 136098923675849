import { useState, useEffect } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { Topbar, Sidebar, ProjectSidebar, Footer } from '../allmolecules';


export default function LayoutTemplate(props:any) {
    const projectType = localStorage.getItem('projectType');
    const href = window.location.href;
    return (
        <>
        <div style={{ display: "flex", height: "100vh", overflowX: 'hidden'}}>
            { !href.includes('project/list') && !href.includes('project/create') && !href.includes('user')? <ProjectSidebar/> : <Sidebar/>}
            <div style={{ display: "block", height: "100vh", width: "100%"}}>
                <Topbar/>
                <div style={{ marginLeft: '16.25rem', height: "100%", width: "100%", background : '#F6FCFF'}}>
                    {props.children}
                </div>
                <Footer/>
            </div>
        </div>
        </>
    );
};



  