// import ReactDOM from 'react-dom';
// import App from './App';
// import { RecoilRoot } from 'recoil';
// import { BrowserRouter } from 'react-router-dom';

// ReactDOM.render(
//   <RecoilRoot>
//     <BrowserRouter basename={process.env.PUBLIC_URL}>
//       <App />
//     </BrowserRouter>
//   </RecoilRoot>,
//    document.getElementById('root') as HTMLElement
// );

// import ReactDOM from "react-dom";
// import { createRoot } from "react-dom/client"; // createRoot를 import
// import { RecoilRoot } from "recoil";
// // import { BrowserRouter } from "react-router-dom";
// import App from "./App";

// const container = document.getElementById("root"); // 루트 엘리먼트 가져오기
// const root = createRoot(container); // 루트 인스턴스 생성

// root.render(
//     <RecoilRoot>
//         {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
//             <App />
//         {/* </BrowserRouter> */}
//     </RecoilRoot>
// );

import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";

const container = document.getElementById("root"); // 루트 엘리먼트 가져오기

if (container) {
    // container가 null이 아닌지 확인
    const root = createRoot(container); // 루트 인스턴스 생성
    root.render(
        <RecoilRoot>
            <App />
        </RecoilRoot>
    );
} else {
    console.error("루트 컨테이너 요소를 찾을 수 없습니다!");
}
