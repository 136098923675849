import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Image } from 'antd';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, MenuItem, styled, Checkbox } from '@mui/material';

type Order = 'asc' | 'desc';

interface ImageName {
  name: string,
}

interface Image {
  key: number,
  name: string,
  url: string,
  couple:string|any,
}
  interface Data {
    num: string,
    name: string,
  }

export default function DamXAIHeatmapResultPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
      }
    }
    
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        fontSize: '1rem',
        fontWeight: 600,
        backgroundColor: '#F5F5F5',
        color: '#3A4557',
        width: '42.8125rem',
        height: '2.5rem',
        border: '1px solid #DDD',
        borderTop: '0.15rem solid #3A4557',
        padding : 0
      },
      [`&.${tableCellClasses.body}`]: {
        color: '#3A4557',
        textAlign: 'center',
        fontSize: '0.8125rem',
        width: '42.8125rem',
        height: '3rem',
        border: '1px solid #DDD',
        padding : 0,
      },
    }));

    const setting: any = localStorage.getItem("settings")
    const typeArr: any = JSON.parse(setting).bridge_type
    let job_id = 0;

    const oriImgUrl : Image[] = [];
    const [oriImgList, setOriImgList] = useState<any[]>(oriImgUrl);
    const [oriSelet, setOriSelect] = useState(0)
    const [oriList, setOriList] = useState<boolean>(false)
    const [resList,setResList]  = useState<boolean>(false)

    const resImgUrl : Image[] = [];
    const [resImgList, setResImgList] = useState<any[]>(resImgUrl);
    const [data, setData] = useState<any[]>(resImgUrl);

    useEffect(() => {
      setData(resImgList.slice((page-1)*10, page*10));
    }, [page]);

    const [num, setNum] = useState<number>(0);
    const onClickPanor = (i:any) => {
      setNum(i-1);
  };

    const filter : ImageName[] = [];
    const [filterList, setFilterList] = useState<any[]>(filter);
    // const [resSelet,setResSelect] = useState(1)
    const [last, setLast] = useState(0)

    // const [BridgeType, setBridgeType] = useState<string>("Girder")
    const [BridgeType, setBridgeType] = useState<string>()

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

        useEffect(() => {
          let getIdCompany = async () => {
            if (token !== null) {
              
              console.log("프로젝트ID" + projectId);
              const response = await axios({
                method: "get",
                url: `${API_URL}/account/auth/check/${token}`,
              })
                .then(async (res) => {
                  if (res.data.check === true) {
                    setUserId(res.data.data.id)
                    setcompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(
                      `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                    );
                    return {
                      email: res.data.data.email,
                      name: res.data.data.name,
                      phone: res.data.data.phone,
                      avatar: res.data.data.avatar,
                      role: res.data.data.role,
                      id: res.data.data.id,
                      companyId: res.data.data.companyId,
                    };
                  } else {
                    console.log("토큰 만료");
                    localStorage.removeItem("token");
                    alert("토큰이 만료었습니다 다시 로그인 해주세요");
                    window.location.replace("/Main");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          };
          getIdCompany();
        }, []);
      
      
      useEffect(() => {
          // 결과(heatmap)이미지 가져오기
          (async () => {
              const heatmap_result_path=  projectType!=='Bridge'? `/project/${projectId}/stageXAI/Heatmap`: `/project/${projectId}/stageXAI/Heatmap/`+ BridgeType
              await axios({
                  method: 'get',
                  url: API_URL+'/File/Files',
                  headers: { "accept": `application/json`, "access-token": `${token}` },
                  params : {path :heatmap_result_path}
          }).then((res) => {
            
              if (res.data.check === true) {
                  console.log("heatmap 불러오기 성공")
                  let files = res.data.data.files
                  files.map((file:any, i:any) => {
                    resImgUrl.push({
                      key: i + 1,
                      name: file,
                      url: `${IMAGE_URL}/image?path=`+heatmap_result_path+`/${file}&width=360`,
                      couple: <a href={`${IMAGE_URL}/image?path=`+heatmap_result_path+`/${file}&width=360`} download>Couple</a>
                    })
                  })
                  // if(localStorage.getItem("heatmapArr") !== null) {
                  //   const temp = JSON.parse(localStorage.getItem('heatmapArr')!);
                  //   const filters = JSON.parse(localStorage.getItem('heatmapArr') || '{}');
                  //   for(let j = 0; j < filters.length; j++) {
                  //     for (let i = 0; i < files.length; i++) {
                  //       if(filters[j] === files[i]) {
                  //         console.log("in4")
                  //         resImgUrl.push({
                  //           key: i + 1,
                  //           name: files[i],
                  //           url: `${IMAGE_URL}/image?path=`+heatmap_result_path+`/${files[i]}&width=360`,
                  //           couple: <a href={`${IMAGE_URL}/image?path=`+heatmap_result_path+`/${files[i]}&width=360`} download>Couple</a>
                  //       })
                  //       }
                  //     }
                  //   }
                  // }
                  console.log(resImgUrl)
                  setResImgList(resImgUrl)
                  setData(resImgUrl.slice((page-1)*10, page*10))
                  setResList(true)
              } else {
                  console.log("heatmap 불러오기 실패")
              }
              console.log(oriImgUrl)
          })
      })();

      //원본 original 이미지 가져오기
      const heatmap_original_path= projectType!=='Bridge'? `/project/${projectId}/stageXAI/original/heatmap`: `/project/${projectId}/stageXAI/original/heatmap/`+ BridgeType
          axios({
              method: 'get',
              url: API_URL + '/File/Files',
              headers: { "accept": `application/json`, "access-token": `${token}` },
              params: { path: heatmap_original_path }
          }).then((res) => {
              if (res.data.check === true) {
                  console.log("ori_file 불러오기 성공")
                  let files = res.data.data.files
                  setLast(files.length - 1)
                  files.map((file:any, i:any) => {
                    oriImgUrl.push({
                      key: i + 1,
                      name: file,
                      url: `${IMAGE_URL}/image?path=`+heatmap_original_path+`/${file}&width=360`,
                      couple: <a href={`${IMAGE_URL}/image?path=`+heatmap_original_path+`/${file}&width=360`} download>download</a>
                    })
                  })

                  // if(localStorage.getItem("heatmapArr") !== null) {
                  //   const temp = JSON.parse(localStorage.getItem('heatmapArr')!);
                  //   const filters = JSON.parse(localStorage.getItem('heatmapArr') || '{}');
                  //   for(let j = 0; j < filters.length; j++) {
                  //     for (let i = 0; i < files.length; i++) {
                  //       if(filters[j] === files[i]) {
                  //         console.log("in4")
                  //         oriImgUrl.push({
                  //           key: i + 1,
                  //           name: files[i],
                  //           url: `${IMAGE_URL}/image?path=`+heatmap_original_path+`/${files[i]}&width=360`,
                  //           couple: <a href={`${IMAGE_URL}/image?path=`+heatmap_original_path+`/${files[i]}&width=360`} download>download</a>
                  //       })
                  //       }
                  //     }
                  //   }
                  // }

                  console.log(oriImgUrl)
                  setOriImgList(oriImgUrl)
                  setOriList(true)
  
              } else {
                  console.log("file 불러오기 실패")
                  setOriList(false)
                  setResList(false)
              }
              console.log(oriImgUrl)
          })
          
      },[BridgeType])
      const nextClick = () => {
          let n = oriSelet
          console.log(n, last)
          if (n === last) {
              // console.log("마지막 번호입니다.")
              setOriSelect(0)
          } else {
              setOriSelect(n+1)
          }
      }
  
      let result: any;
      // const confirm = (record : any) => {
      async function confirm (record : any) {
          console.log(job_id)
          axios({
              method: "post",
              url: API_URL + '/scheduler/job/query',
              headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                  data: {
                      "job_id": job_id ,
                      "company_id": companyId
                  }
          }).then(async (res) => {
              console.log(res)
              if (res.data.check == true) {
                  console.log("성공",res.data.data.status)
                  if (res.data.data.status === "done") {
                      console.log(record)
                      const link = document.createElement('a');
                      let src = record.url.substring(0, 60) + `/Heatmap/couple/${oriImgList[record.key - 1].name}`;
                      const imageBlob = await (await fetch(src)).blob();
                      src = URL.createObjectURL(imageBlob);
                      link.href = src;
                      link.download = oriImgList[record.key - 1].name.slice(0,-4)
                      link.click();
                      
                      clearInterval(result)
                      }  else if(res.data.data.status === "error"){
                          alert("해당 파일이 없습니다.")
                      }
                   } else {
                      console.log("실패")
                   }
              })
          
      }

      async function download() {
          const original_path = projectType==='Bridge'? `stageXAI/original/heatmap/${BridgeType}/`:'stageXAI/original/heatmap/'
          const result_path = projectType==='Bridge'? `stageXAI/Heatmap/${BridgeType}/`:'stageXAI/Heatmap/'
  
          const link = document.createElement('a');
          let src = `${IMAGE_URL}/image?path=/project/${projectId}/`+original_path+`${oriImgList[oriSelet].name}`;
          const imageBlob =  (await fetch(src)).blob();
          src = URL.createObjectURL(await imageBlob);
          link.href = src;
          link.download = oriImgList[oriSelet].name.slice(0,-4)
          link.click();
  
          const link2 = document.createElement('a');
          let src2 = `${IMAGE_URL}/image?path=/project/${projectId}/`+result_path+`${resImgList[oriSelet].name}`;
          const imageBlob2 =  (await fetch(src2)).blob();
          src2 = URL.createObjectURL(await imageBlob2);
          link2.href = src2;
          link2.download = resImgList[oriSelet].name.slice(0,-4)
          link2.click();
      }
  
      const option_render=()=>{
          const arr: any[] = [];
          typeArr.map((type:any)=>{
                  let name=''
              if (type === 'Girder') {
                  name = '거더 하면'
              } else if (type === 'GirderSide') {
                  name = '거더 측면'
              } else if (type === 'Slab') {
                  name='슬라브 하면'
              }else if (type === 'SlabSide') {
                  name='슬라브 측면'
              } else if (type === 'Pier') {
                  name ='교각'
              } else if (type === 'Abutment') {
                  name = '교대'
              }
              arr.push(<MenuItem> {name}</MenuItem>)
          })
          return arr;
      }
      const onChangeBridgeType = (e:any) => {
          setBridgeType(e)
      }

      const handleClick = (event:any, id:any) => {
        if (event.target.classList.contains("selectCheckbox")) {
          console.log("checkbox select");
        } else {
          console.log("row link");
        }
      }
    
        // const { selected } = this.state;
        // const selectedIndex = selected.indexOf(id);
        // let newSelected = [];
  

    return (
        <Layout>
            <div style={{ display : 'flex', justifyContent: 'space-between', width:'54.5%'}}>
            <div>
              <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.875rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>XAI &gt; Heatmap 결과</div>
              <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>Heatmap 결과</div>
            </div>
            <div style={{ display: 'flex', alignItems : 'center', marginTop:'3rem'}}>
              {
                 oriImgList.length !== 0 ?
                <>
                  <div style={{ fontWeight : 700}}>이미지 명 :  </div>
                  <div style={{marginLeft:'1rem'}}>
                      {oriList ===true? oriImgList[num].name : ''}
                  </div>   
                </> : <></>
              }
               
            </div>
            </div>
            <div style={{ display : 'flex'}}>
            <div>
                
                <Box sx={{ width: '32rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', paddingBottom:'1rem'}}>
                    <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                        <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>결과 이미지 리스트 </div>
                        <div>
                            <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '1rem', background: '#0F6FB7', padding : '0.25rem 1.5rem' }}>다운로드</Button>
                        </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '30rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{width:'2rem'}} padding='checkbox' />
                                    <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7rem', fontFamily: 'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'4rem', fontFamily: 'inter-pretendard', fontSize: '1rem'}}>Couple</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize: '1rem'}}>원본</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize: '1rem'}}>결과</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {(resList===true? data:[]).map((row, i) => (
                                    <TableRow 
                                        key={row.key}
                                        style={{ height: '2.5rem'}}
                                       
                                    >
                                    <StyledTableCell align="center" style={{width:'2rem', height: '2.5rem', paddingRight:'0.5rem'}} padding='checkbox'>
                                      <Checkbox
                                        // onClick={event => this.handleClick(event, n.id)}
                                        className="selectCheckbox"
                                        // checked={isSelected}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'3rem', height: '2.5rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem'}}>{row.key}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7rem', height: '2.5rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', cursor: 'pointer'}}  onClick={() => onClickPanor(row.key)}>{row.name}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'4rem', height: '2.5rem', color : '#0F6FB7', fontWeight: 400, fontFamily: 'inter-pretendard', fontSize: '0.875rem'}} title='Couple'>{row.couple}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'3rem', height: '2.5rem', color : '#0F6FB7', fontWeight: 400, fontFamily: 'inter-pretendard', fontSize: '0.875rem'}} title='원본'>
                                      {/* {oriImgList[i].url} */}
                                      <a href={oriList ===true? oriImgList[i]?.url : ''} download>원본</a>
                                      </StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'3rem', height: '2.5rem', color : '#0F6FB7', fontWeight: 400, fontFamily: 'inter-pretendard', fontSize: '0.875rem'}} title='결과'>
                                      {/* {resImgList[i].url} */}
                                      <a href={resList === true ?resImgList[i].url : ''} download>결과</a>
                                      </StyledTableCell>
                                    </TableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '30rem', display : 'flex', justifyContent: 'center', marginTop:'4rem', marginBottom: '2rem'}}>
                                <Pagination count={resImgList.length > 10 && resImgList.length % 10 === 0 ? Math.floor(resImgList.length/10) : Math.floor(resImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                    </div>
                </Box> 
            </div>
            <div>
            <Box sx={{ width: '18.5rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '3rem', marginLeft : '3rem', marginTop: '1rem'}}>
                <div>
                <div>
                    <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem'}}>원본 이미지 </div>
                    { oriImgList.length !== 0 ?
                        <Image src={oriImgList[num].url} style={{ width: '18rem', height:'18rem', borderRadius: '0.625rem'}} /> 
                        : <div style={{ width: '20rem', borderRadius: '0.625rem'}}>이미지가 없습니다.</div>
                    }
                </div>
                <div style={{ marginTop : '2rem'}}>
                    <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem'}}>히트맵 결과 이미지 </div>
                    { resImgList.length !== 0 ?
                        <Image src={resImgList[num].url} style={{ width: '18rem', height:'18rem', borderRadius: '0.625rem'}} />
                      : <div style={{ width: '20rem', borderRadius: '0.625rem'}}>XAI 실행한 이미지가 없습니다.</div>
                    }
                </div>
                </div>
              </Box>
            </div>
            </div>
        </Layout>
    );
};

