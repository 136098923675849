import { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu  } from "react-pro-sidebar";
import { apiHelper,util,formHelper } from "../../allutils";
import { Routes, Route, Link } from "react-router-dom";
import './SidebarMolecure.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PanoramaIcon from '@mui/icons-material/Panorama';
import StraightenIcon from '@mui/icons-material/Straighten';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import AirplayIcon from '@mui/icons-material/Airplay';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Hidden } from '@material-ui/core';

export default function ProjectSidebarMolecure() {
    let id: string | null = localStorage.getItem('projectId');
    let type: string | null = localStorage.getItem('projectType');
    const pre_link = document.location.href.replace("http://", "");
    const fix_link = pre_link.split("/")[0]
    const link = pre_link.replace(fix_link, "");

    return (
        <>
            <Sidebar
                toggled={true}
                
                // backgroundColor='rgba(15, 111, 183, 1)'
                style={{ 
                            position : 'fixed',
                            top : '0',
                            zIndex: '999',
                            height: '100%',
                            width: '16.25rem',
                            fontSize: '1rem',
                        }}
            >
                <div style={{ 
                            height: '100%',
                            width: '16.25rem',
                            background: `${type === "tunnel" ? 'url("/images/sidebar/Tunnel_sidebar.png")' : type === 'dam' ? 'url("/images/sidebar/Dam_sidebar.png")' : type === 'bridge' ? 'url("/images/sidebar/Bridge_sidebar.png")' : type === 'airport' ? 'url("/images/sidebar/Airport_sidebar.png")' : 'url("/images/sidebar/Scaf_sidebar.png")'}`,
                        }} >
                <Menu 
                    menuItemStyles={{
                        button: {
                            'paddingLeft' : '10px',
                             '&:hover': {
                                backgroundColor: 'rgba(9, 94, 157, 1)',
                                color: '#ffffff'
                            },

                        },
                    }}>
                    <MenuItem 
                        // disabled={true}
                        className="menu1" 
                        component={<Link to={`/project/list`} className="link" />}
                    >
                    <h2>Deep Inspector</h2>
                    </MenuItem>
                    <MenuItem 
                        className={ link.includes('/project/dashboard') ? "MenuItemClick" : "MenuItem" }
                        component={<Link to={`/project/dashboard/${id}`} className="link" />}
                        icon={<DashboardIcon />}
                    > 
                        대시보드
                    </MenuItem>
                    <SubMenu 
                        label="전처리" 
                        className="MenuItem"
                        rootStyles={{
                            '.SubMenuItemClick' : {
                                'width' : '16rem',
                                'border-left': '4px solid white',
                                'color': 'white',
                                'background-color': '#095E9D',
                                'font-weight': '600',   
                            }
                        }}
                        icon={<CloudUploadIcon/>}
                        open={link.includes('preprocess') ? true : false}
                    >
                        {
                            type === 'tunnel' ?
                            <>
                            <MenuItem 
                            className={ link.includes('/project/preprocess/setting') ? "SubMenuItemClick" : "SubMenuItem" }
                            component={<Link to={`/project/preprocess/setting/${id}`} className="link" />}
                           
                            > <div style={{ marginLeft : '2.75rem'}}>설정</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/preprocess/videoedit') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/project/preprocess/videoedit/${id}`} className="link" />}
                                onClick={()=> alert("데이터 촬영 문의는 당사에 별도로 문의 부탁드립니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>영상 편집</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/preprocess/imagetask') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/project/preprocess/imagetask/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 작업</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/preprocess/jtag/') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/project/preprocess/jtag/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>J태그</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/preprocess/jtagconfirm') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/project/preprocess/jtagconfirm/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>J태그 검증</div> </MenuItem>
                            </> :
                            type === 'dam' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/dam/project/preprocess/setting') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/preprocess/setting/${id}`} className="link" />}
                           
                            > <div style={{ marginLeft : '2.75rem'}}>설정</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/preprocess/imageupload') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/preprocess/imageupload/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 업로드</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/preprocess/imagefilter') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/dam/project/preprocess/imagefilter/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 필터</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/preprocess/folderdiv/') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/dam/project/preprocess/folderdiv/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>폴더 구분</div> </MenuItem>
                            </> : 
                            type === 'bridge' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/bridge/project/preprocess/setting') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/preprocess/setting/${id}`} className="link" />}
                           
                            > <div style={{ marginLeft : '2.75rem'}}>설정</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/preprocess/imageupload') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/bridge/project/preprocess/imageupload/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 업로드</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/preprocess/imagefilter') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/bridge/project/preprocess/imagefilter/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 필터</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/preprocess/folderdiv/') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/bridge/project/preprocess/folderdiv/${id}`} className="link" />}
                                onClick={()=> alert("원천 데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>폴더 구분</div> </MenuItem>
                            </> : 
                            type === 'airport' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/airport/project/preprocess/setting') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/preprocess/setting/${id}`} className="link" />}
                           
                            > <div style={{ marginLeft : '2.75rem'}}>설정</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/preprocess/imageupload') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/preprocess/imageupload/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 업로드</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/preprocess/imagefilter') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/preprocess/imagefilter/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 필터</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/preprocess/folderdiv/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/preprocess/folderdiv/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>폴더 구분</div> </MenuItem>
                            </>: <></>

                        }
                        
                    </SubMenu>
                    <SubMenu 
                        label="파노라마" 
                        className="MenuItem" 
                        rootStyles={{
                            '.SubMenuItemClick' : {
                                'width' : '16rem',
                                'border-left': '4px solid white',
                                'color': 'white',
                                'background-color': '#095E9D',
                                'font-weight': '600',   
                            }
                        }}
                        icon={<PanoramaIcon/>}
                        open={link.includes('panorama') ? true : false}
                    >
                        {/* <MenuItem 
                            className="SubMenuItem" 
                            component={<Link to={`/project/panorama/setting/${id}`} className="link" />}
                        > 설정 </MenuItem> */}
                        {
                            type === 'tunnel' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/project/panorama/imageedit') ? "SubMenuItemClick" : "SubMenuItem" }
                                // component={<Link to={`/project/panorama/imageedit/${id}`} className="link" />}
                                onClick={()=> alert("데이터 업로드 이후 가능한 작업입니다.")}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 편집</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/panorama/imageconfirm') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/panorama/imageconfirm/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>이미지 검증</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/panorama/vertical') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/panorama/vertical/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수직 파노라마</div> </MenuItem>
                            </> :
                            type === 'dam' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/dam/project/panorama/horizontal') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/panorama/horizontal/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수평 파노라마</div> </MenuItem> 
                            <MenuItem 
                                className={ link.includes('/dam/project/panorama/vertical') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/panorama/vertical/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수직 파노라마</div> </MenuItem>
                            </> : 
                            type === 'bridge' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/bridge/project/panorama/horizontal') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/panorama/horizontal/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수평 파노라마</div> </MenuItem> 
                            <MenuItem 
                                className={ link.includes('/bridge/project/panorama/vertical') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/panorama/vertical/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수직 파노라마</div> </MenuItem>
                            </>
                            : 
                            type === 'airport' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/airport/project/panorama/horizontal') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/panorama/horizontal/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수평 파노라마</div> </MenuItem> 
                            <MenuItem 
                                className={ link.includes('/airport/project/panorama/vertical') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/panorama/vertical/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>수직 파노라마</div> </MenuItem>
                            </>
                            :<></>
                        }
                        
                        
                    </SubMenu>
                    {
                        type === 'tunnel' ?
                        <SubMenu 
                            label="검출 & 측정" 
                            className="MenuItem" 
                            rootStyles={{
                                '.SubMenuItemClick' : {
                                    'width' : '16rem',
                                    'border-left': '4px solid white',
                                    'color': 'white',
                                    'background-color': '#095E9D',
                                    'font-weight': '600',   
                                }
                            }}
                            icon={<StraightenIcon/>}
                            open={link.includes('crack') || link.includes('defect') ? true : false}    
                        >
                            {/* <MenuItem 
                                className="SubMenuItem" 
                                component={<Link to={`/project/crack/detectorsettting/${id}`} className="link" />}
                            > 균열 검출 설정 </MenuItem> */}
                            <MenuItem 
                                className={ link.includes('/project/crack/detector') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/crack/detector/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>균열 검출</div> </MenuItem>
                            {/* <MenuItem 
                                className="SubMenuItem" 
                                component={<Link to={`/project/crack/measuresettting/${id}`} className="link" />}
                            > 균열 측정 설정 </MenuItem> */}
                            <MenuItem 
                                className={ link.includes('/project/crack/measure') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/crack/measure/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>균열 측정</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/defect/detector') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/defect/detector/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 검출</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/defect/measure') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/defect/measure/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 측정</div> </MenuItem>
                        </SubMenu> :
                        type === 'dam' ?
                        <SubMenu 
                            label="검출 & 측정" 
                            className="MenuItem" 
                            rootStyles={{
                                '.SubMenuItemClick' : {
                                    'width' : '16rem',
                                    'border-left': '4px solid white',
                                    'color': 'white',
                                    'background-color': '#095E9D',
                                    'font-weight': '600',   
                                }
                            }}
                            icon={<StraightenIcon/>}
                            open={link.includes('defect') ? true : false}    
                        >
                            <MenuItem 
                                className={ link.includes('/dam/project/defect') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/defect/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 검출 & 측정</div> </MenuItem>
                        </SubMenu> :
                        type === 'bridge' ?
                        <SubMenu 
                            label="검출 & 측정" 
                            className="MenuItem" 
                            rootStyles={{
                                '.SubMenuItemClick' : {
                                    'width' : '16rem',
                                    'border-left': '4px solid white',
                                    'color': 'white',
                                    'background-color': '#095E9D',
                                    'font-weight': '600',   
                                }
                            }}
                            icon={<StraightenIcon/>}
                            open={link.includes('crack') || link.includes('defect') ? true : false}    
                        >
                            <MenuItem 
                                className={ link.includes('/bridge/project/crack/detector') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/crack/detector/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>균열 검출</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/crack/measure') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/crack/measure/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>균열 측정</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/defect/detector') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/defect/detector/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 검출</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/defect/measure') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/defect/measure/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 측정</div> </MenuItem>
                        </SubMenu> :
                        type === 'airport' ?
                        <SubMenu 
                            label="검출 & 측정" 
                            className="MenuItem" 
                            rootStyles={{
                                '.SubMenuItemClick' : {
                                    'width' : '16rem',
                                    'border-left': '4px solid white',
                                    'color': 'white',
                                    'background-color': '#095E9D',
                                    'font-weight': '600',   
                                }
                            }}
                            icon={<StraightenIcon/>}
                            open={link.includes('crack') || link.includes('defect') || link.includes('fod') || link.includes('metalearning') ? true : false}    
                        >
                            <MenuItem 
                                className={ link.includes('/airport/project/crack') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/crack/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>균열 검출 & 측정</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/defect') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/defect/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>결함 검출 & 측정</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/fod') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/fod/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>FOD 검출 & 측정</div> </MenuItem>
                            {/* <MenuItem 
                                className={ link.includes('/airport/project/metalearning') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/metalearning/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>메타 러닝</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/metalearningresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/metalearningresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>메타 러닝 결과</div> </MenuItem> */}
                        </SubMenu> :
                        <></>
                    }
                    
                    <SubMenu 
                        label="외관조사망도 & 보고서" 
                        className="MenuItem" 
                        rootStyles={{
                            '.SubMenuItemClick' : {
                                'width' : '16rem',
                                'border-left': '4px solid white',
                                'color': 'white',
                                'background-color': '#095E9D',
                                'font-weight': '600',   
                            }
                        }}
                        icon={<DescriptionIcon/>}
                        open={link.includes('download') ? true : false}    
                    >
                        {/* <MenuItem 
                            className="SubMenuItem" 
                            component={<Link to={`/project/download/setting/${id}`} className="link" />}
                        > 설정 </MenuItem> */}
                        <MenuItem 
                            className={ link.includes('/project/download') ? "SubMenuItemClick" : "SubMenuItem" }
                            component={<Link to={`/project/download/${id}`} className="link" />}
                        > <div style={{ marginLeft : '2.75rem'}}>보고서 다운로드</div> </MenuItem>
                    </SubMenu>
                    <SubMenu 
                        label="XAI" 
                        className="MenuItem" 
                        rootStyles={{
                            '.SubMenuItemClick' : {
                                'width' : '16rem',
                                'border-left': '4px solid white',
                                'color': 'white',
                                'background-color': '#095E9D',
                                'font-weight': '600',   
                            }
                        }}
                        icon={<SettingsIcon/>}
                        open={link.includes('xai') ? true : false}   
                    >
                        {
                            type === 'tunnel' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/project/xai/heatmap/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/xai/heatmap/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/xai/captioning/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/xai/captioning/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/xai/heatmapresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/xai/heatmapresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵 결과</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/project/xai/captioningresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/project/xai/captioningresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning 결과</div></MenuItem>
                            </> :
                            type === 'dam' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/dam/project/xai/heatmap/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/xai/heatmap/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/xai/captioning/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/xai/captioning/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/xai/heatmapresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/xai/heatmapresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵 결과</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/dam/project/xai/captioningresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/dam/project/xai/captioningresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning 결과</div></MenuItem>
                            </> : 
                            type === 'bridge' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/bridge/project/xai/heatmap/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/xai/heatmap/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/xai/captioning/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/xai/captioning/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/xai/heatmapresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/xai/heatmapresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵 결과</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/bridge/project/xai/captioningresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/bridge/project/xai/captioningresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning 결과</div></MenuItem>
                            </> : 
                            type === 'airport' ?
                            <>
                            <MenuItem 
                                className={ link.includes('/airport/project/xai/heatmap/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/xai/heatmap/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/xai/captioning/') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/xai/captioning/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/xai/heatmapresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/xai/heatmapresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>히트맵 결과</div></MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/xai/captioningresult') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/xai/captioningresult/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>Captioning 결과</div></MenuItem>
                            </>
                            :<></>
                        }
                        
                    </SubMenu>
                    {
                        type === 'airport' ?
                        <SubMenu 
                            label="모니터링" 
                            className="MenuItem" 
                            rootStyles={{
                                '.SubMenuItemClick' : {
                                    'width' : '16rem',
                                    'border-left': '4px solid white',
                                    'color': 'white',
                                    'background-color': '#095E9D',
                                    'font-weight': '600',   
                                }
                            }}
                            icon={<AirplayIcon/>}
                            open={link.includes('uav') ? true : false}    
                        >
                            {/* <MenuItem 
                                className="SubMenuItem" 
                                component={<Link to={`/project/download/setting/${id}`} className="link" />}
                            > 설정 </MenuItem> */}
                            <MenuItem 
                                className={ link.includes('/airport/project/uav/setting') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/uav/setting/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>설정</div> </MenuItem>
                            <MenuItem 
                                className={ link.includes('/airport/project/uav/monitoring') ? "SubMenuItemClick" : "SubMenuItem" }
                                component={<Link to={`/airport/project/uav/monitoring/${id}`} className="link" />}
                            > <div style={{ marginLeft : '2.75rem'}}>실시간 활주로 모니터링</div> </MenuItem>
                        </SubMenu> : <></>
                    }
                    <div 
                        className="Logout" style={{ cursor:"pointer"}} onClick={()=>{util.removeCookie("access_token"); localStorage.removeItem('token'); alert("로그아웃이 완료되었습니다."); util.Move('/')}}
                    > 
                        Logout {<LogoutRoundedIcon />}
                    </div>
                </Menu>
                </div>
            </Sidebar>
        </>
    );
};



  