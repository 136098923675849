import React, { useState, useEffect } from 'react';
import axios from "axios";
import styles from '../../../../styles/XAI.module.css'
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, MenuItem, styled } from '@mui/material';
import { Select } from 'antd';

const { Option } = Select;

interface Image {
    num: Number;
    folderName: string;
    imageURL: Array<string>;
    cnt:Number
}

interface CNT {
    camera_number : number;
    cnt : number;
}

interface Captioning {
    input_folder : string;
    output_folder: string;
    choice_weight: string;
}

export default function DamXAICaptioningPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);

    let Newarr:any[] = [];
    const images : Image [] = [];
    const cnt : CNT [] = [];
    const xai_captioning: Captioning[] = [];

    const setting :any = localStorage.getItem("settings")
    const typeArr: any = JSON.parse(setting).dam_type

    const [DamType, setDamType] = useState<string>("");
    const [dam_type_name, setDamTypeName]=useState<string>("")
   
    for(let i = 0; i < typeArr.length; i++){
        images.push({ 
            num:i+1, 
            folderName:typeArr[i], 
            imageURL: [],
            cnt:0,
            })
            
        cnt.push({
            camera_number :  i,  
            cnt : 0})
    }

    const [weight, setWeight]=useState('model_bridge.pt')

    xai_captioning.push({
        input_folder: "stageXAI/original/captioning",
        output_folder: "stageXAI/Captioning",
        choice_weight : "model/"+weight
    },
    )

    const [imgList, setImgList] = useState<any[]>(images);
    const [finalCNT, setFinalCNT] = useState<any[]>(cnt);
    const [result, setResult] = useState<boolean>(false)
    let [fileArr,setFileArr] = useState<any>([])
    const [arr, setArr] = useState<any>([])
    let [URLarr, setURLarr] = useState<any>([])

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      


    const [model, setModel] = React.useState('');

    let job_id = 0;
    let captioning :any;
    const onClick = () => {
        if (model === "") {
            alert("모델을 선택해주세요.")
        } else {
            xai_captioning[0] = { ...xai_captioning[0], choice_weight: 'model/' + weight  }

            console.log(imgList)
            console.log(imgList.length)
            var cnt_tmp = 0;
            
            if(fileArr.length === 0) {
                alert("이미지를 선택해주세요.")
            } else {
                alert("captioning 생성 작업이 시작되었습니다. 기다려주세요.")
                arr.map((ob: any, i: number) => {
                    if (imgList[i].cnt !== 0) {
                        cnt_tmp = cnt_tmp + 1;
                        console.log(cnt_tmp)
                        console.log(companyId, userid)
                        axios({
                            method: "post",
                            url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                            headers: {
                            "accept": `application/json`,
                            "access-token": `${token}`,
                            "Content-Type": `application/json`
                            },
                            data: {
                                project_id: projectId,
                                task_name: "copy_folder",
                                interactive: false,
                                tasks: [{
                                    input_folder: `stage1/` + ob.folder,
                                    file_names: ob.filename,
                                    output_folder: "stageXAI/original/captioning/"
                                }]
                            }
                            }).then((res) => {
                                console.log(xai_captioning)
                                if (res.data.check === true) {
                                    axios({
                                        method: "post",
                                        url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                                        headers: {
                                        "accept": `application/json`,
                                        "access-token": `${token}`,
                                        "Content-Type": `application/json`
                                        },
                                        data: {
                                            project_id: projectId,
                                            task_name: "xai_captioning",
                                            interactive: false,
                                            tasks: xai_captioning
                                        }
                                    }).then((res2) => {
                                        console.log(res2)
                                        job_id = res2.data.data.job_id
                                        captioning = setInterval(confirm, 30000)
                                    }).catch((err) => {
                                        console.log(err);
                                        
                                    });
                                }
                                else {
                                    alert("에러 : " + res.data.message)
                                }
                                
                            
                            }).catch((err) => {
                                console.log(err)
                            })
                    }
                    else {
                        cnt_tmp = cnt_tmp - 1;
                        
                    } 
                
                })
            }
                
            }
    }

    const confirm = () => {
        // console.log(job_id)
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyId
                }
            }).then((res) => {
                console.log(res)
                if (res.data.check == true) {
                    console.log("성공",res.data.data.status)
                    if (res.data.data.status === "done") {
                        alert("Captioning이 끝났습니다.")
                        clearInterval(captioning) 
                        navigate(`/dam/project/xai/captioningresult/${projectId}`)
                    } else if(res.data.data.status === "error"){ 
                        alert("해당 파일이 없습니다.")
                        clearInterval(captioning) 
                    }
                 } else {
                    console.log("실패")
                 }
            })
        
    }

    const onChange1 = (select: any) => {
        if (projectType === 'tunnel') {
            setModel(select)
            if (select === 'tunnel1') {
                setWeight('Tunnel_model.pt')
            } else if (select === 'tunnel2') {
                setWeight('myresnet.pt')
            }
        } else if (projectType === 'airport') {
            if (select === 'Airport1') {
                setWeight('Fod_model.pt')
                setModel("Fod")

            } else if (select === 'airport2') {
                setWeight('Airport_model.pt')
                setModel("AirLine")
            } 
        } else if (select === 'dam') {
            console.log(select)
            if (select === 'dam1') {
                setWeight('Dam_model.pt')
            }
        }
       
    }
    const  selectDefect :any[]= [];
    const selectRendering = () => {
         if (projectType === 'tunnel') {
             selectDefect.push(<MenuItem value="Crack">FOD</MenuItem>)
             selectDefect.push(<MenuItem value="Leak" disabled>활주로</MenuItem>) 
        } else if (projectType === 'airport') {
            selectDefect.push(<MenuItem value="Crack">Crack</MenuItem>)
            selectDefect.push(<MenuItem value="Leak">Leak</MenuItem>) 
        }
        return selectDefect;
    }

    const onChangeDamType = (e:any) => {
        console.log(e)
        const copy = [...imgList]
        if(e === "Overflow"){
            setDamType("Overflow")
            setDamTypeName("월류부")
            setWeight('Dam_model.pt')
        }else if(e === "DamFloor"){
            setDamType("DamFloor")
            setDamTypeName("댐마루")
            setWeight('Dam_model.pt')
        }else if(e === "DownStream"){
            setDamType("DownStream")
            setDamTypeName("비월류부")
            setWeight('Dam_model.pt')
        }else if(e === "UpStream"){
            setDamType("UpStream")
            setDamTypeName("상류면")
            setWeight('Dam_model.pt')
        }else if(e === "Spillway"){
            setDamType("Spillway")
            setDamTypeName("여수로")
            setWeight('Dam_model.pt')
        }
         setURLarr([])
         for (let i = 0; i < typeArr.length; i++){
             copy[i] = { ...copy[i], cnt: 0 }
         }
         setImgList(copy)
         setModel("Dam")
    }

    const option_render=()=>{
        const arr:any[]=[];
        typeArr.map((type:any)=>{
            let name=''
            if(type==='Overflow'){
                name='월류부'
            }else if(type==='DamFloor'){
                name='댐마루'
            }else if (type === 'UpStream') {
                name='상류면'
            } else if (type === 'DownStream') {
                name ='비월류부'
            } else if (type === 'Spillway') {
                name ='여수로'
            }
            arr.push(<Option value={type}> {name}</Option>)
        })
        return arr;
    }

    const  modelSelect :any[]= [];
    const selectModel = () => {
        if (projectType === 'tunnel') {
            modelSelect.push(
                <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={onChange1}>
                    <Option value='tunnel1' > Tunnel 1</Option>   {/*model_bridge.pt */}    
                    <Option value='tunnel2' > Tunnel 2</Option>    {/*GolfModel.pt */}
                    <Option value='tunnel3'> Tunnel 3</Option>    {/*BridgeModel.pt */}    
                </Select>
                
            )
            
        } else if (projectType === 'airport') {
             modelSelect.push(
                <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={onChange1}>
                    <MenuItem value='Airport1' > FOD</MenuItem>   {/*model_bridge.pt */}    
                    <MenuItem value='Airport2' > 활주로</MenuItem>    {/*GolfModel.pt */}
                </Select>
            ) 
        } else if (projectType === 'dam') {
            modelSelect.push(
                <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={onChangeDamType}>
                    {option_render()}    
                </Select>
            ) 
        }
        return modelSelect;
    }

    // const handleChange = (event: SelectChangeEvent) => {
    //     setModel(event.target.value);
    // };

    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  // localStorage.set("project_id", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);


    
    const rendering = (i:number) => {
        const result: any[] = [];     
        imgList[i].imageURL.map((imageURL: any) => {
            let index1 = ""
            if (projectType === 'dam') {
                if(imageURL.indexOf(".png") !== -1) {
                    index1=imageURL.indexOf(".png")
                }
                else {
                    index1=imageURL.indexOf(".jpg")
                }
            }
            const index_c = imageURL.indexOf(typeArr[i])
            const len = typeArr[i].length
            const ImageUrlCutWidth = imageURL.substring(index_c+len+1, index1+4)
            result.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.DamImageBorder : styles.DamImage} onClick={onClickImage} />)
        })
        
        if(result.length < 1){
            setResult(false);
        }
        
        return result;
    }

    //프로젝트 정보 가져오기
    useEffect(() => {
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((res) => {
            if (res.data.check === true) {
                const settings: any = JSON.parse(res.data.data.settings)
            } else {
                console.log("실패")
            }
        })

        for (let i = 0; i < typeArr.length; i++){
            Newarr[i] = { folder: typeArr[i], filename: [] };
            axios({
                method: 'get',
                url: API_URL+'/File/Files',
                headers: { "accept": `application/json`, "access-token": `${token}` },
                params: {
                    path: `/project/${projectId}/stage1/${typeArr[i]}`
                }
            }).then((res)=>{
                if (res.data.check === true) {
                    console.log("성공")
                    setResult(true);
                    console.log(res)

                    for(let j=0; j<res.data.data.files.length; j++){
                        images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1/${typeArr[i]}/${res.data.data.files[j]}&width=360`)
                    }

                    setResult(true);
                } else {
                    console.log("실패")
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        setArr(Newarr)
    }, [])

    let copyArrImgList = [...imgList];
    let copyArrCNT = [...finalCNT];
    let copyArr = [...URLarr];
    let copyfilename = [...arr]
    let filename = [...fileArr]
    const onClickImage = (e: any) => {
        const id = e.target.id
        if (DamType !== typeArr[id]) { alert("모델과 동일한 사진을 클릭해주세요"); }
        else {
            const len = typeArr[id].length
            filename = [...copyfilename[id].filename]
            const str = e.target.src
            let index1 = ""
            if (projectType === "dam") {
                if(str.indexOf(".png") !== -1) {
                    index1 = str.indexOf(".png")
                }
                else {
                    index1 = str.indexOf(".jpg")
                }
            }
            const index2 = str.indexOf(typeArr[id])
            if (Number(id) === cnt[e.target.id].camera_number) {
                let name = str.substring(index2 + len + 1, index1 + 4)
                console.log(name)
                console.log(copyArr)
                if (copyArr.includes(name) === false) {
                    console.log("여기1")
                    //border
                    copyArr.push(name)
                    localStorage.setItem("captionArr", JSON.stringify(copyArr))
                    //선택 개수 계산
                    copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt + 1 }
                    copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
                    //filename
                    filename.push(name)
                    setFileArr(filename)
                    
                } else {//선택이미지 중복일때
                    console.log("여기2")
                    //  border없앰
                    const i = copyArr.indexOf(name)
                    copyArr.splice(i, 1)
                    localStorage.setItem("captionArr", JSON.stringify(copyArr))
                    // 선택 개수 계산
                    copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt - 1 }
                    copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
                    //filename 
                    const j = filename.indexOf(name)
                    filename.splice(j, 1)
                }
            }
            setFinalCNT(copyArrCNT)
            setImgList(copyArrImgList)
            setURLarr(copyArr)
            
            copyfilename[id] = { ...copyfilename[id], filename: filename }
            setArr(copyfilename)
        }    
    }

    return (
        <Layout>
            <div style={{ display : 'flex', justifyContent: 'space-between', width:'85%'}}>
            <div>
              <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>XAI &gt; Captioning</div>
              <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>Captioning</div>
            </div>
            <div style={{ display: 'flex', alignItems : 'center', marginTop:'3rem'}}>
              <div style={{ fontWeight : 700}}>Model </div>
              <div style={{marginLeft:'1rem'}}>
                  {selectModel()}
              </div>    
            </div>
            </div>
            <div style={{ display : 'flex'}}>
                <div>
                
                <Box sx={{ width: '20rem',  backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem',paddingBottom: '2rem'}}>
                    <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                        <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>구역별 이미지 리스트 </div>
                        <div>
                            <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', background: '#0F6FB7' }} onClick={onClick}>확인</Button>
                        </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '18rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow style={{ height: '2.5rem'}}>
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>이름</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>개수</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {imgList.map((row, i) => (
                                    <TableRow 
                                        key={row.num}
                                        style={{ height: '2.5rem'}}
                                    >
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.num}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.folderName}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.cnt}</StyledTableCell>
                                    </TableRow> 
                                ))}
                                </TableBody>
                                
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '18rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={imgList.length > 10 && imgList.length % 10 === 0 ? Math.floor(imgList.length/10) : Math.floor(imgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                    </div>
                </Box> 
                </div>
                <div style={images.length === 1 ? { marginTop: '1rem', maxHeight : '50rem'} : { marginTop: '1rem', overflowY : 'scroll', maxHeight : '50rem'}}>
                    {
                        result === true ? 
                        images.map((row, i) => (
                            <Box sx={{ width: '73.6875rem',  flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                <div style={{padding : '2rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{ display : 'flex'}}>
                                                <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{i+1}</div>
                                                <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{row.folderName}</div>
                                            </div>

                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={{ width : '69.65rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                    { rendering(i) }
                                        
                                    </div>
                                </div>
                            </Box>
                        )) :
                        <div>
                            이미지 로딩 중 입니다.
                        </div>
                    }
                    
                </div>
            </div>
        </Layout>
    );
};