import React, { useState, useEffect } from 'react';
import axios from "axios";
import styles from '../../../../styles/Preprocess.module.css'
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../../allcomponents';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, styled, Tabs, Tab, Typography } from '@mui/material';

interface Image {
  folderName : string;
  imageURL : Array<string>;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function TunnelPreProcessJtagPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    // 프로젝트 영문이름 + 상행,하행 (폴더, 이미지 만들 때 상관있음)
    const [ProjectNameEn, setProjectNameEn] = useState<string>("")

    let Newarr:any[] = [];
    const [arr, setArr] = useState<any>([])
    let [newArr2, setNewArr2] = useState<any>([])

    let CameraSet123:any[] = [];

    let RenameArr:any[] = [];
    let JDetectorArr:any[] = [];
    let Newarr123:any[] = [];

    const images : Image [] = [];

    const [imgList, setImgList] = useState<any[]>(images);
    const [JDetectorTask, setJDetectorTask] = useState<any[]>([]);
    const [CameraSet, setCameraSet] = useState<any[]>(CameraSet123);

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const navigate = useNavigate();

    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setcompanyId(res.data.data.companyId)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);


    const [isBind, setIsBind] = useState(false);

    const DataBind = () => {
      if (!isBind) {
        // console.log(cameraCount)
      const response = axios({
          method: 'get',
          url: API_URL + `/project/view/${projectId}`,
          headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
      }).then((res) => {
              const settings: any = JSON.parse(res.data.data.settings)
              if (res.data.check === true) {
                  setCameraSet(JSON.parse(settings.filmSetCount))
                  // console.log(JSON.parse(settings.filmSetCount)[0]);

                  for(let i = 0; i < settings.cameraCount; i++){
                      images.push({
                          // folderName:"C0" + Number(i + 1),
                          folderName:"C" + String(i + 1).padStart(2,"0"),
                          imageURL : []
                      })
              
                      RenameArr.push({
                          // input_folder:"stage1_2/C0" + Number(i + 1),
                          input_folder:"stage1_2/C" + String(i + 1).padStart(2,"0"),
                          file_list : []
                      })
                      // setresultRenameList(true);
                  }

                  for(let i = 0; i < JSON.parse(settings.filmSetCount).length; i++){
                      JDetectorArr.push({
                          input_folder: "stage1_2",
                          start_cam: "C" + String(JSON.parse(settings.filmSetCount)[i].FirstCamera).padStart(2,"0"),
                          cam_set_no: JSON.parse(settings.filmSetCount)[i].LastCamera - JSON.parse(settings.filmSetCount)[i].FirstCamera + 1,
                          reverse: false
                      })
                  }
              console.log(JDetectorArr)
              setJDetectorTask(JDetectorArr)
              setImgList(images);

              for(let i = 0; i < settings.cameraCount; i++){
                      axios({
                          method: 'get',
                          url: API_URL+'/file/files',
                          headers: { "accept": `application/json`, "access-token": `${token}` },
                          params: {
                              path: `/project/${projectId}/stage1/${images[i].folderName}`
                          }
                      }).then((res)=>{
                          if (res.data.check === true) {
                              console.log("성공")
                              
                              console.log(res.data.data)
                             
                              for(let j=0; j<res.data.data.files.length; j++){
                                  images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1/${images[i].folderName}/${res.data.data.files[j]}&width=360`)
                              }
                              setResultImageList(true);
                              setIsBind(true);
                          } else {
                              console.log("실패")
                          }
                      }).catch((err) => {
                          console.log(err);
                      });
                  }

                  setArr(RenameArr)
                  console.log(RenameArr);
              }
      }).catch((err) => {
          console.log(err);
      });
      }
    };

    useEffect(() => {
        DataBind();
    }, [isBind])

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

  

    const rendering = (i:number) => {
      const resultImage:any[] = [];        
      imgList[i].imageURL.map((imageURL:any)=>{
          const indexFront = imageURL.indexOf("C") 
          const indexBack= imageURL.indexOf(".png")   
          const ImageUrlCutWidth = imageURL.substring(indexFront+4, indexBack+4)

          // const ImageUrlCutWidth = imageURL.slice(0, -10)
          resultImage.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}} className={newArr2.includes(ImageUrlCutWidth) ? styles.JtagImageBorder : styles.JtagImage} onClick={onClickimage}/>)
      })
          
      if(resultImage.length < 1){
          setResultImageList(false);
      }
      return resultImage;
    }

     /////////////////30초마다 status알려주는 alert//////////////////////////////////////////
     let job_id = 0;
     let result :any;
     const confirm = () => {
         console.log(job_id)
         axios({
             method: "post",
             url: API_URL + '/scheduler/job/query',
             headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                 data: {
                     "job_id": job_id ,
                     "company_id": companyId
                 }
             }).then((res) => {
                 console.log(res)
                 if (res.data.check == true) {
                     console.log("성공")
                     // console.log(res.data.data.status)
                     if (res.data.data.status === "done") {
                         alert("J-tag 작업이 끝났습니다.")
                         // setTask([])
                         clearInterval(result)
                         window.location.href='../Preprocess/JtagConfirm'
                     } else if (res.data.data.status === "progress") {
                         // alert("이미지 추출중입니다.")
                     } else if(res.data.data.status === "error"){
                         alert("해당 파일이 없습니다.")
                     }
                 } else {
                     console.log("실패")
                 }
             })
         
     }
/////////////////////////////////////////////////////////////////////////////////////////////////

const onClickimage = (e:any) => {
  const indexFront = e.target.src.indexOf("C") 
  const indexBack= e.target.src.indexOf(".png")   
  const ImageUrlCutWidth = e.target.src.substring(indexFront+4, indexBack+4) 

  // const ImageUrlCutWidth = e.target.src.slice(0, -10)
  if(arr[Number(e.target.id)].file_list.includes(ImageUrlCutWidth) === false){
      arr[Number(e.target.id)].file_list.push(ImageUrlCutWidth);
  }else{
      for(let i = 0; i < arr[Number(e.target.id)].file_list.length; i++) {
              if(arr[Number(e.target.id)].file_list[i] === ImageUrlCutWidth){
                  arr[Number(e.target.id)].file_list.splice(i, 1);
              }
      }
  }
  console.log(arr);        

  imgList.map((_,i) => {
      arr[i].file_list.map((nowUrl:String)=>{
          Newarr123.push(nowUrl)
      })
  })

  setNewArr2(Newarr123)
}

const onClickConfirm = (e:any) => {
  console.log(arr);
  // console.log(JDetectorArr);
  console.log(JDetectorTask);
  
  // setTimeout(function() {
  //   alert("j-tag를 저장하였습니다.")
  // }, 1000);
  // setTimeout(function() {
  //   navigate(`/project/preprocess/jtagconfirm/${projectId}`);
  // }, 3000);

  axios({
      method: "post",
      url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
      headers: {
        "accept": `application/json`,
        "access-token": `${token}`,
        "Content-Type": `application/json`
      },
      data: {
          projectId: projectId,
          task_name: "joint_rename",
          interactive: false,
          tasks: arr
      }
  }).then((res)=>{
      if (res.data.check === true) {
          console.log("이름 변경 성공")

          axios({
              method: "post",
              url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
              headers: {
                "accept": `application/json`,
                "access-token": `${token}`,
                "Content-Type": `application/json`
              },
              data: {
                  projectId: projectId,
                  task_name: "j_detector",
                  interactive: false,
                  tasks: JDetectorTask
              }
          }).then((res)=>{
              if (res.data.check === true) {
                  // console.log("j_detector 성공")
                  job_id = res.data.data.job_id
                  /////30초마다 alert로 알려줌////////////
                  result = setInterval(confirm, 30000)
                  // alert("j_detector 작업")
                  alert("j-tag를 저장하였습니다.")
                  navigate(`/project/preprocess/jtagconfirm/${projectId}`);
              } else {
                  console.log("실패")
              }
          }).catch((err) => {
              console.log(err);
          });
      } else {
          console.log("이름 변경 실패")
      }
  }).catch((err) => {
      console.log(err);
  });
}

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#0F6FB7",
    fontWeight : 700,
    background: 'rgba(15, 111, 183, 0.10)',
    borderRadius: '0.25rem',
    marginBottom : '0.25rem',
  }
});

    return (
        <Layout>
            <div>
                <div>
                    <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; J태그</div>
                    <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>J태그</div>
                </div>
                <Box sx={{ width: '80%', height : '45.5rem', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="Set 1" {...a11yProps(0)} />
                      <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="Set 2"  {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                <div style={{ marginTop: '1rem', height : '36rem', overflowY : 'scroll'}}>
                {
                      resultImageList === true ? 
                        imgList.map((_, idx) => (
                            <Box sx={{ width: '90rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', marginBottom: '0.5rem'}}>
                                <div style={{padding : '1rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{ display : 'flex'}}>
                                                <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%', fontFamily: 'inter-pretendard'}}>{idx+1}</div>
                                                <div style={{ marginLeft : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard'}}>C0{idx+1}</div>
                                            </div>
                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={{ width : '89rem', height : '15rem', marginTop : '0.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                      { rendering(idx) }
                                        
                                    </div>
                                </div>
                            </Box>
                        ))
                        :   
                        <div>
                            이미지 로딩 중 입니다.
                        </div>
                    }
                </div>
                    
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div style={{fontFamily: 'inter-pretendard'}}>
                      해당 Set가 없습니다.
                  </div>
                </TabPanel>
                {
                   resultImageList === true ?  
                   <div style={{float:'right'}}>
                   <Button variant="contained"onClick={onClickConfirm} style={{fontFamily: 'inter-pretendard'}}>J태그 저장</Button>
                   </div> : <></>
                }
                
                </Box>
            </div>
        </Layout>
    );
};

