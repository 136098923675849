import React, { useState, useEffect } from 'react';
import { Layout } from '../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';
import { styled } from '@mui/material/styles';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, TablePagination, IconButton, FormControl, OutlinedInput } from '@mui/material';
import { ProjectList } from '../../Store/Type/type'
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../Store/Global';
import moment from 'moment';

// ProjectListPage.tsx
export default function ProjectListPage(props:any) {
  // 토큰 받아오기
  let token: string | null = localStorage.getItem("token") 
    useEffect(()=>{
        if (typeof window !== 'undefined' || "null") {
            // console.log('토큰성공');
            token = localStorage.getItem("token");
        } else {
            console.log('You are on the server');
            // 👉️ can't use localStorage1
        }
    })

    const navigate = useNavigate();
    
    // 페이지네이션 변수
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<ProjectList[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // load project type & company id
    const projectType = localStorage.getItem('projectType');
    const userCode = localStorage.getItem('code');
    const [companyId, setCompanyId] = useState<any>();

    // project list 저장
    const ProjectList: ProjectList[] = [];
    const [PList, setPList] = useState<ProjectList[]>([]);
    const [isBind, setIsBind] = useState(false); // 데이터 bind 여부

    // data load func
    const DataBind = () => {
      if (!isBind) {
        axios({
          method: 'get',
          url: API_URL+`/account/member/view/${Number(userCode)}`,
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type": `application/json` },
        }).then((res)=>{
          console.log(res)
            if(res.data.check) {
              console.log(res.data.data.companyId)
              localStorage.setItem('c_code', res.data.data.companyId)
              setCompanyId(res.data.data.companyId)

              axios({
                method: 'get',
                url: API_URL+`/project/list/company/${res.data.data.companyId}`,
                headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type": `application/json` },
              }).then((res)=>{
                console.log(res)
                  if(res.data.check) {
                    const pt = localStorage.getItem("projectType") === 'tunnel' ? "Tunnel" : localStorage.getItem("projectType") === 'dam' ? "Dam" : localStorage.getItem("projectType") === 'bridge' ? "Bridge" : "Airport";
                    console.log(pt)
                      res.data.data.map((row:any, idx:any)=>{
                        if(pt === row.projectType) {
                          ProjectList.push({
                            id: row.id,
                            no: ProjectList.length+1,
                            projectType : row.projectType,
                            // title: <a href='../Facility/Dashboard/ProjectDashboard'>{res.data.data[i].title}</a>,
                            title: <a>{row.title}</a>,
                            settings: row.settings,
                            status: row.status,
                            createdAt : moment(row.createdAt).format('YYYY-MM-DD'),
                            delete : <a>delete</a>,
                            companyId : row.companyId
                          })
                        }
                        console.log(ProjectList.length)
                        setPList(ProjectList);
                        setData(ProjectList.slice((page-1)*10, page*10));
                        setIsBind(true);
                    })

                  }

              })
            }
        })

        //   apiHelper.Get("/project/list", {}, (rst: ReturnValues<ProjectList[]>) => {
        //   console.log(rst);
        //   if (rst.check && rst.data !== null && rst.data !== undefined) {
        //     for (let i = 0; i < rst.data.length; i++) {
        //       // 시연용(Project List 중 type이 tunnel이고 프로젝트 이름 길이가 3 이상인 프로젝트만 Project List에 저장)
        //       const pt = localStorage.getItem("projectType") === 'tunnel' ? "Tunnel" : localStorage.getItem("projectType") === 'dam' ? "Dam" : localStorage.getItem("projectType") === 'bridge' ? "Bridge" : "Airport";
        //       if(rst.data[i].projectType == pt && rst.data[i].title.length > 3) {
        //         ProjectList.push({
        //           id: rst.data[i].id,
        //           no: i + 1,
        //           projectType : rst.data[i].projectType,
        //           // title: <a href='../Facility/Dashboard/ProjectDashboard'>{res.data.data[i].title}</a>,
        //           title: <a>{rst.data[i].title}</a>,
        //           settings: rst.data[i].settings,
        //           status: rst.data[i].status,
        //           createdAt : moment(rst.data[i].createdAt).format('YYYY-MM-DD'),
        //           delete : <a>delete</a>,
        //           companyId : rst.data[i].companyId
        //         })
        //       }
        //     }
        //     console.log(ProjectList.length)
        //     setPList(ProjectList);
        //     setData(ProjectList.slice((page-1)*10, page*10));
        //     setIsBind(true);
        //   }
        // });
      }
    }

    // isBind가 바뀔 때 마다 data bind
    useEffect(() => {
      DataBind();
    }, [isBind]);

    useEffect(() => {
      setData(PList.slice((page-1)*10, page*10));
    }, [page]);

    

    // pagenation : 데이터를 넣은 객체 배열 길이(.length 앞에) ex. PList.length
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PList.length) : 0;

    // page 변경 함수
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }

      }
      
    }

    // mui tablecell custom css
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '0.9rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '2rem',
          border: '1px solid #DDD',
          padding : '0.1rem',
        },
    }));  

    // table row cloick func
    const rowClick = (no:any) => {
      for(let i = 0; i < PList.length; i++) {
        if(PList[i].no === no) {
          console.log(i)
          localStorage.setItem("settings", PList[i].settings)
          localStorage.setItem("companyId", PList[i].companyId)
          localStorage.setItem("projectId", String(PList[i].id))
          navigate(`/project/dashboard/${PList[i].id}`);
        }
      }   
    }

    const onClick = () => {
      if(projectType === 'tunnel') {
        navigate("/project/create")
      } else if(projectType === 'dam') {
        navigate("/dam/project/create")
      } else if(projectType === 'bridge') {
        navigate("/bridge/project/create")
      }
    }

    

    return (
        <Layout>
            <div>
                <div style={{ display:'flex'}}>
                  <div style={{ paddingTop : '2.5rem', paddingLeft: '2rem'}}>
                      <a style={{ fontSize : '1.5rem', fontWeight: 700, color: '#3A4557'}}>프로젝트 목록</a>
                      <Button variant="contained" onClick={onClick} sx={{ background: '#0F6FB7', padding: '0.35rem 2rem', marginLeft: '73.25rem', marginRight: '1rem', fontSize:'1rem', fontWeight:600, fontFamily: 'inter-pretendard'}}>
                        새 프로젝트
                      </Button>
                  </div>
                </div> 
                <Box sx={{ 
                            // height: "35rem", 
                            width: "90rem", 
                            background : '#ffffff', 
                            borderRadius: '0.5rem',
                            marginTop : '1rem',
                            marginLeft : '2rem',
                            paddingTop: '2rem',
                            paddingBottom : '2rem'
                        }}
                >
                  <div style={{ display:'flex', float:'right', marginRight:'1rem', marginBottom:'1rem'}}>
                  <div>
                    <FormControl sx={{ margin : 'auto', width: '20rem', height: '2rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="cameraAmount"
                            aria-describedby="outlined-weight-helper-text"
                            placeholder='검색어를 입력해주세요!!!.'
                            inputProps={{
                              'aria-label': 'cameraAmount',
                            }}
                            sx={{
                              width: '20rem', 
                              height: '2rem', 
                              border: 'none',
                              float: 'right',
                              fontSize:'0.875rem',
                              fontFamily: 'inter-pretendard'
                            }}
                          />
                        </FormControl>
                  </div>
                    <div>
                    <Button variant="outlined" sx={{ marginLeft: '1rem', marginRight: '1rem', fontSize:'0.8rem', fontWeight:600, fontFamily: 'inter-pretendard', padding: '0.3rem 1.5rem'}} onClick={(()=>alert("검색할 프로젝트 개수가 너무 적습니다."))}>
                        검색
                      </Button>
                    </div>
                  </div>
                    {/* 테이블 */}
                    <TableContainer   sx={{ width: '86rem', marginLeft: '2rem', marginTop:'1rem'}}>
                    <Table sx={{  backgroundColor: '#fffff',  
                                  "& .MuiTableRow-root:hover": {
                                    backgroundColor: "#F6FCFF",
                                    color: "#0F6FB7",
                                    fontWeight: 400
                                  } 
                              }} 
                    aria-label="customized table">
                        <TableHead>
                        <TableRow style={{height:'2.5rem'}}>
                            <StyledTableCell align="center" style={{width:'2rem', fontFamily: 'inter-pretendard', fontSize:'1rem'}}>번호</StyledTableCell>
                            <StyledTableCell align="center" sx={{width:'25rem', fontFamily: 'inter-pretendard', fontSize:'1rem' }}>프로젝트 명</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'4rem', fontFamily: 'inter-pretendard', fontSize:'1rem'}}>구조물</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize:'1rem'}}>상태</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard', fontSize:'1rem'}}>날짜</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize:'1rem'}}>삭제</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                          data.map((PList) => (
                            <TableRow 
                                key={PList.id}
                                onClick={() => rowClick(PList.no) }
                                sx={{height:'2.5rem'
                              }}
                                hover={true}
                            >
                              <StyledTableCell align="center" style={{width:'2rem', fontFamily: 'inter-pretendard', fontSize:'0.875rem'}}>{PList.no}</StyledTableCell>
                              <StyledTableCell style={{width:'25rem', textAlign:'left', paddingLeft:'2rem', fontFamily: 'inter-pretendard', fontSize:'0.875rem'}}>{PList.title}</StyledTableCell>
                              <StyledTableCell align="center" style={{width:'4rem', fontFamily: 'inter-pretendard', fontSize:'0.875rem'}}>{PList.projectType}</StyledTableCell>
                              <StyledTableCell align="center" style={{width:'3rem', fontFamily: 'inter-pretendard', fontSize:'0.875rem'}}>{PList.status ? <a>진행중</a>:<a>완료</a>}</StyledTableCell>
                              <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard', fontSize:'0.875rem'}}>{PList.createdAt}</StyledTableCell>
                              <StyledTableCell align="center" style={{width:'3rem'}}>
                                  <Button variant="contained" size='small' style={{fontFamily: 'inter-pretendard', fontSize:'0.875rem', padding : '0.1rem 0.7rem', background: '#0F6FB7', fontWeight: 600}} >삭제</Button>
                              </StyledTableCell>
                            </TableRow> 
                        ))
                        }
                        </TableBody>
                        
                    </Table>
                    <TableFooter>
                      <TableRow style={{ width: '86rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                        <Pagination count={PList.length % 10 === 0 ? Math.floor(PList.length/10) : Math.floor(PList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                          sx={{
                            '& svg': {
                              pointerEvents: 'none'
                            }
                          }}
                        />
                      </TableRow>
                    </TableFooter>
                  </TableContainer>
                </Box>  
            </div>
        </Layout>
    );
};
