import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import {
    Login,
    CompanyJoin,
    Index,
    FooterTerms,
    FooterPrivacy,
    UserList,
    UserConfirm,
    UserDetail,
    UserConfirmDetail,
    UserUpdate,
    ProjectList,
    DashBoard,
    DashBoardResult,
    TunnelCreate,
    TunnelPreProcessSetting,
    TunnelPreProcessVideoEdit,
    TunnelPreProcessImageTask,
    TunnelPreProcessJtag,
    TunnelPreProcessJtagConfirm,
    TunnelPanoramaSetting,
    TunnelPanoramaImageEdit,
    TunnelPanoramaImageConfirm,
    TunnelVerticalPanorama,
    TunnelCrackDetector,
    TunnelCrackMeasure,
    TunnelDefectDetector,
    TunnelDefectMeasure,
    Download,
    TunnelXAIHeatmap,
    TunnelXAICaptioning,
    TunnelXAIHeatmapResult,
    TunnelXAICaptioningResult,
    DamCreate,
    DamPreProcessImageUpload,
    DamPreProcessImageFilter,
    DamPreProcessFolderDiv,
    DamPreProcessSetting,
    DamHorizontalPanorama,
    DamVerticalPanorama,
    DamDefectDetectorMeasure,
    DamXAIHeatmap,
    DamXAICaptioning,
    DamXAIHeatmapResult,
    DamXAICaptioningResult,
    BridgeCreate,
    BridgePreProcessSetting,
    BridgePreProcessImageUpload,
    BridgePreProcessImageFilter,
    BridgePreProcessFolderDiv,
    BridgeHorizontalPanorama,
    BridgeVerticalPanorama,
    BridgeCrackDetector,
    BridgeCrackMeasure,
    BridgeDefectDetector,
    BridgeDefectMeasure,
    BridgeXAIHeatmap,
    BridgeXAICaptioning,
    BridgeXAIHeatmapResult,
    BridgeXAICaptioningResult,
    AirportCreate,
    AirportPreProcessSetting,
    AirportPreProcessImageUpload,
    AirportPreProcessImageFilter,
    AirportPreProcessFolderDiv,
    AirportHorizontalPanorama,
    AirportVerticalPanorama,
    AirportCrackDetectorMeasure,
    AirportDefectDetectorMeasure,
    AirportFodDetectorMeasure,
    AirportMetaLearning,
    AirportMetaLearningResult,
    AirportXAIHeatmap,
    AirportXAICaptioning,
    AirportXAIHeatmapResult,
    AirportXAICaptioningResult,
    AirportUavSetting,
    AirportUavMonitoring,
} from "./allpages";
import "./styles/Global.css";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/join/company" element={<CompanyJoin />} />
                <Route path="/main" element={<Index />} />
                <Route path="/terms" element={<FooterTerms />} />
                <Route path="/privacy" element={<FooterPrivacy />} />
                {/* Users */}
                <Route path="/user/list" element={<UserList />} />
                <Route path="/user/detail/:id" element={<UserDetail />} />
                <Route path="/user/update/:id" element={<UserUpdate />} />
                <Route path="/user/list/confirm" element={<UserConfirm />} />
                <Route
                    path="/user/list/confirm/:id"
                    element={<UserConfirmDetail />}
                />
                {/* Projects */}
                <Route path="/project/list" element={<ProjectList />} />
                <Route path="/project/create" element={<TunnelCreate />} />
                <Route path="/project/dashboard/:id" element={<DashBoard />} />
                <Route
                    path="/project/dashboard/result/:id"
                    element={<DashBoardResult />}
                />
                <Route
                    path="/project/preprocess/setting/:id"
                    element={<TunnelPreProcessSetting />}
                />
                <Route
                    path="/project/preprocess/videoedit/:id"
                    element={<TunnelPreProcessVideoEdit />}
                />
                <Route
                    path="/project/preprocess/imagetask/:id"
                    element={<TunnelPreProcessImageTask />}
                />
                <Route
                    path="/project/preprocess/jtag/:id"
                    element={<TunnelPreProcessJtag />}
                />
                <Route
                    path="/project/preprocess/jtagconfirm/:id"
                    element={<TunnelPreProcessJtagConfirm />}
                />
                <Route
                    path="/project/panorama/setting/:id"
                    element={<TunnelPanoramaSetting />}
                />
                <Route
                    path="/project/panorama/imageedit/:id"
                    element={<TunnelPanoramaImageEdit />}
                />
                <Route
                    path="/project/panorama/imageconfirm/:id"
                    element={<TunnelPanoramaImageConfirm />}
                />
                <Route
                    path="/project/panorama/vertical/:id"
                    element={<TunnelVerticalPanorama />}
                />
                <Route
                    path="/project/crack/detector/:id"
                    element={<TunnelCrackDetector />}
                />
                <Route
                    path="/project/crack/measure/:id"
                    element={<TunnelCrackMeasure />}
                />
                <Route
                    path="/project/defect/detector/:id"
                    element={<TunnelDefectDetector />}
                />
                <Route
                    path="/project/defect/measure/:id"
                    element={<TunnelDefectMeasure />}
                />
                <Route path="/project/download/:id" element={<Download />} />
                <Route
                    path="/project/xai/heatmap/:id"
                    element={<TunnelXAIHeatmap />}
                />
                <Route
                    path="/project/xai/captioning/:id"
                    element={<TunnelXAICaptioning />}
                />
                <Route
                    path="/project/xai/heatmapresult/:id"
                    element={<TunnelXAIHeatmapResult />}
                />
                <Route
                    path="/project/xai/captioningresult/:id"
                    element={<TunnelXAICaptioningResult />}
                />
                {/* dam */}
                <Route path="/dam/project/create" element={<DamCreate />} />
                <Route
                    path="/dam/project/preprocess/setting/:id"
                    element={<DamPreProcessSetting />}
                />
                <Route
                    path="/dam/project/preprocess/imageupload/:id"
                    element={<DamPreProcessImageUpload />}
                />
                <Route
                    path="/dam/project/preprocess/imagefilter/:id"
                    element={<DamPreProcessImageFilter />}
                />
                <Route
                    path="/dam/project/preprocess/folderdiv/:id"
                    element={<DamPreProcessFolderDiv />}
                />
                <Route
                    path="/dam/project/panorama/horizontal/:id"
                    element={<DamHorizontalPanorama />}
                />
                <Route
                    path="/dam/project/panorama/vertical/:id"
                    element={<DamVerticalPanorama />}
                />
                <Route
                    path="/dam/project/defect/:id"
                    element={<DamDefectDetectorMeasure />}
                />
                <Route
                    path="/dam/project/xai/heatmap/:id"
                    element={<DamXAIHeatmap />}
                />
                <Route
                    path="/dam/project/xai/captioning/:id"
                    element={<DamXAICaptioning />}
                />
                <Route
                    path="/dam/project/xai/heatmapresult/:id"
                    element={<DamXAIHeatmapResult />}
                />
                <Route
                    path="/dam/project/xai/captioningresult/:id"
                    element={<DamXAICaptioningResult />}
                />

                {/* bridge */}
                <Route
                    path="/bridge/project/create"
                    element={<BridgeCreate />}
                />
                <Route
                    path="/bridge/project/preprocess/setting/:id"
                    element={<BridgePreProcessSetting />}
                />
                <Route
                    path="/bridge/project/preprocess/imageupload/:id"
                    element={<BridgePreProcessImageUpload />}
                />
                <Route
                    path="/bridge/project/preprocess/imagefilter/:id"
                    element={<BridgePreProcessImageFilter />}
                />
                <Route
                    path="/bridge/project/preprocess/folderdiv/:id"
                    element={<BridgePreProcessFolderDiv />}
                />
                <Route
                    path="/bridge/project/panorama/horizontal/:id"
                    element={<BridgeHorizontalPanorama />}
                />
                <Route
                    path="/bridge/project/panorama/vertical/:id"
                    element={<BridgeVerticalPanorama />}
                />
                <Route
                    path="/bridge/project/crack/detector/:id"
                    element={<BridgeCrackDetector />}
                />
                <Route
                    path="/bridge/project/crack/measure/:id"
                    element={<BridgeCrackMeasure />}
                />
                <Route
                    path="/bridge/project/defect/detector/:id"
                    element={<BridgeDefectDetector />}
                />
                <Route
                    path="/bridge/project/defect/measure/:id"
                    element={<BridgeDefectMeasure />}
                />
                <Route
                    path="/bridge/project/xai/heatmap/:id"
                    element={<BridgeXAIHeatmap />}
                />
                <Route
                    path="/bridge/project/xai/captioning/:id"
                    element={<BridgeXAICaptioning />}
                />
                <Route
                    path="/bridge/project/xai/heatmapresult/:id"
                    element={<BridgeXAIHeatmapResult />}
                />
                <Route
                    path="/bridge/project/xai/captioningresult/:id"
                    element={<BridgeXAICaptioningResult />}
                />

                {/* airport */}
                <Route
                    path="/airport/project/create"
                    element={<AirportCreate />}
                />
                <Route
                    path="/airport/project/preprocess/setting/:id"
                    element={<AirportPreProcessSetting />}
                />
                <Route
                    path="/airport/project/preprocess/imageupload/:id"
                    element={<AirportPreProcessImageUpload />}
                />
                <Route
                    path="/airport/project/preprocess/imagefilter/:id"
                    element={<AirportPreProcessImageFilter />}
                />
                <Route
                    path="/airport/project/preprocess/folderdiv/:id"
                    element={<AirportPreProcessFolderDiv />}
                />
                <Route
                    path="/airport/project/panorama/horizontal/:id"
                    element={<AirportHorizontalPanorama />}
                />
                <Route
                    path="/airport/project/panorama/vertical/:id"
                    element={<AirportVerticalPanorama />}
                />
                <Route
                    path="/airport/project/crack/:id"
                    element={<AirportCrackDetectorMeasure />}
                />
                <Route
                    path="/airport/project/defect/:id"
                    element={<AirportDefectDetectorMeasure />}
                />
                <Route
                    path="/airport/project/fod/:id"
                    element={<AirportFodDetectorMeasure />}
                />
                <Route
                    path="/airport/project/metalearning/:id"
                    element={<AirportMetaLearning />}
                />
                <Route
                    path="/airport/project/metalearningresult/:id"
                    element={<AirportMetaLearningResult />}
                />
                <Route
                    path="/airport/project/xai/heatmap/:id"
                    element={<AirportXAIHeatmap />}
                />
                <Route
                    path="/airport/project/xai/captioning/:id"
                    element={<AirportXAICaptioning />}
                />
                <Route
                    path="/airport/project/xai/heatmapresult/:id"
                    element={<AirportXAIHeatmapResult />}
                />
                <Route
                    path="/airport/project/xai/captioningresult/:id"
                    element={<AirportXAICaptioningResult />}
                />
                <Route
                    path="/airport/project/uav/setting/:id"
                    element={<AirportUavSetting />}
                />
                <Route
                    path="/airport/project/uav/monitoring/:id"
                    element={<AirportUavMonitoring />}
                />
                <Route
                    path="/project/panorama/vertical/:id"
                    element={<TunnelVerticalPanorama />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
