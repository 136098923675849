import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

type Order = 'asc' | 'desc';

interface Image {
    num : Number;
    folderName : string;
    imageURL : Array<string>;
    lean: string;
}

interface Lean {
    input_folder : string;
    lean_mean : number;
}

interface Copy {
    input_folder : string;
    output_folder : string;
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface Data {
    num: string,
    name: string,
  }

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }

export default function AirportHorizontalPanoramaPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token: string | null = localStorage.getItem("token");
    let projectId: string | null = localStorage.getItem("projectId");

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [cNameEn, setEnName] = useState("");
    const [direction, setDirection] = useState("");
    const [cameraNo, setCamraNo] = useState(1);

    const images : Image [] = [];
    const lean : Lean [] = [];
    const folder_copy : Copy [] = [];

    const [num1, setNum1] = useState<number>(0);
    const [num2, setNum2] = useState<number>(0);
    const [span, setSpan] = useState<string>("S001");
    const [spanCount, setSpanCount] = useState<number>(0);
    const [imgList, setImgList] = useState<any[]>(images);
    const [finalLean, setFinalLean] = useState<any[]>(lean);

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
        };

    const rendering = (i:number) => {
        const resultImage:any[] = [];

        imgList[i].imageURL.map((imageURL:any)=>{      
            resultImage.push((<img src={imageURL} id={String(i)} key={imageURL} alt={imageURL} className={'imagetask'} onClick={showModal} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}}/>))
        })
            
        if(resultImage.length < 1){
            setResultImageList(false);
        }

        return resultImage;
    }

    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };
    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
      let getIdCompany = async () => {
        if (token !== null) {
            console.log("프로젝트ID" + projectId);
          const response = await axios({
            method: "get",
            url: `${API_URL}/account/auth/check/${token}`,
          })
            .then(async (res) => {
              if (res.data.check === true) {
                setUserId(res.data.data.id)
                setCompanyId(res.data.data.companyId)
                // localStorage.set("project_id", id);
                console.log(
                  `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                );
                return {
                  email: res.data.data.email,
                  name: res.data.data.name,
                  phone: res.data.data.phone,
                  avatar: res.data.data.avatar,
                  role: res.data.data.role,
                  id: res.data.data.id,
                  companyId: res.data.data.companyId,
                };
              } else {
                console.log("토큰 만료");
                localStorage.removeItem("token");
                alert("토큰이 만료었습니다 다시 로그인 해주세요");
                window.location.replace("/Main");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getIdCompany();
    }, []);

    let Spandist: any[] = [];

    useEffect(() => {
      const response = axios({
        method: "get",
        url: API_URL + `/project/view/${projectId}`,
        headers: { "accept": `application/json`, "access-token": `${token}` },
      })
        .then((res) => {
          console.log(res)
          const settings: any = JSON.parse(res.data.data.settings);
          if (res.data.check === true) {
            for (let i = 0; i < 1; i++) {
              console.log(`공항 이름${settings.tunnel_eng}`);
              setEnName(settings.tunnel_eng);
              setDirection(settings.direction);
              setCamraNo(settings.cameraCount);
              setSpanCount(settings.spanCount);
            }
          }
        })
        .catch((err) => {});
    }, []);

    // const [rrr, setRrr] = useState("");
    // useEffect(() => {
    //   axios({
    //     method: "get",
    //     url: API_URL + "/File/Files",                                                                                                                                                                                                                                                                                                                                                                   
    //     headers: { "accept": `application/json`, "access-token": `${token}` },
    //     params: {
    //       path: `/project/${projectId}/stage3/S001`,
    //     },
    //   })
    //     .then((res) => {
          
    //       if (res.data.check === true) {
    //         setRrr(
    //           `${IMAGE_URL}/image?path=/project/${projectId}/stage3/S001/${res.data.data.files[0]}&width=1240`
    //         );
    //         console.log(res.data.data.files)
    //       setImgList(res.data.data.files)
    //       } else {
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
      
    // }, []);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const StyledTab = styled(Tab)({
        "&.Mui-selected": {
          color: "#0F6FB7",
          fontWeight : 700,
          background: 'rgba(15, 111, 183, 0.10)',
          borderRadius: '0.25rem',
          marginBottom : '0.25rem',
        },
        fontFamily: 'inter-pretendard',
        background: 'white',
        marginRight : '1rem',
        color : '#3A4557',
        fontSize: '0.875rem', 
        fontWeight:500,
      });

      const onClickTab = (i:any) => {
        setNum1(i);
        setNum2(0);
        console.log("tab", i , "panor", num2)
        console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage3/S00${num1+1}/${imgList[num2]}&width=1240`)
    };

      const onClickPanor = (i:any) => {
        setNum2(i);
        console.log("tab", num1 , "panor", i)
        console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage3/S00${num1+1}/${imgList[num2]}&width=1240`)
    };

    const [rrr, setRrr] = useState("");
    useEffect(() => {
      axios({
        method: "get",
        url: API_URL + "/File/Files",                                                                                                                                                                                                                                                                                                                                                                   
        headers: { "accept": `application/json`, "access-token": `${token}` },
        params: {
          path: `/project/${projectId}/stage3/S00${num1+1}`,
        },
      })
        .then((res) => {
          
          if (res.data.check === true) {
            setRrr(
              `${IMAGE_URL}/image?path=/project/${projectId}/stage3/S00${num1+1}/${res.data.data.files[0]}&width=1240`
            );
            console.log(res.data.data.files)
          setImgList(res.data.data.files)
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
      
    }, [num1, num2]);

    const tabsPrint = () => {
      const spanResult:any[] = [];
      for (let i = 0; i < spanCount; i++) {
        spanResult.push((<StyledTab onClick={() => onClickTab(i)}
          label={`Span ${i+1}`} {...a11yProps(i)} />))
      }
      
      return spanResult;
    }

  let tempList: any[] = [];
  let result_list: any[][] = [];

  for (let i = 0; i < spanCount; i++) {
    tempList = imgList.filter((Img: { span: any }) => Img.span === i + 1);
    result_list.push(tempList);
  }
  const [url, setUrl] = useState("0");
  const onSave = () => {
    alert("수직 파노라마를 생성합니다.")
    setTimeout(function() {
      alert("수직 파노라마가 생성되었습니다.")
      navigate(`../airport/project/panorama/vertical/${projectId}`)
    }, 3000);}
    

    const tabPrint = () => {
      const tabResult:any[] = [];
      for (let i = 0; i < spanCount; i++) {
        tabResult.push((
          <TabPanel value={value} index={i}>
                    <div style={{ display:'flex'}}>
                        <div >
                            <Box sx={{ width: '21.75rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem'}}>
                                <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.4rem', fontFamily : 'inter-pretendard'}}>이미지 파일 리스트 </div>
                                </div>
                                <div>
                                    <TableContainer  sx={{ width: '19.75rem', marginLeft: '1rem', marginTop: '1rem' }}>
                                        <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                            <TableHead>
                                            
                                            <TableRow >
                                                <StyledTableCell align="center" style={{width:'3rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                                <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            
                                            <TableBody>
                                            {imgList.map((row, idx) => (
                                                <StyledTableRow 
                                                    key={imgList[idx]}
                                                    style={{ height: '2.5rem', cursor:'pointer'}}
                                                    onClick={() => onClickPanor(idx)}
                                                >
                                                {/* <StyledTableCell component="th" scope="row" align="center">
                                                    {row.num}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{idx+1}</StyledTableCell>
                                                <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{imgList[idx]}</StyledTableCell>
                                                </StyledTableRow> 
                                            ))}
                                            </TableBody>
                                        </Table>
                                        <TableFooter>
                                        <TableRow style={{ width: '19.75rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                        <Pagination count={imgList.length > 10 && imgList.length % 10 === 0 ? Math.floor(imgList.length/10) : Math.floor(imgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                            sx={{
                                              '& svg': {
                                                pointerEvents: 'none'
                                              }
                                            }}
                                          />
                                        </TableRow>
                                      </TableFooter>
                                    </TableContainer>
                                    
                                </div>
                            </Box> 
                            </div>
                            <div style={{ height : '45rem'}}>
                                <Box sx={{ width: '68rem', height: '26rem',flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                    <div style={{padding : '2rem'}}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>이미지 뷰어</div>
                                            <div style={{ fontWeight : 600, fontSize:'1rem', fontFamily:'inter-pretendard', marginRight:'1rem'}}>{imgList[num2]}</div>
                                        </div>
                                            <div>
                                                <div><img src={`${IMAGE_URL}/image?path=/project/${projectId}/stage3/S00${num1+1}/${imgList[num2]}`} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginLeft: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                            </div>
                                            <div style={{ display:'flex', float : 'right' }}>
                                            <div style={{marginTop : '2rem', marginRight: '1rem'}}>
                                                    <Button variant="outlined" sx={{ fontWeight : 600, fontFamily:'inter-pretendard'}} onClick={onSave} >수직 파노라마 실행</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                        </div>
                        

                        </div>
                            
                    </TabPanel>
        
        ))
      }
      
      return tabResult;
    }
    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>파노라마 &gt; 수평 파노라마</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>수평 파노라마</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '1rem', marginLeft : '1.5rem', width: '97rem', height : '3rem' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" style={{ height: '2rem'}}>
                            {tabsPrint()}
                    </Tabs>
                </Box>
                {
                  tabPrint()
                }
                    

                </div>
            </div>
        </Layout>
    );
};

