import React, { useState, useEffect } from 'react';
import axios from "axios";
import styles from '../../../../Styles/CrackDetector_Measure.module.css'
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select, Image} from 'antd';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Tabs, Tab, Typography } from '@mui/material';

const { Option } = Select;

interface ImgContents {
  no : number
  name : any
}

interface OriImage {
  no : any
  original_image : string
}

interface ResultImage {
  no : any
  result_image : string
}

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface Data {
    num: string,
    name: string,
  }

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }
  
  const rows = [
    createData('S001', 'Dongtan_P01_C01_S001_Measure.png'),
    createData('S002', 'Dongtan_P01_C01_S002_Measure.png'),
    createData('S003', 'Dongtan_P01_C01_S003_Measure.png'),
  ];
  
export default function DamDefectMeasurePage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let projectId: string | null = localStorage.getItem("projectId");
    let token : string | null = localStorage.getItem("token") 

    const ImgContents : ImgContents[] = [];  
    const OriImage : OriImage[] = [];  
    const ResultImage : ResultImage[] = [];  

    const navigate = useNavigate();

    const [page, setPage] = React.useState(1);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
      }
    }

    

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [num, setNum] = useState<number>(0);

    const [imgList, setImgList] = useState<any | undefined>(ImgContents)
    const [ImgListOri, setImgListOri] = useState<any[]>(OriImage);
    const [data, setData] = useState<any[]>(ResultImage);
    const [ImgListDefect, setImgListDefect] = useState<any[]>(ResultImage);

    useEffect(() => {
      setData(ImgListDefect.slice((page-1)*10, page*10));
    }, [page]);
    

    const [resultOri, setResultOri] = useState<boolean>(false)
    const [resultDefect, setResultDefect] = useState<boolean>(false)
    
    const [settingState, setSettingState] = useState<boolean>(false)
    const [setting, setSetting]=useState<any>([])
    const [typeArr, setType]=useState([])
    const [DamType, setDamType]=useState<string>("Overflow");
    const [DamTypeCount, setDamTypeCount] = useState<number>(0);
    const [typeName, setTypeName] = useState<string>();

    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);

    useEffect(() => {
      let getIdCompany = async () => {
        if (token !== null) {
            console.log("프로젝트ID" + projectId);
          const response = await axios({
            method: "get",
            url: `${API_URL}/account/auth/check/${token}`,
          })
            .then(async (res) => {
              if (res.data.check === true) {
                setUserId(res.data.data.id)
                setCompanyId(res.data.data.companyId)
                // localStorage.set("projectId", id);
                console.log(
                  `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                );
                return {
                  email: res.data.data.email,
                  name: res.data.data.name,
                  phone: res.data.data.phone,
                  avatar: res.data.data.avatar,
                  role: res.data.data.role,
                  id: res.data.data.id,
                  companyId: res.data.data.companyId,
                };
              } else {
                console.log("토큰 만료");
                localStorage.removeItem("token");
                alert("토큰이 만료었습니다 다시 로그인 해주세요");
                window.location.replace("/Main");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getIdCompany();
    }, []);


  useEffect(() => {
      localStorage.setItem('damType', DamType)
      setNum(0)
      //댐 구조물 종류 받아오기
      axios({
          method: 'get',
          url: API_URL + `/project/view/${projectId}`,
          headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
      }).then((res) => {
          const settings: any = JSON.parse(res.data.data.settings)
              setSetting(settings)
              setSettingState(true)
              setType(settings.dam_type)  
              setDamTypeCount(settings.dam_type.length > 0 ? settings.dam_type.length : 0)         
      }).catch((err) => {
          console.log(err);
      }); 

      if(isSelect) {
        //결함 검출 결과 받아오기
      axios({
        method: 'get',
        url: API_URL+'/File/Files',
        headers: { "accept": `application/json`, "access-token": `${token}` },
        params: {
            path: `/project/${projectId}/stage6/${typeName}`
        }
        }).then((res)=>{
            if (res.data.check === true){
                for(let i = 0; i<res.data.data.files.length; i++){

                    if(
                        res.data.data.files[i].includes(String(".png")) || 
                        res.data.data.files[i].includes(String(".jpg"))){
                        OriImage.push({
                            original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6/${typeName}/${res.data.data.files[i]}&width=1440`,
                            no : i
                        })
                        ImgContents.push({
                          no : i%2 !== 0 ? (i+1)/2 : i,
                          name : <a style={{color:"black"}}>{res.data.data.files[i]}</a>
                      })

                        setResultOri(true);

                    }

                    if(OriImage.length === res.data.data.files.length){
                        setResultOri(true);
                    }
                }
                //결함 검출 결과 받아오기
                axios({
                  method: 'get',
                  url: API_URL+'/File/Files',
                  headers: { "accept": `application/json`, "access-token": `${token}` },
                  params: {
                      path: `/project/${projectId}/stage7/${typeName}`
                  }
                  }).then((res)=>{
                      if (res.data.check === true){
                          // console.log(res.data.data)
                          for(let i = 0; i<res.data.data.files.length; i++){
                              if(res.data.data.files[i].includes(String(".png")) || res.data.data.files[i].includes(String(".jpg"))){
                                  ResultImage.push({
                                      result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage7/${typeName}/${res.data.data.files[i]}&width=1440`,
                                      no : i
                                  })

                                  
                              }                        
                          }

                          if(ResultImage.length > 0){
                              setResultDefect(true);
                              // console.log(resultDefect)
                          }else{
                              alert("결과가 나올 때까지 기다려주세요.")
                          }

                      }else{
                          console.log("실패")
                          // alert('댐 결함 측정을 실행해주세요.')
                          // navigate(`/dam/project/preprocess/setting/${projectId}`)
                      }
                      
                      setImgListDefect(ResultImage); 
                      setData(ResultImage.slice((page-1)*10, page*10));
                      setImgList(ImgContents) 

                  }).catch((err) => {
                      console.log(err);
                  });

                  // console.log(ImgListOri)
                  // console.log(resultDefect)
                  // console.log(resultOri)
            }else{
                console.log("실패")
                alert('댐 결함 검출을 실행해주세요.')
                navigate(`/dam/project/preprocess/setting/${projectId}`)
            }
            
            setImgListOri(OriImage);

        }).catch((err) => {
            console.log(err);
        });
      }
  }, [typeName])

  const openWindow = () => {
      window.open('../../ViewerDefect', "결함 결과 수정", "width=1650px, height=600px, left=300, top=150");
  }

  

  const rendering = () => {
      const result = [];    
      
      resultDefect === true ? localStorage.setItem('edit_defect_image', ImgListDefect[num].result_image) : localStorage.setItem('edit_defect_image', "")
      
      for (let i = 0; i < DamTypeCount; i++) {
          result.push(
            <TabPanel value={value} index={i}>
            <div style={{ marginTop : '0.5rem'}}>
                <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                    <div style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>{resultOri === true ?  ImgListOri[num].original_image.substring(ImgListOri[num].original_image.indexOf("/stage") + 17, ImgListOri[num].original_image.indexOf("&width")) : ""}</div>
                    <div>
                        <Button variant="outlined" sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={downloadCouple}>커플 이미지 다운로드</Button>
                        {/* <Button variant="contained" sx={{ fontWeight : 600 }} onClick={openWindow}>균열 결과 수정</Button> */}
                    </div>
                </div>
                    <div>
                        <div><img src={resultOri === true ? ImgListOri[num].original_image : ""} className={'imagetask'} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem',  cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                        <div><img src={resultDefect === true ? ImgListDefect[num].result_image : ""} className={'imagetask'} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem',  cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                    </div>
            </div>
        </TabPanel>
          );
      }
      return result;
  };

  const renderingTab = () => {
    const result = [];    
    
    resultDefect === true ? localStorage.setItem('edit_defect_image', ImgListDefect[num].result_image) : localStorage.setItem('edit_defect_image', "")
    
    for (let i = 0; i < DamTypeCount; i++) {
        result.push(
          <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label={setting.dam_type[i]} {...a11yProps(i)} />
        );
    }
    return result;
};

  const TabClick = (e:any) => {
      if(e === "1"){
          setDamType("Overflow")
      }else if(e === "2"){
          setDamType("DamFloor")
      }else if(e === "3"){
          setDamType("UpStream")
      }else if(e === "4"){
          setDamType("DownStream")
      }else if(e === "5"){
        setDamType("Spillway")
    }
  }

  const nextClick = () => {
      if (num < ImgListOri.length - 1) {
          setNum(num + 1);
      }
  };

      //커플 이미지 다운로드
      const downloadCouple = () => {
          // console.log(ImgListOri[num].original_image)
          // console.log(ImgListDefect[num].result_image)
          alert("잠시 기다리시면 다운로드 됩니다.")
  
          const indexFrontOri = ImgListOri[num].original_image.indexOf("/stage")
          const indexBackOri= ImgListOri[num].original_image.indexOf("&width")   
          const ImageUrlCutWidthOri = ImgListOri[num].original_image.substring(indexFrontOri+1, indexBackOri)
  
          console.log(ImageUrlCutWidthOri)
  
          const indexFrontName = ImgListOri[num].original_image.indexOf("/stage")
          const indexBackName= ImgListOri[num].original_image.indexOf("&width")   
          const ImageUrlCutWidthName = ImgListOri[num].original_image.substring(indexFrontName+17, indexBackName)
  
          console.log(ImageUrlCutWidthName)
  
          const indexFrontCrack = ImgListDefect[num].result_image.indexOf("/stage")
          const indexBackCrack= ImgListDefect[num].result_image.indexOf("&width")   
          const ImageUrlCutWidthCrack = ImgListDefect[num].result_image.substring(indexFrontCrack+1, indexBackCrack)
  
          console.log(ImageUrlCutWidthCrack)
  
          axios({
              method: "post",
              url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
              headers: {
                "accept": `application/json`,
                "access-token": `${token}`,
                "Content-Type": `application/json`
              },
                        data: {
                  project_id: projectId,
                  task_name: "image_join",
                  interactive: false,
                  tasks: [{
                      image1_path: ImageUrlCutWidthOri,
                      image2_path: ImageUrlCutWidthCrack,
                      align: 0, //수평 //수직으로 하려면 0
                      output_folder: 'stage6_defect_Couple',
                      output_name: ImageUrlCutWidthName
                  }]
                  }
          }).then((res) => {
              console.log(res.data.check)
              if (res.data.check === true) {
                  job_id = res.data.data.job_id
                          /////30초마다 alert로 알려줌////////////
                          resultCouple = setInterval(confirm, 10000)
              } else {
                  console.log("실패")
              }
          })
      }

  let job_id = 0;
  let resultCouple :any;
  
  async function confirm(){
      
      axios({
          method: "post",
          url: API_URL + '/scheduler/job/query',
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
              data: {
                  "job_id": job_id ,
                  "company_id": companyid
              }
                }).then(async (res) => {
              console.log(res)
              if (res.data.check === true) {
                  if (res.data.data.status === "done") {
                      console.log("성공")
                      
                      setTimeout(()=>
                          download()
                      ,10000)

                  clearInterval(resultCouple)
                  } else if (res.data.data.status === "progress") {
                      // alert("이미지 추출중입니다.")
                  } else if(res.data.data.status === "error"){
                      alert("해당 파일이 없습니다.")
                  }
              } else {
                  console.log("실패")
              }
          })   
  }

  async function download(){

      console.log("download")
      

      const indexFrontName = ImgListOri[num].original_image.indexOf("/stage")
      const indexBackName= ImgListOri[num].original_image.indexOf("&width")   
      const ImageUrlCutWidthName = ImgListOri[num].original_image.substring(indexFrontName+17, indexBackName)

      const link = document.createElement('a');
      let src = `${IMAGE_URL}/image?path=/project/${projectId}/stage6_defect_Couple/${ImageUrlCutWidthName}`;
      const imageBlob = await (await fetch(src)).blob();
      src = URL.createObjectURL(imageBlob);
      link.href = src;
      link.download = ImageUrlCutWidthName
      link.click();
  }

    // const showModal = (e:any) => {
    //     setIsModalVisible(true);
    //     setChoiceIndex(e.target.id);
    //     setChoiceImg(e.target.src);
    // };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const StyledTab = styled(Tab)({
        "&.Mui-selected": {
          color: "#0F6FB7",
          fontWeight : 700,
          background: 'rgba(15, 111, 183, 0.10)',
          borderRadius: '0.25rem',
          marginBottom : '0.25rem',
        }
      });

      const onClickPanor = (i:any) => {
        setNum(page > 1 ? (page-1)*10+i : i);
    };

    const [isSelect, setIsSelect] = useState<boolean>(false)

    const option_render=()=>{
      const arr:any[]=[];
      
      typeArr.map((type:any)=>{
          let name=''
          if(type==='Overflow'){
              name='월류부'
          }else if(type==='DamFloor'){
              name='댐마루'
          }else if (type === 'UpStream') {
              name='상류면'
          } else if (type === 'DownStream') {
              name ='비월류부'
          } else if (type === 'Spillway') {
              name ='여수로'
          }
          arr.push(<Option value={type} > {name}</Option>)
      })
      return arr;
  }

    const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요"  style={{ width : '8rem'}} onChange={(e) => {setIsSelect(true); setTypeName(e)}}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }
    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; 결함 검출 & 측정</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>결함 검출 & 측정</div>
            <div>
                
                <div  style={{ marginTop: '1rem'}}>
                    <Box sx={{
                        display: 'block',
                        width: '8rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem',
                        marginLeft : '2rem'
                    }}>
                        {selectModel()}
                    </Box>
                </div>
                {
                  isSelect && resultOri ? 
                  <div style={{ display : 'flex'}}>
                    <div>
                      <Box sx={{ width: '22rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                          <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                          </div>
                          <div>
                              <TableContainer  sx={{ width: '20rem', marginLeft: '1rem', marginTop: '1rem' }}>
                                  <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                      <TableHead>
                                      <TableRow>
                                          <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                          <StyledTableCell align="center" style={{width:'15rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                      </TableRow>
                                      </TableHead>
                                      
                                      <TableBody>
                                      {(resultOri === true ? data:[]).map((row:any, i:any) => (
                                          <StyledTableRow 
                                              key={row.no}
                                              style={{ height: '2.5rem', cursor:'pointer'}}
                                              onClick={() => onClickPanor(i)}
                                          >
                                          {/* <StyledTableCell component="th" scope="row" align="center">
                                              {row.num}
                                          </StyledTableCell> */}
                                          <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{page > 1 ? (page-1)*10+i+1 : i+1}</StyledTableCell>
                                          <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{data[i]?.result_image?.split(`stage7/${typeName}/`)[1]?.split('&width')[0]}</StyledTableCell>
                                          </StyledTableRow> 
                                      ))}
                                      </TableBody>
                                  </Table>
                                  <TableFooter>
                                      <TableRow style={{ width: '20rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                      <Pagination count={ImgListDefect.length > 10 && ImgListDefect.length % 10 === 0 ? Math.floor(ImgListDefect.length/10) : Math.floor(ImgListDefect.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                          sx={{
                                            '& svg': {
                                              pointerEvents: 'none'
                                            }
                                          }}
                                        />
                                      </TableRow>
                                      </TableFooter>
                              </TableContainer>
                              
                          </div>
                      </Box> 
                    </div>
                    <div style={{ marginTop: '1rem', height : '50rem'}}>
                      <Box sx={typeName === 'Spillway' ? { width: '69rem', height: '30rem',  borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'} : { width: '69rem', height: '47rem',  borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                        <div style={{padding : '3rem'}}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <div style={{ marginTop : '0.5rem'}}>
                                <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                  <div style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>{resultOri === true ?  ImgListOri[num]?.original_image.substring(ImgListOri[num]?.original_image.indexOf("/stage") + 17, ImgListOri[num].original_image.indexOf("&width")) : ""}</div>
                                    <div>
                                        <Button variant="outlined" sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={downloadCouple}>커플 이미지 다운로드</Button>
                                        {/* <Button variant="contained" sx={{ fontWeight : 600 }} onClick={openWindow}>균열 결과 수정</Button> */}
                                    </div>
                                  </div>
                                  <div style={typeName === 'Spillway' ? {display:'flex'} : {}}>
                                    <div style={typeName === 'Spillway' ? {width:'100%', marginTop : '1.25rem', marginRight: '3rem', borderRadius: '0.625rem'}:{width:'100%',marginTop : '1.25rem', marginRight: '2rem', borderRadius: '0.625rem'}}>
                                      <Image width={ typeName === 'Spillway' ? 480 : 960 } height={ typeName === 'Spillway' ? 320 : 240 } src={resultOri === true ? ImgListOri[num]?.original_image : ""} style={{borderRadius: '0.625rem'}}/>
                                    </div>
                                    <div style={typeName === 'Spillway' ? {width:'100%',marginTop : '1.25rem', marginRight: '3rem', borderRadius: '0.625rem'}:{width:'100%',marginTop : '1.25rem', marginRight: '2rem', borderRadius: '0.625rem' }}>
                                      
                                      {
                                        resultDefect ? <Image width={ typeName === 'Spillway' ? 480 : 960 } height={ typeName === 'Spillway' ? 320 : 240 } src={resultDefect === true ? ImgListDefect[num]?.result_image : ""} style={{borderRadius: '0.625rem'}}/>
                                        : <div>결함 측정을 진행해주세요</div>
                                      }
                                    </div>
                                  </div>
                              </div>
                            </Box>
                            </div>
                        </Box>
                    </div>
                    </div>
                        : <Box sx={{
                          width: '11.5rem',
                          height: '1.5rem',
                          borderRadius: '0.5rem',
                          background: '#FFF',
                          boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                          padding : '0.75rem',
                          marginLeft : '2rem',
                          marginTop : '1rem'
                      }}>
                          <div>구역을 먼저 선택해주세요.</div>
                      </Box>
                  }
            </div>
        </Layout>
    );
};


