import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled } from '@mui/material';
import {Image} from 'antd'


interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }

  
  interface ImgContents {
    no : number
    name : any
    sort : any
}

interface ResultImage {
    no : any
    result_image : string
}

interface Data {
  num: string,
  name: string,
}

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }
export default function AirportFodDetectorMeasurePage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const ImgContents : ImgContents[] = [];   
    const ResultImage : ResultImage[] = [];  

    const [num, setNum] = useState<number>(0);
    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)
    const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);
    const [resultCrack, setResultCrack] = useState<boolean>(false)

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
        };

        useEffect(() => {

          axios({
              method: 'get',
              url: API_URL+'/File/Files',
              headers: { "accept": `application/json`, "access-token": `${token}` },
              params: {
                  path: `/project/${projectId}/stage6_fod`
              }
          }).then((res)=>{
            
              if (res.data.check === true) {
                  // console.log("성공")
                  
                  for(let i = 0; i<res.data.data.files.length; i++){
                      const SpanNum = (res.data.data.files[i].slice(0, -10)).substring(16)
                      ResultImage.push({
                          result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_fod/${res.data.data.files[i]}&width=1440`,
                          no : SpanNum
                      })
                      // console.log(res.data.data.files[i])
                      ImgContents.push({
                          no : i,
                          name : res.data.data.files[i],
                          sort : i+1
                      })
                  }
  
                  if(ResultImage.length > 0){
                      setResultCrack(true);
                      console.log(ResultImage)
                  }else{
                      alert("결과가 나올 때까지 기다려주세요.")
                  }
  
              } else {
                  // alert('수직파노라마를 실행해주세요.')
              }
  
                  ImgContents.sort((obj1, obj2) => {
                      if (obj1.sort > obj2.sort) {
                          return 1;
                      }
                      if (obj1.sort < obj2.sort) {
                          return -1;
                      }
                      return 0;
                      })
  
              let copyArrImgList = [...ImgList];
  
              for(let i = 0; i < ImgList.length; i++){
                  copyArrImgList[i] = { ...copyArrImgList[i], no : i};
              }
              setImgList(copyArrImgList)
              // console.log(ImgList, "ImgListttttt")
  
              ResultImage.sort((obj1, obj2) => {
                  if (obj1.no > obj2.no) {
                      return 1;
                  }
                  if (obj1.no < obj2.no) {
                      return -1;
                  }
                  return 0;
                  })
  
              setImgListCrack(ResultImage);
  
          }).catch((err) => {
              console.log(err);
          });
      }, [])
  
      const rendering = (i:any) => {
          const result = [];
          result.push(
            <div>
                <div style={{ marginTop : '1.5rem'}}>
                    <Image style={{ width:'30rem', height:'40rem' ,borderRadius: '0.625rem'}} src={resultCrack === true ? ImgListCrack[i].result_image : ""} alt="" />
                </div>
            </div>

        );
          return result;
      };

      const onClickPanor = (i:any) => {
        setNum(i);
    };


    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; FOD 검출 & 측정</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>FOD 검출 & 측정</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div>
                <div style={{ display:'flex', marginTop: '1rem'}}>
                    <div >
                        <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem'}}>
                            <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                            </div>
                            <div>
                                <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
                                    <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                        <TableHead>
                                        
                                        <TableRow >
                                            <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>
                                        {ImgListCrack.map((row, idx) => (
                                            <StyledTableRow 
                                                key={row.result_image}
                                                style={{ height: '2.5rem', cursor:'pointer'}}
                                                onClick={() => onClickPanor(idx)}
                                            >
                                            {/* <StyledTableCell component="th" scope="row" align="center">
                                                {row.num}
                                            </StyledTableCell> */}
                                            <StyledTableCell align="center" style={{width:'2em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{idx+1}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{ImgListCrack[idx]?.result_image.split('stage6_fod/')[1]?.split('&width')[0]}</StyledTableCell>
                                            </StyledTableRow> 
                                        ))}
                                        </TableBody>
                                    </Table>
                                    <TableFooter>
                                <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={ImgListCrack.length > 10 && ImgListCrack.length % 10 === 0 ? Math.floor(ImgListCrack.length/10) : Math.floor(ImgListCrack.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                                </TableContainer>
                                
                            </div>
                        </Box> 
                        </div>
                        <div style={{ height : '45rem'}}>
                            <Box sx={{ width: '36.25rem', height: '48.5rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                <div style={{padding : '3rem'}}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                        <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>이미지 뷰어 </div>
                                        <div style={{ fontWeight : 600, fontSize:'1rem', fontFamily:'inter-pretendard', marginRight:'0.25rem'}}>{ImgListCrack[num]?.result_image.split('stage6_fod/')[1].split('&width')[0]}</div>
                                    </div>
                                    <div>
                                        {rendering(num)}
                                    </div>
                                </div>
                                </div>
                            </Box>
                    </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};


// import React, { useState, useEffect } from 'react';
// import axios from "axios";
// import styles from '../../../../styles/XAI.module.css'
// import { Layout } from '../../../../allcomponents';
// import { useNavigate } from 'react-router-dom';
// import { API_URL, IMAGE_URL } from '../../../../Store/Global';
// import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, MenuItem, styled } from '@mui/material';
// import { Select } from 'antd';

// const { Option } = Select;

// interface Image {
//     num: Number;
//     folderName: string;
//     imageURL: Array<string>;
//     cnt:Number
// }

// interface CNT {
//     camera_number : number;
//     cnt : number;
// }

// interface Heatmap {
//     input_folder : string;
//     output_folder: string;
//     choice_weight: string;
// }

// export default function AirportXAIHeatmapPage(props:any) {
//     const projectType = localStorage.getItem('projectType');
//     const curId = window.location.href.split('/')[6];
//     let token : string | null = localStorage.getItem("token") 
//     let projectId : string | null = localStorage.getItem("projectId")

//     const navigate = useNavigate();
//     const [page, setPage] = React.useState(0);

//     let Newarr:any[] = [];
//     const images : Image [] = [];
//     const cnt : CNT [] = [];
//     const xai_heatmap: Heatmap[] = [];

//     const setting :any = localStorage.getItem("settings")
//     const count = JSON.parse(setting).cameraCount
   
//     for(let i = 0; i < count; i++){
//         images.push({ 
//             num:i, 
//             folderName:"C" + String(i+1).padStart(2,"0"), 
//             imageURL: [],
//             cnt:0,
//             })
            
//         cnt.push({
//             camera_number :  i-1, 
//             cnt : 0})
//     }

//     const [weight, setWeight]=useState('model_bridge.pt')

//     xai_heatmap.push({
//         input_folder: "T:/project/282/stageXAI/original/heatmap",
//         output_folder: "T:/project/282/stageXAI/Heatmap",
//         choice_weight : "T:/project/282/model/"+weight
//     },
//     )

//     const [imgList, setImgList] = useState<any[]>(images);
//     const [finalCNT, setFinalCNT] = useState<any[]>(cnt);
//     const [result, setResult] = useState<boolean>(false)
//     let [fileArr,setFileArr] = useState<any>([])
//     const [arr, setArr] = useState<any>([])
//     let [URLarr, setURLarr] = useState<any>([])

//     const [userid, setUserId] = useState<number>(-1);
//     const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

//     const handlePage = (event:any) => {
//       const nowPageInt = parseInt(event.target.outerText);
//       console.log(event.target)
      
//       if(event.target.outerText) {
//         setPage(nowPageInt);
//       }
//       else {
//         if(event.target.ariaLabel.includes("prev")) {
//           setPage(page-1);
//         }
//         if(event.target.ariaLabel.includes("next")) {
//           setPage(page+1);
//         }
  
//       }
      
//     }
//     const StyledTableCell = styled(TableCell)(({ theme }) => ({
//         [`&.${tableCellClasses.head}`]: {
//           fontSize: '1rem',
//           fontWeight: 600,
//           backgroundColor: '#F5F5F5',
//           color: '#3A4557',
//           width: '42.8125rem',
//           height: '2.5rem',
//           border: '1px solid #DDD',
//           borderTop: '0.15rem solid #3A4557',
//           padding : 0
//         },
//         [`&.${tableCellClasses.body}`]: {
//           color: '#3A4557',
//           textAlign: 'center',
//           fontSize: '0.8125rem',
//           width: '42.8125rem',
//           height: '3rem',
//           border: '1px solid #DDD',
//           padding : 0,
//         },
//       }));
      


//     const [model, setModel] = React.useState('');

//     let job_id = 0;
//     let heatmap :any;
//     const onClick = () => {
//         if (model === "") {
//             alert("모델을 선택해주세요.")
//         } else {
//             // console.log(weight)
//             xai_heatmap[0] = { ...xai_heatmap[0], choice_weight: 'model/' + weight  }
//             // console.log(ImgList)
            
//             // console.log(filename)
//             // console.log(folder_copy)
            
//             // setTimeout(function() {
//             //     alert("heatmap이 생성되었습니다.")
//             //   }, 1000);
//             console.log(imgList)
//             console.log(imgList.length)
//             var cnt_tmp = 0;
            
//             // alert("FOD 생성 작업이 시작되었습니다. 기다려주세요.")
//             // setTimeout(function() {
//             // alert("FOD가 생성되었습니다.")
//             // //  navigate(`../airport/project/xai/heatmapresult/${projectId}`)
//             // }, 5000);
//                 arr.map((ob: any, i: number) => {
//                     // console.log(ImgList[i].cnt)
//                     if (imgList[i].cnt !== 0) {
//                         cnt_tmp = cnt_tmp + 1;
//                         console.log(cnt_tmp)
//                         console.log(companyId, userid)
//                         axios({
//                             method: "post",
//                             url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
//                             headers: {
//                             "accept": `application/json`,
//                             "access-token": `${token}`,
//                             "Content-Type": `application/json`
//                             },
//                             data: {
//                                 project_id: projectId,
//                                 task_name: "copy_folder",
//                                 interactive: false,
//                                 tasks: [{
//                                     input_folder: `stage1/` + ob.cam,
//                                     file_names: ob.filename,
//                                     output_folder: "stageXAI/original/heatmap/"
//                                 }]
//                             }
//                             }).then((res) => {
//                                 console.log(xai_heatmap)
//                                 if (res.data.check === true) {
//                                     axios({
//                                         method: "post",
//                                         url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
//                                         headers: {
//                                         "accept": `application/json`,
//                                         "access-token": `${token}`,
//                                         "Content-Type": `application/json`
//                                         },
//                                         data: {
//                                             project_id: projectId,
//                                             task_name: "xai_heatmap",
//                                             interactive: false,
//                                             tasks: xai_heatmap
//                                         }
//                                     }).then((res2) => {
//                                         console.log(res2)
//                                         job_id = res2.data.data.job_id
//                                         heatmap = setInterval(confirm, 30000)
//                                     }).catch((err) => {
//                                         console.log(err);
                                        
//                                     });
//                                 }
//                                 else {
//                                     alert("에러 : " + res.data.message)
//                                 }
                                
                            
//                             }).catch((err) => {
//                                 console.log(err)
//                             })
//                     }
//                     else {
//                         cnt_tmp = cnt_tmp - 1;
                        
//                     }                
//                 })
//             if(imgList.length === Math.abs(cnt_tmp))
//                     alert("이미지를 선택해주세요.")
//         }
 
//     }

//     const confirm = () => {
//         // console.log(job_id)
//         axios({
//             method: "post",
//             url: API_URL + '/scheduler/job/query',
//             headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
//                 data: {
//                     "job_id": job_id ,
//                     "company_id": companyId
//                 }
//             }).then((res) => {
//                 console.log(res)
//                 if (res.data.check == true) {
//                     console.log("성공",res.data.data.status)
//                     if (res.data.data.status === "done") {
//                         alert("Heatmap이 끝났습니다.")
//                         clearInterval(heatmap)
//                         window.location.href='../XAI/Result_heatmap'
//                     } else if(res.data.data.status === "error"){
//                         alert("해당 파일이 없습니다.")
//                     }
//                  } else {
//                     console.log("실패")
//                  }
//             })
        
//     }

//     const onChange1 = (select: any) => {
//         if (projectType === 'tunnel') {
//             setModel(select)
//             if (select === 'tunnel1') {
//                 setWeight('Tunnel_model.pt')
//             } else if (select === 'tunnel2') {
//                 setWeight('myresnet.pt')
//             }
//         } else if (projectType === 'airport') {
//             if (select === 'Airport1') {
//                 setWeight('Fod_model.pt')
//                 setModel("Fod")

//             } else if (select === 'airport2') {
//                 setWeight('Airport_model.pt')
//                 setModel("AirLine")
//             } 
//         }
       
//     }
//     const  selectDefect :any[]= [];
//     const selectRendering = () => {
//          if (projectType === 'tunnel') {
//              selectDefect.push(<MenuItem value="Crack">FOD</MenuItem>)
//              selectDefect.push(<MenuItem value="Leak" disabled>활주로</MenuItem>) 
//         } else if (projectType === 'airport') {
//             selectDefect.push(<MenuItem value="Crack">Crack</MenuItem>)
//             selectDefect.push(<MenuItem value="Leak">Leak</MenuItem>) 
//         }
//         return selectDefect;
//     }



//     const  modelSelect :any[]= [];
//     const selectModel = () => {
//         if (projectType === 'tunnel') {
//             modelSelect.push(
//                 <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={onChange1}>
//                     <Option value='tunnel1' > Tunnel 1</Option>   {/*model_bridge.pt */}    
//                     <Option value='tunnel2' > Tunnel 2</Option>    {/*GolfModel.pt */}
//                     <Option value='tunnel3'> Tunnel 3</Option>    {/*BridgeModel.pt */}    
//                 </Select>
                
//             )
            
//         } else if (projectType === 'airport') {
//              modelSelect.push(
//                 <Select placeholder="선택해주세요" className={styles.selectDiv} onChange={onChange1}>
//                     <MenuItem value='Airport1' > FOD</MenuItem>   {/*model_bridge.pt */}    
//                     <MenuItem value='Airport2' > 활주로</MenuItem>    {/*GolfModel.pt */}
//                 </Select>
//             ) 
//         }
//         return modelSelect;
//     }

//     // const handleChange = (event: SelectChangeEvent) => {
//     //     setModel(event.target.value);
//     // };

//     useEffect(() => {
//         let getIdCompany = async () => {
//           if (token !== null) {
//             console.log("프로젝트ID" + projectId);
//             const response = await axios({
//               method: "get",
//               url: `${API_URL}/account/auth/check/${token}`,
//             })
//               .then(async (res) => {
//                 if (res.data.check === true) {
//                   setUserId(res.data.data.id)
//                   // localStorage.set("project_id", id);
//                   console.log(
//                     `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
//                   );
//                   return {
//                     email: res.data.data.email,
//                     name: res.data.data.name,
//                     phone: res.data.data.phone,
//                     avatar: res.data.data.avatar,
//                     role: res.data.data.role,
//                     id: res.data.data.id,
//                     companyId: companyId,
//                   };
//                 } else {
//                   console.log("토큰 만료");
//                   localStorage.removeItem("token");
//                   alert("토큰이 만료었습니다 다시 로그인 해주세요");
//                   window.location.replace("/Main");
//                 }
//               })
//               .catch((err) => {
//                 console.log(err);
//               });
//           }
//         };
//         getIdCompany();
//       }, []);


    
//     const rendering = (i:number) => {
//         const result: any[] = [];     

//         imgList[i].imageURL.map((imageURL: any) => {
//             let index1 = ""
//             if (projectType === "tunnel") {
//                 index1 = imageURL.indexOf(".png")
//             } else if(projectType==="airport") {
//                 index1=imageURL.indexOf(".JPG")
//             } else if (projectType === 'dam') {
//                 index1=imageURL.indexOf(".jpg")
//             }
//             const index_c=imageURL.indexOf('C')
//             const ImageUrlCutWidth = imageURL.substring(index_c+4, index1+4)
//             result.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}} className={URLarr.includes(ImageUrlCutWidth) ? styles.JtagImageBorder : styles.JtagImage} onClick={onClickImage} />)
//         })
        
//         if(result.length < 1){
//             setResult(false);
//         }
        
//         return result;
//     }

//     //프로젝트 정보 가져오기
//     let img_path: any;
//     useEffect(() => {
//         axios({
//             method: 'get',
//             url: API_URL + `/project/view/${projectId}`,
//             headers: { "accept": `application/json`, "access-token": `${token}` },
//         }).then((res) => {
//             if (res.data.check === true) {
//                 const settings: any = JSON.parse(res.data.data.settings)
//                 // setType(res.data.data.projectType)
//             } else {
//                 console.log("실패")
//             }
//         })

//         for (let i = 0; i < count; i++){
//             Newarr[i] = { cam: 'C' + String(i + 1).padStart(2, "0"), filename: [] };
            
//             axios({
//                 method: 'get',
//                 url: API_URL+'/file/files',
//                 headers: { "accept": `application/json`, "access-token": `${token}` },
//                 params: {
//                     path: `/project/${projectId}/stage1/${images[i].folderName}`
//                 }
//             }).then((res)=>{
//                 if (res.data.check === true) {
//                     console.log("성공")
                    
//                     console.log(res)

//                     for(let j=0; j<res.data.data.files.length; j++){
//                         images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1/${images[i].folderName}/${res.data.data.files[j]}&width=360`)
//                     }

//                     setResult(true);

//                 } else {
//                     console.log(res)
//                     console.log("실패")
//                 }
//             }).catch((err) => {
//                 console.log(err);
//             });
//         }
        
//         setArr(Newarr)
//     }, [])

//     let copyArrImgList = [...imgList];
//     let copyArrCNT = [...finalCNT];
//     let copyArr = [...URLarr];
//     let copyfilename = [...arr]
//     let filename = [...fileArr]
//     const onClickImage = (e: any) => {
//         console.log(e.target)
//         const id = e.target.id
//         filename = [...copyfilename[id].filename]
//         const str = e.target.src
//         let index1 = ""
//         if (projectType === "tunnel") {
//             index1 = str.indexOf(".png")
//         } else if(projectType==="airport") {
//             index1=str.indexOf(".JPG")
//         }
        
//         const index2 = str.indexOf("C")
//         console.log(str.substring(index2 + 4, index1 + 4))//C01_00000.png
//         console.log(Number(id), cnt[e.target.id].camera_number+2)
//         if (Number(id) === cnt[e.target.id].camera_number+1) {
//             let name = str.substring(index2 + 4, index1 + 4)
//             console.log(name)
//             console.log(copyArr)
//             if (copyArr.includes(name) === false) {
//                 console.log("여기1")
//                 //border
//                 copyArr.push(name)
//                 console.log(copyArr)
//                 localStorage.setItem("heatmapArr", JSON.stringify(copyArr))
//                 //선택 개수 계산
//                 copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt + 1 }
//                 copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
//                 //filename
//                 filename.push(name)
//                 setFileArr(filename)
                
//             } else {//선택이미지 중복일때
//                 console.log("여기2")
//                 //  border없앰
//                 const i = copyArr.indexOf(name)
//                 copyArr.splice(i, 1)
//                 console.log(copyArr)
//                 localStorage.setItem("heatmapArr", JSON.stringify(copyArr))
//                 // 선택 개수 계산
//                 copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt -1 }
//                 copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
//                 //filename 
//                 const j = filename.indexOf(name)
//                 filename.splice(j, 1)
//             }
//         }
//         else {
//             console.log("여기")
//         }
//         setFinalCNT(copyArrCNT)
//         setImgList(copyArrImgList)
//         setURLarr(copyArr)
        
//         copyfilename[id] = {...copyfilename[id], filename: filename}
//         setArr(copyfilename)
        
//     }

//     return (
//         <Layout>
//             <div style={{ display : 'flex', justifyContent: 'space-between', width:'85%'}}>
//             <div>
//               <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; FOD 검출 & 측정</div>
//               <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>FOD 검출 & 측정</div>
//             </div>
//             <div style={{ display: 'flex', alignItems : 'center', marginTop:'3rem'}}>
//               <div style={{ fontWeight : 700}}>Model </div>
//               <div style={{marginLeft:'1rem'}}>
//                   {selectModel()}
//               </div>    
//             </div>
//             </div>
//             <div style={{ display : 'flex'}}>
//                 <div>
                
//                 <Box sx={{ width: '20rem',  backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem',paddingBottom: '2rem'}}>
//                     <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
//                         <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>카메라 리스트 </div>
//                         <div>
//                             <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', background: '#0F6FB7' }} onClick={onClick}>확인</Button>
//                         </div>
//                     </div>
//                     <div>
//                         <TableContainer  sx={{ width: '18rem', marginLeft: '1rem', marginTop: '1rem' }}>
//                             <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
//                                 <TableHead>
//                                 <TableRow style={{ height: '2.5rem'}}>
//                                     <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard'}}>번호</StyledTableCell>
//                                     <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>이름</StyledTableCell>
//                                     <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>개수</StyledTableCell>
//                                 </TableRow>
//                                 </TableHead>
                                
//                                 <TableBody>
//                                 {imgList.map((row, i) => (
//                                     <TableRow 
//                                         key={row.num}
//                                         style={{ height: '2.5rem'}}
//                                     >
//                                     <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.num+1}</StyledTableCell>
//                                     <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.folderName}</StyledTableCell>
//                                     <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.cnt}</StyledTableCell>
//                                     </TableRow> 
//                                 ))}
//                                 </TableBody>
                                
//                             </Table>
//                             <TableFooter>
//                                 <TableRow style={{ width: '18rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
//                                 <Pagination count={imgList.length > 10 && imgList.length % 10 === 0 ? Math.floor(imgList.length/10) : Math.floor(imgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
//                                     sx={{
//                                       '& svg': {
//                                         pointerEvents: 'none'
//                                       }
//                                     }}
//                                   />
//                                 </TableRow>
//                                 </TableFooter>
//                         </TableContainer>
//                     </div>
//                 </Box> 
//                 </div>
//                 <div style={{ marginTop: '1rem', overflowY : 'scroll', height : '50rem'}}>
//                     {
//                         result === true ? 
//                         images.map((row, i) => (
//                             <Box sx={{ width: '73.6875rem', height: '20rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
//                                 <div style={{padding : '2rem'}}>
//                                     {/* 네임라인 */}
//                                     <div style={{ display: 'flex', justifyContent: 'space-between'}}>
//                                             <div style={{ display : 'flex'}}>
//                                                 <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{i+1}</div>
//                                                 <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{row.folderName}</div>
//                                             </div>

//                                     </div>
//                                     {/* 이미지라인 */}
//                                     <div style={{ width : '69.65rem', height : '15rem', marginTop : '0.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
//                                     { rendering(i) }
                                        
//                                     </div>
//                                 </div>
//                             </Box>
//                         )) :
//                         <div>
//                             이미지 로딩 중 입니다.
//                         </div>
//                     }
                    
//                 </div>
//             </div>
//         </Layout>
//     );
// };