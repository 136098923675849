import { ReturnValue,ReturnValues } from "../allmodels";
import { apiHelper,util,formHelper } from "../allutils";
import React, { useState, useEffect } from 'react';
import config from "../config";
import axios from "axios";
import { API_URL, IMAGE_URL, OCR_URL } from '../Store/Global';

// 기업 회원가입 페이지
const CompanyJoinPage = () => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMiLCJpYXQiOjE2OTM0NTMxNDAsImV4cCI6MTY5MzQ2MjE0MH0.h3i1nTon-aFXf5DkljeQGue9BJSBoFoDkjHXtQm0xHY"
    const [state, setState] = useState({
        "name": "",
        "phone": "",
        "businessRegNo": "",
        "addr1": "",
        "addr2": "",
        "domain": "",
        "masterID": 0,
        "employee": "",
        "isAccept": true,
        "isCharge": true,
        "isSurcharge": true
    })

    const [file, setFile] = useState();

    // ocr 작업
    const onChangeImg = (e:any) => {
        e.preventDefault();
        const formData = new FormData();

        if(e.target.files){
            const uploadFile = e.target.files[0]
            formData.append('file',uploadFile)
            setFile(uploadFile)
            console.log(uploadFile)
            console.log('===useState===')
            console.log(file)

            // ocr api 호출
            axios({
                method: 'post',
                url: OCR_URL + '/ocr/business_registration',
                headers: { 
                    "accept": `*/*`,
                    "Content-Type": `multipart/form-data`,
                },  
                data: {
                    file:uploadFile
                },
                    
                }).then((res) => {
                console.log(res.data)
                if(res.data) {
                    setState({
                        ...state,
                        ['addr1']:  res.data?.Corporate_Address,
                        ['name'] : res.data?.Corporate_Name,
                        ['employee'] : res.data?.Corporate_Owner,
                        ['businessRegNo'] : res.data?.Registration_Num
                    })
                }

                
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleChange = (e: any) => {
        console.log(e)
        setState({
            ...state,
            [e.target.name]:  e.target.value
        })
  }

    // 회원가입 버튼
    const BtnJoin = () => {
        // if 예외처리가 되어서 모든 값이 제대로 입력되면
        formHelper("frm", function(jsonData:any) {
            if (util.IsNullOrEmpty(jsonData.name)) {
                alert("회사명을 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.phone)) {
                alert("연락처를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.businessRegNo)) {
                alert("사업자 번호를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.addr1)) {
                alert("주소를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.employee)) {
                alert("대표자명을 입력하세요.");
            } 
            else {
                console.log(state)
                axios({
                    method: 'post',
                    url: API_URL + '/company/regist',
                    headers: { 
                        "accept": `application/json`,
                        "access-token": `${token}`,
                        "Content-Type": `application/json`
                    },  
                    data: { 
                    "name": state.name,
                    "phone": state.phone,
                    "businessRegNo": state.businessRegNo,
                    "addr1": state.addr1,
                    "addr2": state.addr2,
                    "domain": state.domain,
                    "masterID": state.masterID,
                    "employee": state.employee,
                    "isAccept": state.isAccept,
                    "isCharge": state.isCharge,
                    "isSurcharge": state.isSurcharge
                    },
                        
                    }).then((res) => {
                    console.log(res)
                    if(res.data.check) {
                        //기업 회원가입에 성공하면 
                        alert("기업 회원가입이 완료되었습니다.")
                        util.Move('/'); //main 페이지로 이동합니다.
                    }
                    else {
                        alert("다시 시도해주세요. : " + res.data.message)
                    }
                    }).catch((err) => {
                    console.log(err)
                    })
                
            }
        });
    };

    

    return(
        <div style={{ background: 'url("/images/background/login_background.png")', backgroundSize:'cover', height: '100vh'}}>
        <div className="login-page-wrapper">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                    <div className="card shadow-lg">
                    <div className="card-body">
                        <div className="px-3">
                            <div style={{ textAlign:"center" }}>
                                {/* <img src="#" height="55" alt="logo" className="auth-logo" /> */}
                            </div>
                            <div className="text-center auth-logo-text">
                                <h4 className="mt-0 mb-3 mt-5" style={{ color : 'white'}}>기업 회원가입</h4> 
                                {/* <input onClick={()=> ocrDo()}>OCR</input> */}
                                <div style={{background:'#dce6f5', borderRadius:'0.5rem', padding:'0.5rem', marginBottom:'1rem' }}>
                                    {/* <label htmlFor="company-name" style={{ color : 'white'}}>회사명</label>*/}
                                    <div style={{ fontSize:'0.75rem', textAlign:'left', marginLeft:'0.25rem'}}>사업자 등록증을 업로드하면 OCR 기능으로 <br/>기업 정보가 자동으로 입력됩니다. <br/><span style={{ textDecoration:"underline", fontWeight:600}}>업로드한 이미지는 저장되지 않습니다.</span></div>
                                    <div style={{ float:'right', marginTop:'-1.5rem'}}>
                                        <label style={{ width:'3rem', height:'1.25rem', background: 'white', borderRadius:'1rem', fontSize:'1rem', color:'red', cursor:'pointer', fontWeight:700, padding:'0.25rem'}} htmlFor="ocr">Click</label>
                                        <input type="file" id="ocr" accept="image/*" style={{ display:'none'}} onChange={onChangeImg}/>   
                                    </div>
                                                           
                                </div>
                                
                                
                            </div>
                            <form className="form-horizontal auth-form my-4" name="frm" id="frm" method="post" style={{ marginLeft:'1rem'}}>
                                <div className="form-group2">
                                    <label htmlFor="company-name" style={{ color : 'white'}}>회사명</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="name" name="name" placeholder="회사명을 입력해주세요" value={state.name} defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="company-phone" style={{ color : 'white'}}>연락처</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="phone" name="phone" placeholder="연락처를 입력해주세요" defaultValue={''} value={state.phone} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="company-name" style={{ color : 'white'}}>사업자 번호</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="businessRegNo" name="businessRegNo" placeholder="사업자 번호를 입력해주세요" value={state.businessRegNo} defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="company-name" style={{ color : 'white'}}>주소</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="addr1" name="addr1" placeholder="소재지를 입력해주세요" defaultValue={''} value={state.addr1}  onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="company-name" style={{ color : 'white'}}>대표명</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="employee" name="employee" placeholder="대표자명을 입력해주세요" defaultValue={''} value={state.employee} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group mb-0 row">
                                    <div className="col-12 mt-2" style={{ paddingTop : '2rem'}}>
                                        <button className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light" type="button" onClick={BtnJoin}>회원가입 <i className="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default CompanyJoinPage;