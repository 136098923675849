import React, { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react'
import styles from '../../../../../styles/Preprocess.module.css'
import { Progress, Tooltip } from 'antd';
import { ImageContents } from '../../../../../Store/Type/type';
import { videoURL, ImageList, TimeSet, project_info } from '../../../../../Store/State/atom';
import { useRecoilValue, useRecoilState, constSelector } from 'recoil';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled } from '@mui/material';
import axios from "axios";
import { API_URL} from '../../../../../Store/Global';

export default function ImageUpload() {
    let token: string | null = localStorage.getItem("token") 
    let projectId: string | null = localStorage.getItem("projectId")

    const [page, setPage] = React.useState(0);
    
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }


    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);


  let ProjectInfo = useRecoilValue<any>(project_info)
  useEffect(() => {
        if (typeof window !== 'undefined' || "null") {
            // console.log('You are on the browser');
            token = localStorage.getItem("token");
        } else {
            console.log('You are on the server');
            // 👉️ can't use localStorage1
        }
        
    })
    
    //image upload url 
    // const [videoUrl, setVideoUrl] = useRecoilState(videoURL);
    const [imageList, setimageList] = useRecoilState<ImageContents[]>(ImageList);
    const [files, setFiles] = useState<ImageContents[]>([]);

    const [num, setNum] = useState<number>(0);

    // let video_url = useRecoilValue(videoURL);
  
    // 드래그 중일때와 아닐때의 스타일을 구분하기 위한 state 변수
    const [isDragging, setIsDragging] = useState<boolean>(false);
  
    // 각 선택했던 파일들의 고유값 id
    const fileId = useRef<number>(0);
  
    // 드래그 이벤트를 감지하는 ref 참조변수 (label 태그에 들어갈 예정)
    const dragRef = useRef<HTMLLabelElement | null>(null);
    
    let tempFiles: ImageContents[] = files;

    const [errMsg, setErrMsg] = useState<any>();
    const text = <span>{errMsg}</span>;
    let ob : any= [];

    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void => {
      let selectFiles: File[] = [];
      if (e.type === "drop") {
        console.log(e.dataTransfer.files)
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }
      // selectFiles = [...selectFiles].sort((a, b) => a.name > b.name ? 1 : -1)
      
      for (const file of selectFiles) {
        // selectFiles.forEach((file, idx) => {

        // })
        
        let url = URL.createObjectURL(file);
        
        let n = file.name.indexOf('C')

        // imageList.filter((a: any) =>console.log( a.Video_name.props.children === file.name))
        ob = imageList.filter((a: any) => a.Video_name.props.children !== file.name) //imageList에 중복 제거
        if (ob.length === 0 ) {
          // console.log("ob배열 비였어")
          tempFiles = [
          ...tempFiles, 
          {
            // no: Number(file.name.substring(n+1,n+3)),
            no:1+fileId.current++,
            url: url,//file,
            name: <a>{file.name}</a>,
            reversal: 0,
            status: <Progress type="circle" percent={0} width= {40} />
          }];
          
        } else {
          // console.log("중복 배열 카메라수", ob[0].no,tempFiles)
          const a = tempFiles.findIndex(a => (a.no === ob[0].no))
          if (a !== -1) { 
            console.log(ob)
            tempFiles = [...ob, {
              no: 1+ob.length,
              // no: Number(file.name.substring(n+1,n+3)),
              url: url,//file,
              name: <a>{file.name}</a>,
              reversal: 0,
              status: <Progress type="circle" percent={0} width= {40} />
            }]
          }
        }
        
        
          // 오름차순 코드
        tempFiles.sort((obj1, obj2) => {
          if (obj1.no > obj2.no) {
            return 1;
          }
          if (obj1.no < obj2.no) {
             return -1;
          }
          return 0;
        })
        setimageList(tempFiles)
        let path = 'stage0/C' + String(file.name.substring(n+1,n+3)).padStart(2,'0')
        let filename = file.name
           // 서버 업로드
           const upload_res = axios({
            method: 'post',
            //  url: API_URL+`/File/Upload/${projectId}/${path}/${filename}`,
             url: API_URL + `/file/upload/${projectId}?path=${path}&filename=${filename}`,
             headers: { 
             "accept": `application/json`,
             "access-token": `${token}`,
             "Content-Type": `multipart/form-data`  },
             data: { upload: file ,
                    onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
              //  console.log(timeSet, imageList)
                  
              let copyarr = [...tempFiles] 
              let a = Math.round((progressEvent.loaded*100)/progressEvent.total)
              const ind = copyarr.findIndex(element => element.name.props.children === filename)
              
              if (ind !== -1) {
                // console.log(ind, ' : ', a)  
                copyarr[ind] = { ...copyarr[ind],status: <Progress type="circle" percent={a} width={40} /> }
                tempFiles =copyarr
               }
              setimageList(copyarr)
            }
           }}).then((res) => {
          // setimageList(tempFiles)
            if (res.data.check === true) {
                console.log("성공")
                setFiles(tempFiles)
              } else {
              console.log("실패 : ", res.data.message)
              let copyarr = [...tempFiles]
              const ind = copyarr.findIndex(element => element.name.props.children === filename)
              if (ind !== -1) {
                  copyarr[ind] = { ...copyarr[ind], status: <Progress type="circle" status='exception' width={40} /> }
              }
                setimageList(copyarr)
                tempFiles =copyarr
              if (res.data.message === '해당 파일이 이미 존재합니다.') {
                  setErrMsg('이미 파일이 존재합니다.')
                  alert(res.data.message)

                }
              }
            }).catch((err) => {
              console.log(err);
            });
          }
          setimageList(tempFiles)
        console.log(tempFiles)
      }, [files]);
    
    const handleFilterFile = useCallback((id: number): void => {
      setFiles(files.filter((file: ImageContents) => file.no !== id));
    }, [files]);
  
    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

    }, []);
    const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
      initDragEvents();
    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents, imageList]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '1rem',
      fontWeight: 600,
      backgroundColor: '#F5F5F5',
      color: '#3A4557',
      width: '42.8125rem',
      height: '2.5rem',
      border: '1px solid #DDD',
      borderTop: '0.15rem solid #3A4557',
      padding : 0
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#3A4557',
      textAlign: 'center',
      fontSize: '0.8125rem',
      width: '42.8125rem',
      height: '3rem',
      border: '1px solid #DDD',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap', overflow:'hidden',
      padding : 0,
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td': {
    },

  }));
    
  return (
      <div>
        <Box sx={{
            width: '66rem',
            height: '8rem',
            borderRadius: '0.5rem',
            background: '#FFF',
            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
            padding : '2rem'
        }}>
            <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
              <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>이미지 업로드 </div>
              </div>
            <div style={{
                width: '65.75rem',
                height: '6rem',
                borderRadius: '1rem',
                border: '1px solid rgba(222, 228, 242, 0.40)',
                background: 'rgba(245, 245, 245, 0.50)',
                marginTop : '1rem',
                textAlign: 'center'
            }}>
            <input
                type="file"
                id="fileUpload"
                style={{ display: "none" }} // label을 이용하여 구현하기에 없애줌
                multiple={true} // 파일 다중선택 허용
                onChange={onChangeFiles}
                accept=".jpg, .png, .jpeg"
            />

            <label
                className={styles.isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
                // 드래그 중일때와 아닐때의 클래스 이름을 다르게 주어 스타일 차이
                htmlFor="fileUpload"
                ref={dragRef}
            >
                    <div style={{ marginBottom : '0.5rem'}}>
                        <p/>
                        <div style={{  marginBottom : '0.5rem'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                              <g clip-path="url(#clip0_1082_5475)">
                                  <path d="M20.4173 2.91699H8.75065C7.14648 2.91699 5.84857 4.22949 5.84857 5.83366L5.83398 29.167C5.83398 30.7712 7.1319 32.0837 8.73607 32.0837H26.2507C27.8548 32.0837 29.1673 30.7712 29.1673 29.167V11.667L20.4173 2.91699ZM26.2507 29.167H8.75065V5.83366H18.959V13.1253H26.2507V29.167ZM11.6673 21.8899L13.7236 23.9462L16.0423 21.642V27.7087H18.959V21.642L21.2777 23.9607L23.334 21.8899L17.5152 16.042L11.6673 21.8899Z" fill="#0F6FB7"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_1082_5475">
                                  <rect width="35" height="35" fill="white"/>
                              </clipPath>
                              </defs>
                          </svg>
                      </div>
                    <div style={{
                        color: '#3A4557',
                        fontSize: '0.9rem',
                        fontWeight: 500
                    }}>
                        클릭 또는 드래그하여 파일을 업로드 해주세요.
                    </div>
                </div>
              </label>
        </div>
        </Box>
        
        <div style={{display:'flex'}}>
        <div style={{ marginRight: '2rem'}}>
        <Box sx={{
            width: '25rem',
            borderRadius: '0.5rem',
            background: '#FFF',
            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
            padding : '2rem',
            marginTop : '2rem'
        }}>
                <div style={{ justifyContent : 'space-between', display: 'flex', marginBottom:'0.75rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>이미지 파일 리스트 </div>
                </div>
                <div className={styles.uploadList}>
                <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem' }}>
                    <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                        <TableHead>
                        {imageList.length !== 0 ? 
                                <TableRow >
                                <StyledTableCell align="center" style={{width:'3rem', fontSize: '0.875rem', fontFamily:'inter-pretendard'}}>no</StyledTableCell>
                                <StyledTableCell align="center" style={{width:'10rem', fontSize: '0.875rem',fontFamily:'inter-pretendard'}}>Name</StyledTableCell>
                                <StyledTableCell align="center" style={{width:'3rem', fontSize: '0.875rem', fontFamily:'inter-pretendard'}}>상태</StyledTableCell>
                            </TableRow>
                             : <div style={{fontFamily:'inter-pretendard'}}>이미지를 업로드 해주세요.</div>}
                        
                        </TableHead>
                        
                        <TableBody>
                          {imageList.length !== 0 ? imageList.map((row:any, idx:any) => (
                            <StyledTableRow 
                                key={idx}
                                style={{ height: '2.5rem', cursor:'pointer'}}
                                onClick={() => console.log(idx)}
                            >
                            <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{idx+1}</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'10rem', height: '2.5rem', fontFamily:'inter-pretendard', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden', textAlign:'left', paddingLeft : '1rem'}}>{row.name}</StyledTableCell>
                            <StyledTableCell align="center" style={{width:'3rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.status}</StyledTableCell>
                            </StyledTableRow> 
                        )) : <></>}
                        </TableBody>
                    </Table>
                    <TableFooter>
                    {imageList.length !== 0 ? 
                      <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                      <Pagination count={imageList.length > 10 && imageList.length % 10 === 0 ? Math.floor(imageList.length/10) : Math.floor(imageList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                          sx={{
                            '& svg': {
                              pointerEvents: 'none'
                            }
                          }}
                        />
                      </TableRow>
                     : <></>}
                    
                  </TableFooter>
                </TableContainer>
                </div>
            </Box>
        </div> 
        {imageList.length !== 0 ? 
        <div>
            <Box sx={{
                width: '35rem',
                height: '25rem',
                borderRadius: '0.5rem',
                background: '#FFF',
                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                padding : '2rem',
                marginTop : '2rem',
                paddingBottom : '3rem',
            }}>
                <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>이미지 뷰어 </div>
                </div>
                <div style={{ marginTop : '0.75rem'}}>
                  {/* <VideoController/> */}
                  <img src={imageList[num].url!} id={String(num)} key={imageList[num].url} alt={imageList[num].url} className={'imagetask'} style={{width: '35rem', marginTop : '1.25rem', borderRadius: '0.5rem'}}/>
                </div>
            </Box>
        </div>  
        : <></>}
        
      </div>
          
    </div>
  )
}