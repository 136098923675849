import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Button } from '@mui/material';
  
const { Option } = Select;

interface ImgContents {
    no : number
    name : any
    sort : string
}

interface OriImage {
    no : any
    original_image : string
}

export default function BridgeHorizontalPanoramaPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setCompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    const ImgContents : ImgContents[] = []; 
    const OriImage : OriImage[] = [];  

    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)
    const [ImgListOri, setImgListOri] = useState<any[]>(OriImage);
    const [data, setData] = useState<any[]>(OriImage);

    const [resultOri, setResultOri] = useState<boolean>(false)
    const [resultDefect, setResultDefect] = useState<boolean>(false)

    const [setting, setSetting]=useState<any>([])
    const [typeArr, setType]=useState([])

    const [BridgeType, setBridgeType] = useState<string>("")
    const [GirderCount, setGirderCount] = useState<number>(0)

    const [num, setNum] = useState<number>(0);

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        setData(ImgList.slice((page-1)*10, page*10));
      }, [page]);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    
    useEffect(()=>{
        setResultOri(false);
        setResultDefect(false);

        setImgList([])
        setImgListOri([])
        

        //교량 구조물 종류 받아오기
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            const settings: any = JSON.parse(res.data.data.settings)
                console.log(settings)
                setSetting(settings)
                setType(settings.bridge_type)
                setGirderCount(settings.girder_count)
                console.log(BridgeType)

                if(BridgeType === "Girder"){          
                    for(let i = 0; i < settings.span_count; i++){
                        for(let k = 0; k < settings.span_length/settings.span_class_length; k++){
                        axios({
                            method: 'get',
                            url: API_URL+'/File/Files',
                            headers: { "accept": `application/json`, "access-token": `${token}` },
                            params: {
                                path: `/project/${projectId}/stage4/${BridgeType}/S${"0"+settings.span_number_list[i].num+"0"+(k+1)}`
                            }
                            }).then((res)=>{
                                for(let y = 0; y < res.data.data.files.length; y++){
                                    const indexFront = res.data.data.files[y].indexOf("S") 
                                    const indexBack = res.data.data.files[y].indexOf(".png") 
                                    const SpanNum = res.data.data.files[y].substring(indexFront, indexBack)

                                    OriImage.push({
                                        original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/S${"0"+settings.span_number_list[i].num+"0"+(k+1)}/${res.data.data.files[y]}&width=1440`,
                                        no : OriImage.length
                                    })
                
                                    ImgContents.push({
                                        no : ImgContents.length,
                                        name : <a style={{color:"black"}}>{res.data.data.files[y]}</a>,
                                        sort : SpanNum
                                    })                                     
                                } 

                                OriImage.sort((obj1, obj2) => {
                                        if (obj1.original_image > obj2.original_image) {
                                            return 1;
                                        }
                                        if (obj1.original_image < obj2.original_image) {
                                            return -1;
                                        }
                                        return 0;
                                })
                                    
                                ImgContents.sort((obj1, obj2) => {
                                        if (obj1.sort > obj2.sort) {
                                            return 1;
                                        }
                                        if (obj1.sort < obj2.sort) {
                                            return -1;
                                        }
                                        return 0;
                                })

                                let copyArrOriImage = [...OriImage];
                                let copyArrImgContents = [...ImgContents];    

                                if(OriImage.length === settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count){
                                    setResultOri(true);

                                    for(let j = 0; j < OriImage.length; j++){
                                        // console.log(j+1)
                                        copyArrOriImage[j] = { ...copyArrOriImage[j], no : j+1};
                                        copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                    }
                                }           
                                
                                setImgListOri(copyArrOriImage);
                                setData(copyArrImgContents.slice((page-1)*10, page*10));
                                setImgList(copyArrImgContents); 
                                // console.log(`stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`)
                        })
                        }
                    }
                } if(BridgeType === "Slab"){          
                    for(let i = 0; i < settings.span_count; i++){
                        for(let k = 0; k < settings.span_length/settings.span_class_length; k++){
                        axios({
                            method: 'get',
                            url: API_URL+'/File/Files',
                            headers: { "accept": `application/json`, "access-token": `${token}` },
                            params: {
                                path: `/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`
                            }
                            }).then((res)=>{
                                for(let y = 0; y < res.data.data.files.length; y++){
                                    const indexFront = res.data.data.files[y].indexOf("S") 
                                    const indexBack = res.data.data.files[y].indexOf(".png") 
                                    const SpanNum = res.data.data.files[y].substring(indexFront, indexBack)

                                    OriImage.push({
                                        original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}/${res.data.data.files[y]}&width=1440`,
                                        no : OriImage.length
                                    })
                
                                    ImgContents.push({
                                        no : ImgContents.length+1,
                                        name : <a style={{color:"black"}}>{res.data.data.files[y]}</a>,
                                        sort : res.data.data.files[y]
                                    })                                     
                                } 
                                
                                ImgContents.sort((obj1, obj2) => {
                                    if (obj1.no > obj2.no) {
                                        return 1;
                                    }
                                    if (obj1.no < obj2.no) {
                                        return -1;
                                    }
                                    return 0;
                            })

                                let copyArrOriImage = [...OriImage];
                                let copyArrImgContents = [...ImgContents];    

                                if(OriImage.length > 0){
                                    setResultOri(true);
                                }           
                                console.log(settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count)
                                setImgListOri(copyArrOriImage);
                                setData(copyArrImgContents.slice((page-1)*10, page*10));
                                setImgList(copyArrImgContents); 
                        })
                        }
                    }
                } 
                if(BridgeType === "Pier"){
                    for(let i = 0; i < settings.pier_count; i++){
                        axios({
                            method: 'get',
                            url: API_URL+'/File/Files',
                            headers: { "accept": `application/json`, "access-token": `${token}` },
                            params: {
                                path: `/project/${projectId}/stage4/${BridgeType}/P0${settings.pier_number_list[i].num}`
                            }
                            }).then((res)=>{
                                for(let y = 0; y < res.data.data.files.length; y++){
                                    const indexFront = res.data.data.files[y].indexOf("S") 
                                    const indexBack = res.data.data.files[y].indexOf(".png") 
                                    const SpanNum = res.data.data.files[y].substring(indexFront, indexBack)

                                    OriImage.push({
                                        original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/P0${settings.pier_number_list[i].num}/${res.data.data.files[y]}&width=1440`,
                                        no : OriImage.length
                                    })
                
                                    ImgContents.push({
                                        no : ImgContents.length+1,
                                        name : <a style={{color:"black"}}>{res.data.data.files[y]}</a>,
                                        sort : res.data.data.files[y]
                                    })                                     
                                } 
                                
                                ImgContents.sort((obj1, obj2) => {
                                    if (obj1.no > obj2.no) {
                                        return 1;
                                    }
                                    if (obj1.no < obj2.no) {
                                        return -1;
                                    }
                                    return 0;
                            })

                                let copyArrOriImage = [...OriImage];
                                let copyArrImgContents = [...ImgContents];    

                                if(OriImage.length > 0){
                                    setResultOri(true);
                                }           
                                console.log(settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count)
                                setImgListOri(copyArrOriImage);
                                setData(copyArrImgContents.slice((page-1)*10, page*10));
                                setImgList(copyArrImgContents); 
                        })
                        
                    }
                }         
                else if(BridgeType === "GirderSide"){          
                    for(let i = 0; i < settings.span_count; i++){
                        for(let k = 0; k < settings.span_length/settings.span_class_length; k++)
                        {
                        axios({
                            method: 'get',
                            url: API_URL+'/File/Files',
                            headers: { "accept": `application/json`, "access-token": `${token}` },
                            params: {

                                path: `/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`
                            }
                            
                            }).then((res)=>{
                                for(let y = 0; y < res.data.data.files.length; y++){
                                    const indexFront = res.data.data.files[y].indexOf("S") 
                                    const indexBack = res.data.data.files[y].indexOf(".png") 
                                    const SpanNum = res.data.data.files[y].substring(indexFront, indexBack)
                                    // console.log(`/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`)
                                    // console.log(res.data.data.length)

                                    OriImage.push({
                                        original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}/${res.data.data.files[y]}&width=1440`,
                                        no : OriImage.length
                                    })
                
                                    ImgContents.push({
                                        no : ImgContents.length+1,
                                        name : <a style={{color:"black"}}>{res.data.data.files[y]}</a>,
                                        sort : res.data.data.files[y]
                                    })                                     
                                } 
                            ImgContents.sort((obj1, obj2) => {
                                    if (obj1.no > obj2.no) {
                                        return 1;
                                    }
                                    if (obj1.no < obj2.no) {
                                        return -1;
                                    }
                                    return 0;
                            })

                                let copyArrOriImage = [...OriImage];
                                let copyArrImgContents = [...ImgContents];    

                                if(OriImage.length === settings.girder_count * settings.span_length/settings.span_class_length){
                                    setResultOri(true);
                                }           

                                setImgListOri(copyArrOriImage);
                                setImgList(copyArrImgContents); 
                                setData(copyArrImgContents.slice((page-1)*10, page*10));
                        })
                        }
                    }
                } 

                
        }).catch((err) => {
            console.log(err);
        });     
        
    }, [BridgeType])

    const onChangeType = (e: any) => {
        setIsSelect(true)
        console.log(e);
        if (e === "Girder") {
            setBridgeType("Girder");
            } else if (e === "GirderSide") {
            setBridgeType("GirderSide");
            } else if (e === "Slab") {
            setBridgeType("Slab");
            } else if (e === "SlabSide") {
            setBridgeType("SlabSide");
            } else if (e === "Pier") {
            setBridgeType("Pier");
            console.log("Pise")
            } else {
            setBridgeType("Abutment");
            }
        };

//     const onClickPonirama = (e:any) => {
//       // console.log(arr);
//       // console.log(Verstitch);    
//       axios({

//           method: "post",
//           url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
//           headers: {
//             "accept": `application/json`,
//             "access-token": `${token}`,
//             "Content-Type": `application/json`
//           },
//           data: {
//               projectId: projectId,
//               task_name: "ver_stitch_old",
//               interactive: false,
//               tasks: [
//                   {
//                       input_folder: `stage4/${damtype}`,
//                       output_folder: "stage5",
//                       yml_output_folder: "stage5_YML",
//                       cam_no: 2,
//                       conf_name: "config.cfg"
//                   }
//               ],
//           }
//       }).then((res)=>{
//           if (res.data.check === true) {
//               console.log("파노라마 성공")
//               alert("수직파노라마 작업")

//           } else {
//               console.log("파노라마 실패")
//           }
//       }).catch((err) => {
//           console.log(err);
//       });
//   }

  const onSave = () => {
    alert("수직 파노라마를 생성합니다.")
    setTimeout(function() {
      alert("수직 파노라마가 생성되었습니다.")
      navigate(`/dam/project/panorama/vertical/${projectId}`)
    }, 3000);}
        
  
      const rendering = (i:any) => {
          const result = [];
          result.push(
            <div>
                <div style={{ marginTop : '1.5rem'}}>
                    <img style={{ width:'50rem', height:'20rem' ,borderRadius: '0.625rem'}} src={resultOri === true ? ImgListOri[num].original_image : ""} alt="" />
                </div>
                <div style={{ display:'flex', float : 'right' }}>
                <div style={{marginTop : '1.5rem'}}>
                        <Button variant="outlined" sx={{ fontWeight : 600, fontFamily:'inter-pretendard'}} onClick={onSave} >수직 파노라마 실행</Button>
                    </div>
                </div>
            </div>

        );
          return result;
      };

      const onClickPanor = (i:any) => {
        setNum(i);
    };


    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const [isSelect, setIsSelect] = useState<boolean>(false)

      const option_render=()=>{
        const arr:any[]=[];
        
        typeArr.map((type:any)=>{
            let name=''
            if(type==='Girder'){
                name='거더 하면'
            }else if(type==='GirderSide'){
                name='거더 측면'
            }else if (type === 'Slab') {
                name='슬라브 하면'
            } else if (type === 'Pier') {
                name ='교각'
            } else if(type==='Abutment') {
                name = '교대'
            }
            arr.push(<Option value={type} > {name}</Option>)
        })
        return arr;
    }

      const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요" style={{ width : '8rem'}} onChange={onChangeType}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>파노라마 &gt; 수평 파노라마</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>수평 파노라마</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div>
                <div  style={{ marginTop: '1rem'}}>
                    <Box sx={{
                        display: 'block',
                        width: '8rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }}>
                        {selectModel()}
                    </Box>
                </div>
                
                    <div style={{ display:'flex', marginTop: '1rem'}}>
                        <div >
                        {
                            typeArr.length !== 0 && ImgList.length !== 0 ?
                            <div style={{display:'flex'}}>
                                <div>
                                    <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem'}}>
                                        <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                                        </div>
                                        <div>
                                            <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
                                                <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                                    <TableHead>
                                                    
                                                    <TableRow >
                                                        <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                                        <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                    {resultOri ? data?.map((row:any, idx:any) => (
                                                        <StyledTableRow 
                                                            key={row.original_image}
                                                            style={{ height: '2.5rem', cursor:'pointer'}}
                                                            onClick={() => onClickPanor(idx)}
                                                        >
                                                        {/* <StyledTableCell component="th" scope="row" align="center">
                                                            {row.num}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'2em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{(page-1)*10+(idx+1)}</StyledTableCell>
                                                        {/* <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{data[idx]?.original_image.split('stage4/Overflow/')[1].split('&width')[0]}</StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.name}</StyledTableCell>
                                                        </StyledTableRow> 
                                                    )): ''}
                                                    </TableBody>
                                                </Table>
                                                <TableFooter>
                                            <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                            <Pagination count={ImgList.length > 10 && ImgList.length % 10 === 0 ? Math.floor(ImgList.length/10) : Math.floor(ImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                                sx={{
                                                '& svg': {
                                                    pointerEvents: 'none'
                                                }
                                                }}
                                            />
                                            </TableRow>
                                            </TableFooter>
                                            </TableContainer>
                                            
                                        </div>
                                    </Box> 
                                </div>
                                <div>
                                    <Box sx={{ width: '56.25rem', paddingBottom : '3rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                        <div style={{padding : '3rem'}}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>이미지 뷰어 </div>
                                                {/* <div style={{ fontWeight : 600, fontSize:'1rem', fontFamily:'inter-pretendard', marginRight:'0.25rem'}}>{ImgListOri[num]?.original_image.split('stage4/Overflow/')[1].split('&width')[0]}</div> */}
                                                <div style={{ fontWeight : 600, fontSize:'1rem', fontFamily:'inter-pretendard', marginRight:'0.25rem'}}>{ImgList[num]?.name}</div>
                                            </div>
                                            <div>
                                                {rendering(num)}
                                            </div>
                                        </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            : isSelect ?
                            <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem'
                            }}>
                                <div>이미지를 업로드 해주세요.</div>
                            </Box>
                            : <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem'
                            }}>
                                <div>구역을 먼저 선택해주세요.</div>
                            </Box>
                        
                        }
                        </div>
                        
                    </div>
                    
                </div>
            
            </div>
        
        </Layout>
    );
};

