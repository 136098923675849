import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Layout } from '../../../../allcomponents';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AirportCreateSettingTab from './AirportCreateSettingTab'
// import PanoramaSettingTab from './PanoramaSettingTab';
// import DetectorSettingTab from './DetectorSettingTab';
// import MeasureSettingTab from './MeasureSettingTab';
// import DrawerSettingTab from './DrawerSettingTab';
// import EstimatorSettingTab from './EstimatorSettingTab';
import styles from '../../../../styles/Preprocess.module.css'
import { useNavigate } from 'react-router-dom';
import { Button, Form, Radio,Select, Input, Table} from 'antd';
// import { project_name, airport_name_kor, airport_name_eng, camera_count,  overlap_rate } from "../../../../Store/State/projectAtom";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AirportCreatePage(props:any) {
    const navigate = useNavigate();

    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let projectId : string | null = localStorage.getItem("projectId")
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const submitClick1 = () => {
      // recoil로 저장한 값을 useRecoilValue로 불러와서 create api req body에 넣기
      alert("프로젝트가 생성되었습니다.")
      // navigate(`/project/list`)
    }

    return (
        <Layout>
            <div >
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}></div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>프로젝트 생성</div>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="프로젝트 설정" {...a11yProps(0)} />
                    <Tab label="파노라마" {...a11yProps(1)} />
                    <Tab label="검출 & 측정" {...a11yProps(2)} />
                    <Tab label="외관조사망도" {...a11yProps(3)} />
                    <Tab label="상태평가보고서" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                  <Box sx={{ height: '28rem', paddingTop: '3rem' ,overflowY:'scroll'}}>
                  <AirportCreateSettingTab/>
                  </Box> 
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    {/* <PanoramaSettingTab/> */}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    <div style={{ display: 'flex', padding: '2rem'}}>
                      <div>
                        <div style={{ fontWeight: 600, marginBottom: '1rem'}}>균열 검출</div>
                      {/* <DetectorSettingTab/> */}
                      </div>
                      <div>
                      <div style={{ fontWeight: 600, marginBottom: '1rem'}}>균열 측정</div>
                        {/* < MeasureSettingTab/> */}
                      </div>
                    </div>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    {/* <DrawerSettingTab/> */}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    {/* <EstimatorSettingTab/> */}
                  </Box>
                </TabPanel>
                </Box>
            </div>
            <div style={{ display: 'flex', width : '30rem', height: '3rem' }}>
              <Form.Item className={styles.createBtn} style={{ float:'right'}}>
                  <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '6rem', float : 'right', backgroundColor: '#0F6FB7'}} >확인</Button>
              </Form.Item>
            </div>
        </Layout>
    );
};

