import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Tabs, Tab, Typography } from '@mui/material';

interface Image {
    num : Number;
    folderName : string;
    imageURL : Array<string>;
    lean: string;
}

interface Lean {
    input_folder : string;
    lean_mean : number;
}

interface Copy {
    input_folder : string;
    output_folder : string;
}

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface Data {
    num: string,
    name: string,
  }

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }

  interface OriImage {
    no : any
    original_image : string
}

interface ResultImage {
  no : any
  result_image : string
}
  
  const rows = [
    createData('S001', 'GoYung_C01_S001_Crack.png'),
    createData('S001', 'GoYung_C02_S001_Crack.png'),
    createData('S002', 'GoYung_C01_S002_Crack.png'),
    createData('S002', 'GoYung_C02_S002_Crack.png'),
    createData('S003', 'GoYung_C01_S003_Crack.png'),
    createData('S003', 'GoYung_C02_S003_Crack.png')
  ];
  
export default function AirportCrackDetectorMeasurePage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let projectId: string | null = localStorage.getItem("projectId");

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    let token : string | null = localStorage.getItem("token") 

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const images : Image [] = [];
    const lean : Lean [] = [];
    const folder_copy : Copy [] = [];

    const [num, setNum] = useState<number>(0);
    const [imgList, setImgList] = useState<any[]>(images);
    const [finalLean, setFinalLean] = useState<any[]>(lean);
    const OriImage : OriImage[] = [];  
    const ResultImage : ResultImage[] = []; 

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const [ImgListOri, setImgListOri] = useState<any[]>(OriImage);
    const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const rendering = (i:number) => {
        const resultImage:any[] = [];

        imgList[i].imageURL.map((imageURL:any)=>{      
            resultImage.push((<img src={imageURL} id={String(i)} key={imageURL} alt={imageURL} className={'imagetask'} onClick={showModal} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}}/>))
        })
            
        if(resultImage.length < 1){
            setResultImageList(false);
        }

        return resultImage;
    }

    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const StyledTab = styled(Tab)({
        "&.Mui-selected": {
          color: "#0F6FB7",
          fontWeight : 700,
          background: 'rgba(15, 111, 183, 0.10)',
          borderRadius: '0.25rem',
          marginBottom : '0.25rem',
        }
      });

    //커플 이미지 다운로드
    const downloadCouple = () => {


      console.log(ImgListOri)
      console.log(ImgListCrack)

      const indexFrontOri = ImgListOri[num].original_image.indexOf("/stage3")
      const indexBackOri= ImgListOri[num].original_image.indexOf("&width")   
      const ImageUrlCutWidthOri = ImgListOri[num].original_image.substring(indexFrontOri+1, indexBackOri)

      // console.log(ImageUrlCutWidthOri)

      const indexFrontName = ImgListOri[num].original_image.indexOf("/stage3")
      const indexBackName= ImgListOri[num].original_image.indexOf("&width")   
      const ImageUrlCutWidthName = ImgListOri[num].original_image.substring(indexFrontName+13, indexBackName)

      // console.log(ImageUrlCutWidthName)

      const indexFrontCrack = ImgListCrack[num].result_image.indexOf("/stage3")
      const indexBackCrack= ImgListCrack[num].result_image.indexOf("&width")   
      const ImageUrlCutWidthCrack = ImgListCrack[num].result_image.substring(indexFrontCrack+1, indexBackCrack)

      // console.log(ImageUrlCutWidthCrack)

      axios({
          method: "post",
          url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
            data: {
              project_id: projectId,
              task_name: "image_join",
              interactive: false,
              tasks: [{
                  image1_path: ImageUrlCutWidthOri,
                  image2_path: ImageUrlCutWidthCrack,
                  align: 0, //수평 //수직으로 하려면 0
                  output_folder: 'stage6_crack_Couple',
                  output_name: ImageUrlCutWidthName
              }]
              }
      }).then((res) => {
          console.log(res.data.check)
          if (res.data.check === true) {
              job_id = res.data.data.job_id
                      /////30초마다 alert로 알려줌////////////
                      resultCouple = setInterval(confirm, 10000)
          } else {
              console.log("실패")
          }
      })
  }
  let job_id = 0;
  let resultCouple :any;
  
  async function confirm(){
      
      axios({
          method: "post",
          url: API_URL + '/scheduler/job/query',
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
              data: {
                  "job_id": job_id ,
                  "company_id": companyId
              }
          }).then(async (res) => {
              console.log(res)
              if (res.data.check === true) {
                  if (res.data.data.status === "done") {
                      console.log("성공")

                      setTimeout(()=>
                      download()
                      , 10000)
                  
                  clearInterval(resultCouple)
                  } else if (res.data.data.status === "progress") {
                      // alert("이미지 추출중입니다.")
                  } else if(res.data.data.status === "error"){
                      alert("해당 파일이 없습니다.")
                  }
              } else {
                  console.log("실패")
              }
          })
    }
    async function download() {

      const indexFrontName = ImgListOri[num].original_image.indexOf("/stage3")
      const indexBackName= ImgListOri[num].original_image.indexOf("&width")   
      const ImageUrlCutWidthName = ImgListOri[num].original_image.substring(indexFrontName+13, indexBackName)

      const link = document.createElement('a');
      let src = `${IMAGE_URL}/image?path=/project/${projectId}/stage6_crack_Couple/${ImageUrlCutWidthName}`;
      console.log("주소",ImageUrlCutWidthName)
      const imageBlob = await (await fetch(src)).blob();
      src = URL.createObjectURL(imageBlob);
      link.href = src;
      link.download = ImageUrlCutWidthName
      link.click();
  }

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>균열 검출 & 측정 &gt; 균열 검출</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>균열 검출 & 측정</div>
            <div style={{ display : 'flex'}}>
                <div>
                
                <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                    <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                    <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem',paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width:'3rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {rows.map((row, i) => (
                                    <StyledTableRow 
                                        key={row.num}
                                        style={{ height: '2.5rem'}}
                                    >
                                    {/* <StyledTableCell component="th" scope="row" align="center">
                                        {row.num}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.num}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.name}</StyledTableCell>
                                    </StyledTableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={rows.length > 10 && rows.length % 10 === 0 ? Math.floor(rows.length/10) : Math.floor(rows.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                        
                    </div>
                </Box> 
                </div>
                <div style={{ marginTop: '1rem', height : '50rem'}}>
                <Box sx={{ width: '69rem', height: '47rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                            <div style={{padding : '3rem'}}>
                                {/* 탭들어갈자리 */}
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    {
                                        
                                    }
                                    <Tabs value={value} onChange={handleChange} variant="scrollable" style={{ height: '2rem'}}>
                                        <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="Span 1" {...a11yProps(0)} />
                                        <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="Span 2"  {...a11yProps(1)} />
                                        <StyledTab sx={{ fontFamily: 'inter-pretendard'}} label="Span 3"  {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                <div style={{ marginTop : '0.5rem'}}>
                                        <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                            <div style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>GoYung_C01_S001.png</div>
                                            <div>
                                                <Button variant="outlined" onClick={downloadCouple} sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }}>커플 이미지 다운로드</Button>
                                                {/* <Button variant="contained" sx={{ fontWeight : 600 }}>균열 결과 수정</Button> */}
                                            </div>
                                        </div>
                                            <div>
                                                <div><img src={require('../../../../images/GoYung_C01_S001.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                                <div><img src={require('../../../../images/GoYung_C01_S001_Crack.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                            </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                <div style={{ marginTop : '0.5rem'}}>
                                        <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                        <div style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>GoYung_C01_S002.png</div>
                                            <div>
                                                <Button variant="outlined" sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }}>커플 이미지 다운로드</Button>
                                                {/* <Button variant="contained" sx={{ fontWeight : 600 }}>균열 결과 수정</Button> */}
                                            </div>
                                        </div>
                                            <div>
                                                <div><img src={require('../../../../images/GoYung_C01_S002.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                                <div><img src={require('../../../../images/GoYung_C01_S002_Crack.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                            </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                <div style={{ marginTop : '0.5rem'}}>
                                        <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                        <div style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>GoYung_C01_S003.png</div>
                                            <div>
                                                <Button variant="outlined" sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }}>커플 이미지 다운로드</Button>
                                                {/* <Button variant="contained" sx={{ fontWeight : 600 }}>균열 결과 수정</Button> */}
                                            </div>
                                        </div>
                                            <div>
                                                <div><img src={require('../../../../images/GoYung_C01_S003.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                                <div><img src={require('../../../../images/GoYung_C01_S003_Crack.png')} className={'imagetask'} onClick={showModal} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                                            </div>
                                    </div>
                                </TabPanel>
                                
                                </div>
                            </Box>
                </div>
            </div>
        </Layout>
    );
};