// import React, { useState, useEffect } from "react";
// import { Layout } from "../../../../allcomponents";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_URL, IMAGE_URL } from "../../../../Store/Global";
// import {
//     Box,
//     Table,
//     TableBody,
//     TableCell,
//     tableCellClasses,
//     TableContainer,
//     TableHead,
//     TableRow,
//     TableFooter,
//     Pagination,
//     styled,
// } from "@mui/material";

// interface ImgContents {
//     no: number;
//     name: any;
//     sort: any;
// }

// interface ResultImage {
//     no: any;
//     result_image: string;
// }

// interface Data {
//     num: string;
//     name: string;
// }

// function createData(num: string, name: string) {
//     return { num, name };
// }

// export default function TunnelVerticalPanoramaPage(props: any) {
//     const projectType = localStorage.getItem("projectType");
//     const curId = window.location.href.split("/")[6];
//     let token: string | null = localStorage.getItem("token");
//     let projectId: string | null = localStorage.getItem("projectId");

//     const ImgContents: ImgContents[] = [];
//     const ResultImage: ResultImage[] = [];

//     const [num, setNum] = useState<number>(0);
//     const [ImgList, setImgList] = useState<any | undefined>(ImgContents);
//     const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);
//     const [resultCrack, setResultCrack] = useState<boolean>(false);

//     const navigate = useNavigate();
//     const [page, setPage] = React.useState(0);
//     const handlePage = (event: any) => {
//         const nowPageInt = parseInt(event.target.outerText);
//         console.log(event.target);

//         if (event.target.outerText) {
//             setPage(nowPageInt);
//         } else {
//             if (event.target.ariaLabel.includes("prev")) {
//                 setPage(page - 1);
//             }
//             if (event.target.ariaLabel.includes("next")) {
//                 setPage(page + 1);
//             }
//         }
//     };

//     const [resultImageList, setResultImageList] = useState<boolean>(false);
//     const [resultTableList, setResultTableList] = useState<boolean>(false);

//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [choiceImg, setChoiceImg] = useState<string>("");
//     const [choiceIndex, setChoiceIndex] = useState<number>();

//     const {
//         onSelectAllClick,
//         order,
//         orderBy,
//         numSelected,
//         rowCount,
//         onRequestSort,
//     } = props;

//     const [value, setValue] = React.useState(0);

//     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//         setValue(newValue);
//     };

//     const createSortHandler =
//         (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
//             onRequestSort(event, property);
//         };

//     useEffect(() => {
//         axios({
//             method: "get",
//             url: API_URL + "/File/Files",
//             headers: { accept: `application/json`, "access-token": `${token}` },
//             params: {
//                 path: `/project/${projectId}/stage5`,
//             },
//         })
//             .then((res) => {
//                 if (res.data.check === true) {
//                     // console.log("성공")

//                     for (let i = 0; i < res.data.data.files.length; i++) {
//                         const SpanNum = res.data.data.files[i]
//                             .slice(0, -10)
//                             .substring(16);
//                         ResultImage.push({
//                             result_image: `${IMAGE_URL}/image?path=/project/${projectId}/stage5/${res.data.data.files[i]}&width=1440`,
//                             no: SpanNum,
//                         });
//                         // console.log(res.data.data.files[i])
//                         ImgContents.push({
//                             no: i,
//                             name: res.data.data.files[i],
//                             sort: i + 1,
//                         });
//                     }

//                     if (ResultImage.length > 0) {
//                         setResultCrack(true);
//                         console.log(ResultImage);
//                     } else {
//                         alert("결과가 나올 때까지 기다려주세요.");
//                     }
//                 } else {
//                     // alert('수직파노라마를 실행해주세요.')
//                 }

//                 ImgContents.sort((obj1, obj2) => {
//                     if (obj1.sort > obj2.sort) {
//                         return 1;
//                     }
//                     if (obj1.sort < obj2.sort) {
//                         return -1;
//                     }
//                     return 0;
//                 });

//                 let copyArrImgList = [...ImgList];

//                 for (let i = 0; i < ImgList.length; i++) {
//                     copyArrImgList[i] = { ...copyArrImgList[i], no: i };
//                 }
//                 setImgList(copyArrImgList);
//                 // console.log(ImgList, "ImgListttttt")

//                 ResultImage.sort((obj1, obj2) => {
//                     if (obj1.no > obj2.no) {
//                         return 1;
//                     }
//                     if (obj1.no < obj2.no) {
//                         return -1;
//                     }
//                     return 0;
//                 });

//                 setImgListCrack(ResultImage);
//             })
//             .catch((err) => {
//                 console.log(err);
//             });
//     }, []);

//     const rendering = (i: any) => {
//         const result = [];
//         result.push(
//             <div>
//                 <div style={{ marginTop: "1.5rem" }}>
//                     <img
//                         style={{
//                             width: "50rem",
//                             height: "40rem",
//                             borderRadius: "0.625rem",
//                         }}
//                         src={
//                             resultCrack === true
//                                 ? ImgListCrack[i].result_image
//                                 : ""
//                         }
//                         alt=""
//                     />
//                 </div>
//             </div>
//         );
//         return result;
//     };

//     const onClickPanor = (i: any) => {
//         setNum(i);
//     };

//     const showModal = (e: any) => {
//         setIsModalVisible(true);
//         setChoiceIndex(e.target.id);
//         setChoiceImg(e.target.src);
//     };

//     const StyledTableCell = styled(TableCell)(({ theme }) => ({
//         [`&.${tableCellClasses.head}`]: {
//             fontSize: "1rem",
//             fontWeight: 600,
//             backgroundColor: "#F5F5F5",
//             color: "#3A4557",
//             width: "42.8125rem",
//             height: "2.5rem",
//             border: "1px solid #DDD",
//             borderTop: "0.15rem solid #3A4557",
//             padding: 0,
//         },
//         [`&.${tableCellClasses.body}`]: {
//             color: "#3A4557",
//             textAlign: "center",
//             fontSize: "0.8125rem",
//             width: "42.8125rem",
//             height: "3rem",
//             border: "1px solid #DDD",
//             padding: 0,
//         },
//     }));

//     const StyledTableRow = styled(TableRow)(({ theme }) => ({
//         // '&:nth-of-type(odd)': {
//         //   backgroundColor: theme.palette.action.hover,
//         // },
//         // hide last border
//         "&:last-child td": {},
//     }));

//     return (
//         <Layout>
//             <div
//                 style={{
//                     paddingTop: "2.01rem",
//                     paddingLeft: "2rem",
//                     color: "#999",
//                     fontSize: "0.75rem",
//                     fontFamily: "inter-pretendard",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     lineHeight: "100%",
//                 }}
//             >
//                 파노라마 &gt; 수직 파노라마
//             </div>
//             <div
//                 style={{
//                     paddingTop: "0.3rem",
//                     paddingLeft: "2rem",
//                     color: "#3A4557",
//                     fontSize: "1.5rem",
//                     fontFamily: "inter-pretendard",
//                     fontStyle: "normal",
//                     fontWeight: 700,
//                     lineHeight: "100%",
//                 }}
//             >
//                 수직 파노라마
//             </div>
//             <div style={{ display: "flex", marginLeft: "2rem" }}>
//                 <div>
//                     <div style={{ display: "flex", marginTop: "1rem" }}>
//                         <div>
//                             <Box
//                                 sx={{
//                                     width: "25rem",
//                                     backgroundColor: "white",
//                                     borderRadius: "0.5rem",
//                                     boxShadow:
//                                         "2px 2px 20px 0px rgba(58, 69, 87, 0.05)",
//                                     padding: "2rem",
//                                 }}
//                             >
//                                 <div
//                                     style={{
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "space-between",
//                                     }}
//                                 >
//                                     <div
//                                         style={{
//                                             fontWeight: 700,
//                                             borderLeft:
//                                                 "0.1875rem solid #0F6FB7",
//                                             fontSize: "1rem",
//                                             height: "1.2rem",
//                                             paddingLeft: "0.62rem",
//                                         }}
//                                     >
//                                         이미지 파일 리스트{" "}
//                                     </div>
//                                 </div>
//                                 <div>
//                                     <TableContainer
//                                         sx={{
//                                             width: "23rem",
//                                             marginLeft: "1rem",
//                                             marginTop: "1rem",
//                                             marginBottom: "1rem",
//                                         }}
//                                     >
//                                         <Table
//                                             sx={{ backgroundColor: "#fffff" }}
//                                             aria-label="customized table"
//                                         >
//                                             <TableHead>
//                                                 <TableRow>
//                                                     <StyledTableCell
//                                                         align="center"
//                                                         style={{
//                                                             width: "2rem",
//                                                             fontFamily:
//                                                                 "inter-pretendard",
//                                                         }}
//                                                     >
//                                                         번호
//                                                     </StyledTableCell>
//                                                     <StyledTableCell
//                                                         align="center"
//                                                         style={{
//                                                             width: "5rem",
//                                                             fontFamily:
//                                                                 "inter-pretendard",
//                                                         }}
//                                                     >
//                                                         이름
//                                                     </StyledTableCell>
//                                                 </TableRow>
//                                             </TableHead>

//                                             <TableBody>
//                                                 {ImgListCrack.map(
//                                                     (row, idx) => (
//                                                         <StyledTableRow
//                                                             key={
//                                                                 row.result_image
//                                                             }
//                                                             style={{
//                                                                 height: "2.5rem",
//                                                                 cursor: "pointer",
//                                                             }}
//                                                             onClick={() =>
//                                                                 onClickPanor(
//                                                                     idx
//                                                                 )
//                                                             }
//                                                         >
//                                                             {/* <StyledTableCell component="th" scope="row" align="center">
//                                                 {row.num}
//                                             </StyledTableCell> */}
//                                                             <StyledTableCell
//                                                                 align="center"
//                                                                 style={{
//                                                                     width: "2em",
//                                                                     height: "2.5rem",
//                                                                     fontFamily:
//                                                                         "inter-pretendard",
//                                                                 }}
//                                                             >
//                                                                 {idx + 1}
//                                                             </StyledTableCell>
//                                                             <StyledTableCell
//                                                                 align="center"
//                                                                 style={{
//                                                                     width: "5rem",
//                                                                     height: "2.5rem",
//                                                                     fontFamily:
//                                                                         "inter-pretendard",
//                                                                 }}
//                                                             >
//                                                                 {
//                                                                     ImgListCrack[
//                                                                         idx
//                                                                     ]?.result_image
//                                                                         .split(
//                                                                             "stage5/"
//                                                                         )[1]
//                                                                         .split(
//                                                                             "&width"
//                                                                         )[0]
//                                                                 }
//                                                             </StyledTableCell>
//                                                         </StyledTableRow>
//                                                     )
//                                                 )}
//                                             </TableBody>
//                                         </Table>
//                                         <TableFooter>
//                                             <TableRow
//                                                 style={{
//                                                     width: "23rem",
//                                                     display: "flex",
//                                                     justifyContent: "center",
//                                                     marginTop: "4rem",
//                                                 }}
//                                             >
//                                                 <Pagination
//                                                     count={
//                                                         ImgListCrack.length >
//                                                             10 &&
//                                                         ImgListCrack.length %
//                                                             10 ===
//                                                             0
//                                                             ? Math.floor(
//                                                                   ImgListCrack.length /
//                                                                       10
//                                                               )
//                                                             : Math.floor(
//                                                                   ImgListCrack.length /
//                                                                       10
//                                                               ) + 1
//                                                     }
//                                                     defaultPage={1}
//                                                     onChange={(e) =>
//                                                         handlePage(e)
//                                                     }
//                                                     color="primary"
//                                                     sx={{
//                                                         "& svg": {
//                                                             pointerEvents:
//                                                                 "none",
//                                                         },
//                                                     }}
//                                                 />
//                                             </TableRow>
//                                         </TableFooter>
//                                     </TableContainer>
//                                 </div>
//                             </Box>
//                         </div>
//                         <div style={{ height: "45rem" }}>
//                             <Box
//                                 sx={{
//                                     width: "56.25rem",
//                                     height: "48.5rem",
//                                     flexShrink: 0,
//                                     borderRadius: "0.5rem",
//                                     background: "#FFF",
//                                     boxShadow:
//                                         "2px 2px 20px 0px rgba(58, 69, 87, 0.05)",
//                                     marginLeft: "2rem",
//                                     marginBottom: "1rem",
//                                 }}
//                             >
//                                 <div style={{ padding: "3rem" }}>
//                                     <div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 justifyContent: "space-between",
//                                             }}
//                                         >
//                                             <div
//                                                 style={{
//                                                     fontWeight: 700,
//                                                     borderLeft:
//                                                         "0.1875rem solid #0F6FB7",
//                                                     fontSize: "1rem",
//                                                     height: "1.2rem",
//                                                     paddingLeft: "0.62rem",
//                                                     fontFamily:
//                                                         "inter-pretendard",
//                                                 }}
//                                             >
//                                                 이미지 뷰어{" "}
//                                             </div>
//                                             <div
//                                                 style={{
//                                                     fontWeight: 600,
//                                                     fontSize: "1rem",
//                                                     fontFamily:
//                                                         "inter-pretendard",
//                                                     marginRight: "0.25rem",
//                                                 }}
//                                             >
//                                                 {
//                                                     ImgListCrack[
//                                                         num
//                                                     ]?.result_image
//                                                         .split("stage5/")[1]
//                                                         .split("&width")[0]
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div>{rendering(num)}</div>
//                                     </div>
//                                 </div>
//                             </Box>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Layout>
//     );
// }

// 2
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from "../../../../allcomponents";
import { useNavigate } from "react-router-dom";
import { API_URL, IMAGE_URL } from "../../../../Store/Global";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Pagination,
    styled,
} from "@mui/material";

interface ImgContents {
    no: number;
    name: any;
    sort: any;
}

interface ResultImage {
    no: any;
    result_image: string;
}

interface Data {
    num: string;
    name: string;
}

function createData(num: string, name: string) {
    return { num, name };
}

export default function TunnelVerticalPanoramaPage(props: any) {
    const projectType = localStorage.getItem("projectType");
    const curId = window.location.href.split("/")[6];
    let token: string | null = localStorage.getItem("token");
    let projectId: string | null = localStorage.getItem("projectId");

    const ImgContents: ImgContents[] = [];
    const ResultImage: ResultImage[] = [];

    const [num, setNum] = useState<number>(0);
    const [ImgList, setImgList] = useState<any | undefined>(ImgContents);
    const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);
    const [resultCrack, setResultCrack] = useState<boolean>(false);

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const handlePage = (event: any) => {
        const nowPageInt = parseInt(event.target.outerText);
        console.log(event.target);

        if (event.target.outerText) {
            setPage(nowPageInt);
        } else {
            if (event.target.ariaLabel.includes("prev")) {
                setPage(page - 1);
            }
            if (event.target.ariaLabel.includes("next")) {
                setPage(page + 1);
            }
        }
    };

    const [resultImageList, setResultImageList] = useState<boolean>(false);
    const [resultTableList, setResultTableList] = useState<boolean>(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("");
    const [choiceIndex, setChoiceIndex] = useState<number>();

    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    useEffect(() => {
        axios({
            method: "get",
            url: API_URL + "/File/Files",
            headers: { accept: `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage5`,
            },
        })
            .then((res) => {
                if (res.data.check === true) {
                    // console.log("성공")

                    for (let i = 0; i < res.data.data.files.length; i++) {
                        const SpanNum = res.data.data.files[i]
                            .slice(0, -10)
                            .substring(16);
                        ResultImage.push({
                            result_image: `${IMAGE_URL}/image?path=/project/${projectId}/stage5/${res.data.data.files[i]}&width=1440`,
                            no: SpanNum,
                        });
                        // console.log(res.data.data.files[i])
                        ImgContents.push({
                            no: i,
                            name: res.data.data.files[i],
                            sort: i + 1,
                        });
                    }

                    if (ResultImage.length > 0) {
                        setResultCrack(true);
                        console.log(ResultImage);
                    } else {
                        alert("결과가 나올 때까지 기다려주세요.");
                    }
                } else {
                    // alert('수직파노라마를 실행해주세요.')
                }

                ImgContents.sort((obj1, obj2) => {
                    if (obj1.sort > obj2.sort) {
                        return 1;
                    }
                    if (obj1.sort < obj2.sort) {
                        return -1;
                    }
                    return 0;
                });

                let copyArrImgList = [...ImgList];

                for (let i = 0; i < ImgList.length; i++) {
                    copyArrImgList[i] = { ...copyArrImgList[i], no: i };
                }
                setImgList(copyArrImgList);
                // console.log(ImgList, "ImgListttttt")

                ResultImage.sort((obj1, obj2) => {
                    if (obj1.no > obj2.no) {
                        return 1;
                    }
                    if (obj1.no < obj2.no) {
                        return -1;
                    }
                    return 0;
                });

                setImgListCrack(ResultImage);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const rendering = (i: any) => {
        const result = [];
        result.push(
            <div>
                <div style={{ marginTop: "1.5rem" }}>
                    <img
                        style={{
                            width: "50rem",
                            height: "40rem",
                            borderRadius: "0.625rem",
                        }}
                        src={
                            resultCrack === true
                                ? ImgListCrack[i].result_image
                                : ""
                        }
                        alt=""
                    />
                </div>
            </div>
        );
        return result;
    };

    const onClickPanor = (i: any) => {
        setNum(i);
    };

    const showModal = (e: any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: "1rem",
            fontWeight: 600,
            backgroundColor: "#F5F5F5",
            color: "#3A4557",
            width: "42.8125rem",
            height: "2.5rem",
            border: "1px solid #DDD",
            borderTop: "0.15rem solid #3A4557",
            padding: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            color: "#3A4557",
            textAlign: "center",
            fontSize: "0.8125rem",
            width: "42.8125rem",
            height: "3rem",
            border: "1px solid #DDD",
            padding: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        "&:last-child td": {},
    }));

    // 파일 업로드 처리 함수
    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("file", file);

            axios({
                method: "post",
                url: `${API_URL}/upload`,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "access-token": `${token}`,
                },
            })
                .then((response) => {
                    // 업로드 성공 후 이미지 목록 갱신
                    console.log("Upload successful", response);
                })
                .catch((error) => {
                    console.error("Error uploading file", error);
                });
        }
    }

    return (
        <Layout>
            <div
                style={{
                    paddingTop: "2.01rem",
                    paddingLeft: "2rem",
                    color: "#999",
                    fontSize: "0.75rem",
                    fontFamily: "inter-pretendard",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "100%",
                }}
            >
                파노라마 &gt; 수직 파노라마
            </div>
            <div
                style={{
                    paddingTop: "0.3rem",
                    paddingLeft: "2rem",
                    color: "#3A4557",
                    fontSize: "1.5rem",
                    fontFamily: "inter-pretendard",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "100%",
                }}
            >
                수직 파노라마
            </div>
            <div style={{ display: "flex", marginLeft: "2rem" }}>
                <div>
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                        <div>
                            <Box
                                sx={{
                                    width: "25rem",
                                    backgroundColor: "white",
                                    borderRadius: "0.5rem",
                                    boxShadow:
                                        "2px 2px 20px 0px rgba(58, 69, 87, 0.05)",
                                    padding: "2rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: 700,
                                            borderLeft:
                                                "0.1875rem solid #0F6FB7",
                                            fontSize: "1rem",
                                            height: "1.2rem",
                                            paddingLeft: "0.62rem",
                                        }}
                                    >
                                        이미지 파일 리스트{" "}
                                    </div>
                                </div>
                                <div>
                                    <TableContainer
                                        sx={{
                                            width: "23rem",
                                            marginLeft: "1rem",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <Table
                                            sx={{ backgroundColor: "#fffff" }}
                                            aria-label="customized table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{
                                                            width: "2rem",
                                                            fontFamily:
                                                                "inter-pretendard",
                                                        }}
                                                    >
                                                        번호
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{
                                                            width: "5rem",
                                                            fontFamily:
                                                                "inter-pretendard",
                                                        }}
                                                    >
                                                        이름
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {ImgListCrack.map(
                                                    (row, idx) => (
                                                        <StyledTableRow
                                                            key={
                                                                row.result_image
                                                            }
                                                            style={{
                                                                height: "2.5rem",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                onClickPanor(
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {/* <StyledTableCell component="th" scope="row" align="center">
                                                {row.num}
                                            </StyledTableCell> */}
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: "2em",
                                                                    height: "2.5rem",
                                                                    fontFamily:
                                                                        "inter-pretendard",
                                                                }}
                                                            >
                                                                {idx + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: "5rem",
                                                                    height: "2.5rem",
                                                                    fontFamily:
                                                                        "inter-pretendard",
                                                                }}
                                                            >
                                                                {
                                                                    ImgListCrack[
                                                                        idx
                                                                    ]?.result_image
                                                                        .split(
                                                                            "stage5/"
                                                                        )[1]
                                                                        .split(
                                                                            "&width"
                                                                        )[0]
                                                                }
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                        <TableFooter>
                                            <TableRow
                                                style={{
                                                    width: "23rem",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: "4rem",
                                                }}
                                            >
                                                <Pagination
                                                    count={
                                                        ImgListCrack.length >
                                                            10 &&
                                                        ImgListCrack.length %
                                                            10 ===
                                                            0
                                                            ? Math.floor(
                                                                  ImgListCrack.length /
                                                                      10
                                                              )
                                                            : Math.floor(
                                                                  ImgListCrack.length /
                                                                      10
                                                              ) + 1
                                                    }
                                                    defaultPage={1}
                                                    onChange={(e) =>
                                                        handlePage(e)
                                                    }
                                                    color="primary"
                                                    sx={{
                                                        "& svg": {
                                                            pointerEvents:
                                                                "none",
                                                        },
                                                    }}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </TableContainer>
                                </div>
                            </Box>
                        </div>
                        <div style={{ height: "45rem" }}>
                            <Box
                                sx={{
                                    width: "56.25rem",
                                    height: "48.5rem",
                                    flexShrink: 0,
                                    borderRadius: "0.5rem",
                                    background: "#FFF",
                                    boxShadow:
                                        "2px 2px 20px 0px rgba(58, 69, 87, 0.05)",
                                    marginLeft: "2rem",
                                    marginBottom: "1rem",
                                }}
                            >
                                <div style={{ padding: "3rem" }}>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontWeight: 700,
                                                    borderLeft:
                                                        "0.1875rem solid #0F6FB7",
                                                    fontSize: "1rem",
                                                    height: "1.2rem",
                                                    paddingLeft: "0.62rem",
                                                    fontFamily:
                                                        "inter-pretendard",
                                                }}
                                            >
                                                이미지 뷰어{" "}
                                            </div>
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: "1rem",
                                                    fontFamily:
                                                        "inter-pretendard",
                                                    marginRight: "0.25rem",
                                                }}
                                            >
                                                {
                                                    ImgListCrack[
                                                        num
                                                    ]?.result_image
                                                        .split("stage5/")[1]
                                                        .split("&width")[0]
                                                }
                                            </div>
                                        </div>
                                        <div>{rendering(num)}</div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
