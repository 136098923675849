import React from 'react'
import { Link } from 'react-router-dom';
import './IndexPage.css'
import { useRecoilState } from 'recoil';


export default function IndexPage() {

    // const [ProjectType, setProjectType] = useRecoilState(projectType);

  return (
    <div>
      <section>
                <div className='index_inner'>
                    <div className='head'>
                        <br />
                        <br />
                        
                        <br />
                        <a className='head_title'>Service</a>
                        <br />
                        <a className='head_text'>딥인스펙션에서 제공하는 AI안전점검 프로그램</a>
                    </div>


                    <div className='box_container'>
                        <div className='mid'>
                            <div className='index'>
                                <div className='tunnel_box'>
                                    <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "tunnel")}>
                                        <a><div className='maintext'>Tunnel</div></a>
                                    </Link>
                                    <div className='subtext'>터널</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='dam_box'>
                                {/* <Link to="" onClick={()=>alert("서비스 준비중입니다.")}> */}
                                <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "dam")}>
                                        <a><div className='maintext'>Dam</div></a>
                                </Link>
                                    <div className='subtext'>댐</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='scaf_box'>
                                <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                    {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "scaf")}> */}
                                            <a><div className='maintext'>Scaf</div></a>
                                    </Link>
                                    <div className='subtext'>가설기자재</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='airport_box'>
                                <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "airport")}> */}
                                        <a><div className='maintext'>Airport</div></a>
                                </Link>
                                <div className='subtext'>항공기 및 활주로</div>

                                    {/* <a href="#"><div className='maintext}>Airport</div></a>
                                    <div className='subtext}>항공기 및 활주로</div> */}
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                              <div className='bridge_box'>
                              {/* <Link to="" onClick={()=>alert("서비스 준비중입니다.")}> */}
                                   <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "bridge")}>
                                        <a><div className='maintext'>Bridge</div></a>
                                    </Link>
                                    <div className='subtext'>교량</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='cuslope_box'>
                                <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                    {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "cutslope")}> */}
                                        <a><div className='maintext'>Cutslope</div></a>
                                    </Link>
                                    <div className='subtext'>비탈면</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                              <div className='building_box'>
                              <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                    {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "building")}> */}
                                        <a><div className='maintext'>Building</div></a>
                                    </Link>
                                    <div className='subtext'>건물</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='waterwork_box'>
                                <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                    {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "waterwork")}> */}
                                        <a><div className='maintext'>Waterwork</div></a>
                                    </Link>
                                    <div className='subtext'>상수도</div>
                                </div>
                            </div>
                        </div>

                        <div className='mid'>
                            <div className='index'>
                                <div className='pipeline_box'>
                                <Link to="" onClick={()=>alert("서비스 준비중입니다.")}>
                                    {/* <Link to="/project/list" onClick={()=>localStorage.setItem('projectType', "pipeline")}> */}
                                        <a><div className='maintext'>Pipeline</div></a>
                                    </Link>
                                    <div className='subtext'>가스배관/열배관</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className='footer'>
                        <div className='homeicon'>
                            <a href="./"> <div><img src="./images/index/icon_home.png" alt="" width={15} height={15} ></img></div></a>
                        </div>


                    </div> */}
                </div>



            </section>
    </div>
  )
}
